import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import { onMounted, ref, computed } from 'vue'
import { camelCase, upperFirst, kebabCase } from 'lodash-es'
import { XIcon } from '../icons'

interface AlertProps {
  // brand props
  success?: boolean
  info?: boolean
  warning?: boolean
  error?: boolean
  // other props
  fullWidth?: boolean
  closable?: boolean
  small?: boolean
  storageSuffix?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAlert',
  props: {
    success: { type: Boolean },
    info: { type: Boolean },
    warning: { type: Boolean },
    error: { type: Boolean },
    fullWidth: { type: Boolean },
    closable: { type: Boolean },
    small: { type: Boolean },
    storageSuffix: { default: '' }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const getKey = (suffix?: string) => (suffix ? `KlausUiAlert${upperFirst(camelCase(suffix))}` : undefined)

const visible = ref(true)
const localStorageKey = getKey(props.storageSuffix)

onMounted(() => {
  if (!localStorageKey) return
  const dismissed = localStorage.getItem(localStorageKey) === 'true'
  if (dismissed) {
    visible.value = false
    emit('close')
  }
})

const closeAlert = () => {
  if (localStorageKey) {
    localStorage.setItem(localStorageKey, 'true')
    visible.value = false
  }
  emit('close')
}

const brandingProps = ['success', 'info', 'warning', 'error']
const brandingClasses = computed(() =>
  brandingProps.filter((key) => key in props && props[key] === true).map((key) => `-${kebabCase(key)}`),
)

return (_ctx: any,_cache: any) => {
  return (visible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["ui-alert text-gray-80 relative border-l", [
      brandingClasses.value,
      _ctx.fullWidth && 'w-full',
      _ctx.small && 'rounded-sm border-l-4 py-[0.4375rem] pl-3 text-xs',
      !_ctx.small && 'rounded border-l-[6px] py-[0.84375rem] pl-[1.125rem] text-sm',
      _ctx.small && _ctx.closable && 'pr-11',
      !_ctx.small && _ctx.closable && 'pr-16',
      !_ctx.closable && 'pr-3',
    ]])
      }, [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.closable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "ui-alert__trigger absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2",
              onClick: closeAlert
            }, [
              _createVNode(_unref(XIcon), { class: "icon-s" })
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}
}

})