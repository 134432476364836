import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-20 br-sm text-gray-80 flex h-16 w-full items-center gap-2 rounded border-2 border-red-50 px-4 py-2 text-sm" }

import { defineComponent } from 'vue'
import { AlertTriangleIcon } from '@klausapp/ui-kit/icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'EditorError',
  setup(__props) {

defineComponent({ name: 'EditorError' })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(AlertTriangleIcon), { class: "icon-m text-red-50" }),
    _createTextVNode(" " + _toDisplayString(_ctx.$t('modules.comment_editor.async_loader_error')), 1 /* TEXT */)
  ]))
}
}

})