import { ref, watch } from 'vue'
import { getImageDownloadUrl } from '@/api/image-download-url'
import config from '@/config'
import { unsavedImages } from '@/modules/shared/CommentEditor/plugins/useUnsavedImages'

export const redirectedImagesStore = {}

const notUploaded = (newSrc: string) => {
  const imageWithSrcUrl = Object.values(unsavedImages).find((img) => img.signedUrlResponse?.url === newSrc)
  return imageWithSrcUrl && !imageWithSrcUrl.uploaded
}

export const useRedirectedImageSource = (watchFn: () => any) => {
  const src = ref('')

  watch(
    () => [watchFn(), notUploaded(watchFn())],
    async ([newSrc, notUploadedYet]) => {
      if (!newSrc?.includes(`uploads.${config.domainRoot}`)) {
        src.value = newSrc || ''
        return
      }

      if (notUploadedYet) return

      const fileName = newSrc.split('/').pop()
      if (!redirectedImagesStore[fileName]) {
        const { downloadUrl } = await getImageDownloadUrl(fileName)
        const response = await fetch(downloadUrl)
        redirectedImagesStore[fileName] = URL.createObjectURL(await response.blob())
      }
      src.value = redirectedImagesStore[fileName]
    },
    { immediate: true },
  )

  return { src }
}
