import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "flex h-16 items-center gap-2 py-5" }
const _hoisted_2 = { class: "text-gray-90 grow truncate text-center text-base font-semibold" }

import { UiDrawer, UiButton } from '@klausapp/ui-kit'
import { XIcon, ArrowLeftIcon } from '@klausapp/ui-kit/icons'
import useHelpDrawer from './composables/useHelpDrawer'


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpDrawer',
  setup(__props) {

const { helpDrawerActive, activeView, backToParent, isActiveChildRoute } = useHelpDrawer()

function closeHelpDrawer() {
  helpDrawerActive.value = false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UiDrawer), {
    class: "bottom-2 left-0 right-2 top-2 z-[7] rounded rounded-r md:rounded-r",
    active: _unref(helpDrawerActive),
    "max-width": "360px",
    narrow: "",
    onClose: closeHelpDrawer
  }, {
    header: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        (_unref(isActiveChildRoute))
          ? (_openBlock(), _createBlock(_unref(UiButton), {
              key: 0,
              class: "shrink-0",
              secondary: "",
              small: "",
              borderless: "",
              onClick: _unref(backToParent)
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(ArrowLeftIcon), { class: "icon-m" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(activeView)?.title), 1 /* TEXT */),
        _createVNode(_unref(UiButton), {
          class: "shrink-0",
          secondary: "",
          small: "",
          borderless: "",
          onClick: closeHelpDrawer
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(XIcon), { class: "icon-m" })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(activeView)?.content)))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["active"]))
}
}

})