import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import type { PropsWithDefaults, UpdateParams, Modes } from '../../types'
import { ALL_ID } from '../../constants'
import { getId, getIds } from '../utils'
import useSortedList from '../common/useSortedList'
import useSelectedList from '../common/useSelectedList'
import useFlatOptions from '../common/useFlatOptions'

export default function useMultipleAllList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, _emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const { reorderList, sortedOptions, sortedGroups } = useSortedList(props)
  const { selectedValues, excludedValues, updateModelValue, onUpdate } = useSelectedList<T, P, L, M, V, O>(props)
  const { flatOptions } = useFlatOptions(props)

  onUpdate(updateResults.trigger)

  const isSelected = (option: T) => {
    if (showAllGroup.value && allSelected.value) {
      if (props.allowSingleDeselect) return true

      return option[props.propToCheck] === ALL_ID
    }

    return selectedIds.value.includes(option[props.propToCheck])
  }

  const toggleAll = () => (allSelected.value ? selectFirst() : selectAll())

  const selectFirst = () => {
    selectedValues.value = [getId(props.modelValue[0], props)]
  }

  const selectAll = () => {
    selectedValues.value = flatOptions.value.map((option) => option[props.propToCheck])
  }

  const deselectAll = () => {
    selectedValues.value = []
  }

  const selectOption = (option: T) => {
    selectedValues.value = getIds<T, P, L, M, V, O>(props.modelValue, props).concat(option[props.propToCheck])
  }

  const deselectOption = (option: T) => {
    const selectedOptions = getIds<T, P, L, M, V, O>(props.modelValue, props).filter(
      (id) => id !== option[props.propToCheck],
    )
    if (selectedOptions.length || props.allowDeselectAll) {
      selectedValues.value = selectedOptions
    } else {
      selectAll()
    }
  }

  const handleSelectAllToggle = () => {
    if (props.allowSingleDeselect) {
      allSelected.value ? deselectAll() : selectAll()
    } else {
      toggleAll()
    }
  }

  const handleSingleOptionToggle = (option: T) => {
    if (allSelected.value && !props.allowSingleDeselect) {
      selectedValues.value = [option[props.propToCheck]]
    } else {
      isSelected(option) ? deselectOption(option) : selectOption(option)
    }
  }

  const toggle = (option: T) => {
    if (option[props.propToCheck] === ALL_ID) {
      handleSelectAllToggle()
    } else {
      handleSingleOptionToggle(option)
    }

    updateModelValue(selectedValues.value, [])
  }

  const allSelected = computed(() => props.modelValue.length === flatOptions.value.length)

  const selectedIds = computed(() => props.modelValue.map((item: T | T[P]) => getId(item, props)))

  const simplifiedSelectedIds = computed(() => (allSelected.value ? [ALL_ID] : selectedIds.value))

  const showAllGroup = computed(() => !searchQuery.value && !!props.selectAllLabel && flatOptions.value.length > 2)

  return {
    excludedValues,
    isSelected,
    isIndeterminate: (_option: T) => false,
    reorderList,
    selectedIds: simplifiedSelectedIds,
    sortedOptions,
    sortedGroups,
    showAllGroup,
    toggle,
    addOrSelectOption: (_query: string) => undefined,
    onUpdate: updateResults.on,
  }
}
