import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

import UiMenuGroup from '../UiMenu/UiMenuGroup.vue'

import { type Modes } from './types'
import UiDropdownItem from './UiDropdownItem.vue'
import { ALL_ID } from './constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownGroup',
  props: {
    options: {},
    name: {},
    mode: {},
    label: {},
    listRef: {},
    total: {},
    emptyText: {},
    propToCheck: {},
    getIsSelected: { type: Function },
    getIsIndeterminate: { type: Function }
  },
  emits: ["toggle"],
  setup(__props: any, { expose: __expose }) {

const props = __props



const isOptionPropInList = (option: T, prop: string, list: string[]) =>
  prop in option && typeof option[prop] === 'string' && list.includes(option[prop])

const isDisabled = (option: T) => {
  const markedAsDisabled = ['separator', 'placeholder']
  return isOptionPropInList(option, 'name', markedAsDisabled) || isOptionPropInList(option, 'value', markedAsDisabled)
}

const isSeparator = (option: T) =>
  isOptionPropInList(option, 'name', ['separator']) || isOptionPropInList(option, 'value', ['separator'])

const optionsLength = computed(() => {
  return props.options.filter((opt) => !isSeparator(opt)).length
})

__expose({ optionsLength })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuGroup, null, _createSlots({
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(UiDropdownItem, {
          key: `${option[props.propToCheck]}-${option[props.label]}`,
          option: option,
          selected: _ctx.getIsSelected(option),
          indeterminate: _ctx.getIsIndeterminate(option),
          mode: props.mode,
          label: props.label,
          disabled: isDisabled(option),
          separator: isSeparator(option),
          "prop-to-check": props.propToCheck,
          onToggle: (option) => _ctx.$emit('toggle', option)
        }, _createSlots({
          option: _withCtx(({ option: slotOption }) => [
            _renderSlot(_ctx.$slots, "option", { option: slotOption })
          ]),
          _: 2 /* DYNAMIC */
        }, [
          (_ctx.$slots['option-icon'] && option[props.propToCheck] !== _unref(ALL_ID))
            ? {
                name: "option-icon",
                fn: _withCtx(({ option: slotOption }) => [
                  _renderSlot(_ctx.$slots, "option-icon", { option: slotOption })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["option", "selected", "indeterminate", "mode", "label", "disabled", "separator", "prop-to-check", "onToggle"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.name)
      ? {
          name: "name",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.name), 1 /* TEXT */)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.emptyText)
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.emptyText), 1 /* TEXT */)
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.total && _ctx.total > optionsLength.value)
      ? {
          name: "more",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "more-count", {
              count: _ctx.total - optionsLength.value
            })
          ]),
          key: "2"
        }
      : undefined
  ]), 1024 /* DYNAMIC_SLOTS */))
}
}

})