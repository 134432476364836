import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineComponent } from 'vue'
import UiButton from './UiButton.vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiDangerButton',
  setup(__props) {



defineComponent({
  name: 'UiDangerButton',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiButton, _mergeProps(_ctx.$attrs, {
    secondary: "",
    class: _ctx.$style.dangerButton
  }), {
    default: _withCtx(() => [
      _createCommentVNode(" @slot content of the button "),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class"]))
}
}

})