import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { provide, reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAccordion',
  props: {
    multiple: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const activeItems = reactive<Set<string>>(new Set())

const toggleActiveItem = (name: string) => {
  const togglingCurrentActive = activeItems.has(name)
  if (props.multiple) {
    togglingCurrentActive ? activeItems.delete(name) : activeItems.add(name)
    return
  }

  activeItems.clear()

  if (!togglingCurrentActive) activeItems.add(name)
}

// Provide the functions to UiAccordionItems passed in slot
provide('toggleActiveItem', toggleActiveItem)
provide('getActiveItems', () => Array.from(activeItems))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})