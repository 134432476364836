import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "text-link flex items-center pt-2 text-sm font-medium"
}
const _hoisted_2 = { class: "text-gray-80 px-5 pb-4 pt-6 text-left text-xs" }
const _hoisted_3 = { class: "mb-5 flex justify-between" }
const _hoisted_4 = { class: "text-base font-medium" }
const _hoisted_5 = { class: "mb-4 flex gap-3" }
const _hoisted_6 = ["href"]

import { ref } from 'vue'
import { InfoIcon, XIcon, FolderIcon } from '@klausapp/ui-kit/icons'
import type { Instance } from 'tippy.js'

import { Tippy } from '@/components/Tippy'


export default /*@__PURE__*/_defineComponent({
  __name: 'HowPinsWork',
  setup(__props) {

const tippyRef = ref<{ tip: Instance } | null>(null)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Tippy), {
    ref_key: "tippyRef",
    ref: tippyRef,
    interactive: "",
    trigger: "click",
    theme: "light",
    placement: "top",
    arrow: false,
    offset: [5, 0],
    class: "flex grow"
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        _createVNode(_unref(InfoIcon), { class: "icon-m mr-1" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('pins.modal.how_pins_work')), 1 /* TEXT */)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('pins.modal.pins_for_coaching')), 1 /* TEXT */),
          _createElementVNode("button", {
            type: "button",
            class: "group",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (tippyRef.value?.tip.hide()))
          }, [
            _createVNode(_unref(XIcon), { class: "icon-m group-hover:text-gray-60 text-gray-50" })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(FolderIcon), { class: "icon-m mt-0.5 shrink-0 text-gray-50" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('pins.modal.how_to_save_pins')), 1 /* TEXT */)
        ]),
        _createElementVNode("a", {
          href: _ctx.$t('support_links.pins_for_coaching'),
          class: "content-link",
          rel: "noopener noreferrer",
          target: "_blank"
        }, _toDisplayString(_ctx.$t('universal.read_more')), 9 /* TEXT, PROPS */, _hoisted_6)
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}
}

})