import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex shrink-0"
}
const _hoisted_2 = { class: "grow overflow-hidden truncate whitespace-nowrap" }

import { computed, useSlots } from 'vue'
import { hasSlotContent } from '@klausapp/shared/utils/slot'
import { UiMenuItemTheme } from './types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuItemContent',
  props: {
    disabled: { type: Boolean },
    theme: { default: UiMenuItemTheme.Default }
  },
  setup(__props: any) {

const props = __props

const slots = useSlots()

const isSlotUsed = computed(() => hasSlotContent(slots.icon))

const themeClasses = computed(() => {
  if (props.disabled) return disabledThemeClasses[props.theme]

  return activeThemeClasses[props.theme]
})

const activeThemeClasses = {
  [UiMenuItemTheme.Danger]: 'text-red-60',
  [UiMenuItemTheme.Default]: 'text-gray-80',
}

const disabledThemeClasses = {
  [UiMenuItemTheme.Danger]: 'text-red-40',
  [UiMenuItemTheme.Default]: 'text-gray-50',
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex grow items-center gap-2 overflow-hidden", [{ 'cursor-not-allowed': _ctx.disabled }, themeClasses.value]]),
    role: "button"
  }, [
    (isSlotUsed.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2 /* CLASS */))
}
}

})