import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'
import UiButton from '../../UiButton.vue'
import UiMenuBaseTrigger from './UiMenuBaseTrigger.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuButtonTrigger',
  props: {
    disabled: { type: Boolean },
    expanded: { type: Boolean },
    dataTestid: {}
  },
  setup(__props: any) {



const buttonRef = ref<HTMLButtonElement | null>(null)

const handleFocus = () => {
  buttonRef.value?.focus()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuBaseTrigger, { onFocus: handleFocus }, {
    default: _withCtx(({ openMenu }) => [
      _createVNode(UiButton, {
        ref_key: "buttonRef",
        ref: buttonRef,
        secondary: "",
        small: "",
        "data-testid": _ctx.dataTestid || 'menu-button-trigger',
        disabled: _ctx.disabled,
        type: "button",
        tabindex: "0",
        "aria-expanded": _ctx.expanded,
        onClick: openMenu,
        onKeydown: _withKeys(openMenu, ["enter"])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["data-testid", "disabled", "aria-expanded", "onClick", "onKeydown"])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})