import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

import { defineComponent } from 'vue'
import { XIcon } from '../icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiBanner',
  props: {
    status: { default: 'info' },
    stubborn: { type: Boolean, default: false }
  },
  setup(__props: any) {



defineComponent({
  name: 'UiBanner',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-sm", [
      _ctx.$style.banner,
      {
        'bg-applicationBanner-background-error': _ctx.status === 'error',
        'bg-applicationBanner-background-warning': _ctx.status === 'warning',
        'bg-applicationBanner-background-info': _ctx.status === 'info',
        'bg-applicationBanner-background-success': _ctx.status === 'success',
      },
    ]])
  }, [
    _createCommentVNode(" @slot content of the banner "),
    _renderSlot(_ctx.$slots, "default"),
    _createCommentVNode("\n      triggered after clicking the 'x' button\n      @event close\n    "),
    (!_ctx.stubborn)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "absolute right-4 top-3",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_unref(XIcon), { class: "icon-m" })
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})