import { computed, reactive, ref, watch } from 'vue'
import * as Sentry from '@sentry/vue'
import { useIdle, useTimestamp } from '@vueuse/core'

import { setWeekStart } from '@/utils/date'
import type { Session, PreData } from '@/types/session'
import { embeddedRuntime, hasZendeskProxy } from '@/config'
import { APIError, api, oldApi, zendeskApi } from '@/api'
import { getAccessToken, auth0, logout, generateZendeskLoginUrl } from '@/modules/auth/api'
import { identify } from '@/utils/analytics'
import { applyOverrides } from '@/utils/feature-flag-override'
import { applyDefaults } from '@/utils/constants/default-workspace-settings'
import { redirectToSso } from '@/modules/auth/utils/sso'
import { getSupportedLocale } from '@/i18n'
import { getDomains } from '@/modules/extension/api'
import { shareHostsWithExtension } from '@/modules/extension/utils/messaging'

/**
 * Session reactive object
 */
export const session = reactive<Session>({
  features: {},
  account: undefined,
  user: undefined,
  workspace: undefined,
  system: undefined,
  isAuthenticated: false,
  fullPageRedirectFromError: false,
} as any)

export const signupState = ref<PreData>()

const logAuth0Error = (err: any) => {
  if (err instanceof Error) {
    Sentry.captureException(err)
  } else if (err?.error) {
    if (['timeout', 'login_required'].includes(err.error)) return
    Sentry.captureMessage(`auth0 session refresh failed - ${err.error}`)
  } else {
    Sentry.captureException(err)
  }
}

export const loadFreshTokens = async () => {
  const accessToken = await getAccessToken()
  // Store long lived ID Token next to session as it is used in various places
  const claims = await auth0.getIdTokenClaims()
  if (!claims) throw new Error('Session - failed to fetch ID token')
  session.idToken = claims.__raw
  session.idTokenPayload = claims as any
  session.isAuthenticated = true

  return [accessToken, session.idToken]
}

/**
 * Load all necessary session metadata for app boot
 */
export const loadSession = async () => {
  // Skip Auth0 and old pre-data if we are running behind proxy
  if (hasZendeskProxy) {
    // Allow anonymous extension auth pages through
    if (embeddedRuntime() && ['/qa/zendesk-sso', '/qa/extension/sso-logout'].includes(location.pathname)) {
      return session
    }

    session.isAuthenticated = true
  } else {
    try {
      await loadFreshTokens()
    } catch (err: any) {
      // Handle unverified email logins
      const emailNotVerified = err?.error === 'unauthorized' && err?.error_description?.includes('verify your email')
      if (emailNotVerified) {
        logout({ unverified_email: 'true' })
        return session
      }

      // Log exceptional session fetching issues and reset tokens with logout
      if (!['login_required', 'consent_required'].includes(err?.error)) {
        logAuth0Error(err)
        logout()
      }

      redirectToSso() // Handle SSO redirects if any when logged out

      if (embeddedRuntime()) shareHostsWithExtension()

      return session
    }

    // Trigger SAML user creation & automatic authentication method linking
    // TODO: Implement new manual authentication linking flow & SAML user provisioning before removing this
    signupState.value = await oldApi
      .get(`users/pre-data`, { headers: { 'X-Id-Token': session.idToken } })
      .json<{ data: PreData }>()
      .then((r) => r.data)
  }

  try {
    // Load session metadata
    const { user, account, workspace, features, domainAccountMatch, redirectTo } = await api
      .get('users/session', { searchParams: contextSwitchParams(), headers: { 'X-Id-Token': session.idToken } })
      .json<any>()

    // Redirect to correct app origin (to zendesk domain) if it is given by BE
    // Also do not redirect for integration callback pages in case ZD customer has logged into kibbles.klausapp.com
    if (redirectTo && !location.pathname.startsWith('/integrate')) {
      location.href = location.href.replace(location.origin, redirectTo)
      return
    }

    if (!account) {
      session.domainAccountMatch = domainAccountMatch
    } else {
      session.user = user
      session.user.id = parseInt(user.id, 10)
      session.user.settings = session.user.settings || {}
      session.account = account
      session.account.id = parseInt(account.id, 10)

      session.features = applyOverrides(features)

      if (workspace) {
        session.workspace = workspace
        session.workspace.id = parseInt(workspace.id, 10)
        session.workspace.settings = applyDefaults(session.workspace.settings)
      }

      setWeekStart(session.user.firstDayOfWeek) // Set day.js first day of the week

      identify(session)

      // Set user information, as well as tags and further extras
      const scope = Sentry.getCurrentScope()
      scope.setTag('account_role', session.account.role)
      scope.setTag('workspace_role', session.workspace?.role)
      scope.setUser({ id: session.user.id.toString(), email: session.user.email })

      // Expose data to the extension if embeddded
      if (embeddedRuntime()) getDomains().then(shareHostsWithExtension)
    }
  } catch (err: unknown) {
    if (!hasZendeskProxy || !(err instanceof APIError)) throw err

    // Handle logged out Zendesk users & invalid account state behind proxy
    if (err.status === 401) {
      session.fullPageRedirectFromError = true
      handleExpiredSession()
      return session
    } else if (err.status === 403 && err.body.includes('account does not have correct SKU')) {
      session.fullPageRedirectFromError = true
      location.href = 'https://zendesk.com/qa'
      return session
    } else if (err.status === 403) {
      session.fullPageRedirectFromError = true
      location.href = '/'
      return session
    } else if (err.status === 404 && err.body.includes('account is not yet migrated')) {
      history.replaceState(null, '', '/qa/account-not-found/not-migrated')
      return session
    } else if (err.status === 404 && err.body.includes('account not found')) {
      history.replaceState(null, '', '/qa/account-not-found')
      return session
    } else {
      throw err
    }
  }

  // Start Zendesk session renewal
  if (hasZendeskProxy && session.isAuthenticated) {
    let expiresAt = await renewZendeskSession()

    const BUFFER_SECONDS = 120 // 2 minute buffer before expiration
    const { idle } = useIdle(60 * 1000)
    const now = useTimestamp({ interval: 1000 })
    const expirySeconds = computed(() => Math.floor((expiresAt - now.value) / 1000))

    watch(expirySeconds, async (secondsLeft) => {
      if (idle.value && secondsLeft <= 0) {
        handleExpiredSession() // Logout idle users when session has expired
      } else if (!idle.value && secondsLeft <= BUFFER_SECONDS) {
        expiresAt = await renewZendeskSession() // Renew session of active user when buffer is reached
      }
    })

    watch(idle, async (idleValue) => {
      // Immediately renew session when coming out of idle state
      if (!idleValue) expiresAt = await renewZendeskSession()
    })
  }

  return session
}

const renewZendeskSession = async () => {
  try {
    const res = await zendeskApi
      .extend({ hooks: { afterResponse: undefined } })
      .extend({ hooks: { afterResponse: [] } })
      .get('v2/users/me/session/renew.json')
    return Number(res.headers.get('X-Zendesk-User-Session-Expires-At') || 0) * 1000
  } catch (err: unknown) {
    if (err instanceof APIError && err.status === 401) {
      handleExpiredSession()
    } else {
      throw err
    }
  }
}

const handleExpiredSession = () => {
  if (embeddedRuntime()) {
    location.href = '/qa/extension/sso-logout'
  } else {
    location.href = generateZendeskLoginUrl(location.pathname + location.search)
  }
}

const contextSwitchParams = () => {
  const url = new URL(window.location.href)
  const response: { accountId?: number; workspaceId?: number; locale?: string } = {}
  const account = url.searchParams.get('account')
  const workspace = url.searchParams.get('workspace')
  const locale = getSupportedLocale(url.searchParams.get('lang') || '')

  if (!account && !workspace && !locale) return
  if (account) response.accountId = parseInt(account, 10)
  if (workspace) response.workspaceId = parseInt(workspace, 10)
  if (locale) response.locale = locale

  url.searchParams.delete('account')
  url.searchParams.delete('workspace')
  url.searchParams.delete('lang')

  history.replaceState(null, '', url.toString())

  return response
}
