import { Auth0Client } from '@auth0/auth0-spa-js'
import { WebAuth } from 'auth0-js'

export type { Auth0Error } from 'auth0-js'

import type { Locale } from '@/i18n/types'
import config, { embeddedRuntime, hasZendeskProxy } from '@/config'
import { oldApi } from '@/api'

const auth0JS = new WebAuth({
  domain: config.authDomain,
  clientID: config.authClientId,
  redirectUri: window.location.origin,
  responseType: 'token id_token',
  scope: 'openid email profile user_metadata offline_access',
  _timesToRetryFailedRequests: 3,
})

export const auth0 = new Auth0Client({
  domain: config.authDomain,
  clientId: config.authClientId,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
})

export const getAccessToken = () => auth0.getTokenSilently()

export const generateZendeskLoginUrl = (returnTo: string) => {
  return `/auth/v2/login/signin?return_to=${encodeURIComponent(returnTo)}`
}

export const setLoginReturnPath = (fullPath: string) => {
  if (embeddedRuntime()) return
  sessionStorage.loginReturnPath = fullPath
}

export const getLoginReturnPath = () => {
  const path = sessionStorage.loginReturnPath
  const embedded = embeddedRuntime()
  delete sessionStorage.loginReturnPath

  if (!path || embedded) return
  if (path.startsWith('/extension') && !embedded) return

  return path
}

export const logout = (searchParams?: Record<string, string>, pathname?: string) => {
  // Use ZD logout url when proxied
  if (hasZendeskProxy) {
    let logoutUrl = '/access/logout?return_to='

    // Return to extension primary domain login page
    if (embeddedRuntime()) {
      logoutUrl += encodeURIComponent('/qa/extension/sso-logout')
    } else {
      logoutUrl += encodeURIComponent('/qa')
    }

    location.href = logoutUrl
    return
  }

  const url = new URL(config.authLogoutUrl)
  if (pathname) url.pathname = pathname
  url.search = new URLSearchParams(searchParams).toString()

  auth0.logout({
    logoutParams: { returnTo: url.toString() },
  })
}

export const login = (email: string, password: string) =>
  new Promise<void>((resolve, reject) => {
    auth0JS.login({ email, password }, (err) => {
      if (err) return reject(err)
      resolve()
    })
  })

export const googleLogin = () =>
  auth0.loginWithPopup({ authorizationParams: { connection: 'google-oauth2', prompt: 'select_account' } })

export const slackLogin = () => auth0.loginWithPopup({ authorizationParams: { connection: 'slack' } })

export const changePassword = (email: string) =>
  new Promise<void>((resolve, reject) => {
    auth0JS.changePassword({ connection: 'Username-Password-Authentication', email }, (err) => {
      if (err) return reject(err)
      resolve()
    })
  })

export const signup = (email: string, password: string, userMetadata: any = {}) =>
  new Promise<void>((resolve, reject) => {
    auth0JS.signup({ email, password, connection: 'Username-Password-Authentication', userMetadata }, (err) => {
      if (err) return reject(err)
      resolve()
    })
  })

export const passwordlessStart = (method: 'code' | 'link', email: string) =>
  new Promise<void>((resolve, reject) => {
    auth0JS.passwordlessStart({ connection: 'email', send: method, email }, (err) => {
      if (err) return reject(err)
      resolve()
    })
  })

export const passwordlessLogin = (email: string, verificationCode: string) =>
  new Promise<void>((resolve, reject) => {
    auth0JS.passwordlessLogin({ connection: 'email', email, verificationCode }, (err) => {
      if (err) return reject(err)
      resolve()
    })
  })

interface RegisterUserPayload {
  agreed?: true
  accessToken: string
  estimateSeatCount?: string
  firstDayOfWeek?: string
  heardAboutKlausFrom?: string
  idToken: string
  inviteId?: number
  locale?: Locale
  email?: string
  inviteToken?: string
  paymentPlanName?: string
  paymentPlanPhoneNumber?: string
  paymentPlanTimeZone?: string
  paymentPlanCountry?: string
  pictureUrl?: string
  userFirstName?: string
  userLastName?: string
  zendeskCustomer?: boolean
}

export const parseInviteTokenPayload = (tokenPayload: string) => {
  const json = atob(tokenPayload.replace(/_/g, '/').replace(/-/g, '+'))
  const { email, token } = JSON.parse(json)
  return { email, inviteToken: token }
}

export const registerUser = ({ accessToken, idToken, ...data }: RegisterUserPayload) =>
  oldApi
    .post('users/register', {
      hooks: undefined,
      headers: { Authorization: `Bearer ${accessToken}`, 'X-Id-Token': idToken },
      json: { appVersion: config.gitCommit, ...data },
    })
    .json<{ data: number }>()
    .then((d) => d.data)

export const acceptInvite = (inviteId: number) => oldApi.post(`users/accept-invite/${inviteId}`)
