import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onKeyup"]
const _hoisted_2 = {
  class: "absolute inset-0 m-auto flex h-4/5 w-4/5 flex-col items-center justify-center border-none bg-transparent outline-none",
  tabindex: "-1"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]

import { defineComponent, nextTick, ref, watch } from 'vue'
import { TransitionFade } from '@morev/vue-transitions'
import { XIcon, ExternalLinkIcon } from '@klausapp/ui-kit/icons'
import { GlobalEvents } from '@klausapp/ui-kit'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLightbox',
  props: {
    show: { type: Boolean },
    src: {}
  },
  emits: ["close", "focus"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

defineComponent({
  name: 'UiLightbox',
})

const dialog = ref<HTMLDialogElement | null>(null)

const closeModal = () => {
  emit('close')
}

watch(
  () => props.show,
  async (val) => {
    if (val) {
      await nextTick()
      dialog.value?.focus()
      emit('focus')
    }
  },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionFade), { duration: 200 }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['bg-black/24 fixed inset-0 z-20 overflow-y-auto', _ctx.$style.uiLightboxBackdrop]),
            role: "button",
            tabindex: "0",
            onClick: closeModal,
            onKeyup: [
              _withKeys(_withModifiers(closeModal, ["exact"]), ["enter"]),
              _withKeys(_withModifiers(closeModal, ["exact"]), ["space"])
            ]
          }, [
            _createElementVNode("dialog", _hoisted_2, [
              _createVNode(_unref(GlobalEvents), {
                "no-filtering": "",
                onKeyup: _withKeys(closeModal, ["esc"])
              }),
              _createElementVNode("div", {
                class: "relative flex max-h-full max-w-full justify-center bg-transparent outline-none",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("img", {
                  src: _ctx.src,
                  alt: "image",
                  class: _normalizeClass(['mx-auto max-h-full max-w-full rounded-md object-contain shadow-2xl', _ctx.$style.uiLightboxImg])
                }, null, 10 /* CLASS, PROPS */, _hoisted_3),
                _createElementVNode("button", {
                  class: "text-gray-80 bg-gray-20 absolute -right-12 top-0 flex h-8 w-8 items-center justify-center rounded-xl outline-none transition-colors duration-300 hover:bg-white",
                  type: "button",
                  onClick: closeModal
                }, [
                  _createVNode(_unref(XIcon), { class: "icon-m" })
                ]),
                _createElementVNode("a", {
                  href: _ctx.src,
                  target: "_blank",
                  rel: "noreferrer noopener",
                  class: "text-gray-80 bg-gray-20 absolute -right-12 bottom-0 flex h-8 w-8 items-center justify-center rounded-xl outline-none transition-colors duration-300 hover:bg-white"
                }, [
                  _createVNode(_unref(ExternalLinkIcon), { class: "icon-m" })
                ], 8 /* PROPS */, _hoisted_4)
              ])
            ])
          ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})