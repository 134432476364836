import i18n from '@/i18n'
import { session } from '@/composables/useSession'

export function openSupport() {
  if (session.features.z2SunCoWidgetDev) {
    window.__z2SunCoWidgetRef?.show()
  } else {
    const supportUrl = i18n.t('support_links.zendesk_help_widget')
    window.open(supportUrl, '_blank', 'noopener noreffer')
  }
}

export const zdSupportEmail = 'support-zendesk-qa@zendesk.com'

export function subscriptionMailTo(state: 'trial' | 'active') {
  const subject =
    state === 'trial'
      ? i18n.t('settings.subscription.support_email.trial.subject')
      : i18n.t('settings.subscription.support_email.customer.subject')

  const body =
    state === 'trial'
      ? i18n.t('settings.subscription.support_email.trial.body')
      : i18n.t('settings.subscription.support_email.customer.body')

  return `mailto:${zdSupportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}
