import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "min-w-[254px] rounded-md p-1" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flex items-center gap-2" }

import { ref, onMounted } from 'vue'
import { CheckIcon } from '@klausapp/ui-kit/icons'
import { theme } from '@klausapp/ui-kit'
import { Tippy } from '@/components/Tippy'
import i18n from '@/i18n'
import { translationExists } from '@/i18n/utils'
import { hasZendeskProxy } from '@/config'
import { hasAccountRole } from '@/utils/roleUtils'

import { getZendeskProducts } from './api'
import type { ZendeskProductItem, ZendeskProductKey } from './types'
import AdminIcon from './icons/admin.svg'
import AiAgentsIcon from './icons/ai-agents.svg'
import CommunityIcon from './icons/community.svg'
import ChatIcon from './icons/chat.svg'
import VoiceIcon from './icons/voice.svg'
import AnalyticsIcon from './icons/analytics.svg'
import SalesIcon from './icons/sales.svg'
import WfmIcon from './icons/wfm.svg'
import QaIcon from './icons/qa.svg'
import SupportIcon from './icons/support.svg'
import KnowledgeIcon from './icons/knowledge.svg'

const activeProduct = 'quality_assurance'

// The order of the zd products in ZENDESK_PRODUCT_KEYS will be also the order menu items in the product tray

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductTray',
  setup(__props) {

const tippyRef = ref<InstanceType<typeof Tippy>>()
const isDropdownVisible = ref(false)
const ZENDESK_PRODUCT_KEYS: ZendeskProductKey[] = [
  { key: 'lotus' },
  { key: 'knowledge_base' },
  { key: 'ai_agents' },
  { key: 'chat' },
  { key: 'voice' },
  { key: 'analytics' },
  { key: 'workforce_management' },
  { key: 'quality_assurance' },
  { key: 'community' },
  { key: 'sales' },
  { key: 'central_admin' },
]

const zendeskProductKeys = ref<ZendeskProductKey[]>(structuredClone(ZENDESK_PRODUCT_KEYS))

const products = ref<ZendeskProductItem[]>([])

const mapProducts = (products: ZendeskProductKey[]) => {
  const availableProducts = products.map((p) => ({
    key: p.key,
    globalName: i18n.t(`zendesk_product_tray.${p.key}`),
    href: linkMap[p.key as keyof typeof linkMap],
    icon: p.key,
  }))

  const orderKeys = ZENDESK_PRODUCT_KEYS.map((p) => p.key)
  const orderedProducts = availableProducts.sort((a, b) => orderKeys.indexOf(a.key) - orderKeys.indexOf(b.key))

  return orderedProducts
}

const linkMap = {
  lotus: '/agent',
  knowledge_base: '/hc/start',
  community: '/gather/start',
  chat: '/chat/start',
  voice: '/talk/start',
  analytics: '/explore',
  sales: '/sell/start',
  workforce_management: '/wfm/v2/agent-activity',
  quality_assurance: '/qa',
  ai_agents: '/admin/channels/ai-agents-automation/ai-agents?ref=product_tray',
  central_admin: '/admin',
}

const iconMap = {
  lotus: SupportIcon,
  knowledge_base: KnowledgeIcon,
  community: CommunityIcon,
  chat: ChatIcon,
  voice: VoiceIcon,
  analytics: AnalyticsIcon,
  sales: SalesIcon,
  workforce_management: WfmIcon,
  quality_assurance: QaIcon,
  ai_agents: AiAgentsIcon,
  central_admin: AdminIcon,
}

const hidePopover = () => {
  tippyRef.value?.tip.hide()
}

onMounted(async () => {
  if (hasZendeskProxy) {
    const data = await getZendeskProducts()
    zendeskProductKeys.value = data.products
  }

  products.value = mapProducts(zendeskProductKeys.value)

  if (hasZendeskProxy) {
    products.value = products.value.filter((p) => translationExists(`zendesk_product_tray.${p.key}`))

    if (!hasAccountRole('ADMIN')) {
      products.value = products.value.filter((p) => p.key !== 'ai_agents')
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Tippy), {
    ref_key: "tippyRef",
    ref: tippyRef,
    trigger: "click",
    theme: "light paddingless",
    placement: "right-end",
    arrow: false,
    interactive: "",
    "hide-on-click": true,
    onKeydown: _withKeys(hidePopover, ["esc"]),
    onHide: _cache[1] || (_cache[1] = ($event: any) => (isDropdownVisible.value = false))
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("button", {
        "data-testid": "zendesk-product-tray-trigger",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isDropdownVisible.value = !isDropdownVisible.value))
      }, [
        _createVNode(_unref(theme).icons.productTray)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, (product) => {
          return (_openBlock(), _createElementBlock("li", {
            key: product.key,
            class: "my-0.5"
          }, [
            _createElementVNode("a", {
              href: product.href,
              rel: "noopener noreferrer",
              target: "_blank",
              class: _normalizeClass([
            'text-gray-80 hover:bg-zblue-10 flex min-h-8 w-full items-center justify-between gap-2 rounded-sm py-1 pl-2 pr-3 text-sm font-medium',
            { 'bg-zblue-10': product.key === activeProduct },
          ])
            }, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(iconMap[product.icon as keyof typeof iconMap]), { class: "icon-m text-gray-60" })),
                _createElementVNode("span", null, _toDisplayString(product.globalName), 1 /* TEXT */)
              ]),
              (product.key === activeProduct)
                ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                    key: 0,
                    class: "icon-m text-zblue-60"
                  }))
                : _createCommentVNode("v-if", true)
            ], 10 /* CLASS, PROPS */, _hoisted_2)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}
}

})