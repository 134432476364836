import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["open"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "q-modal__subtitle text-sm" }
const _hoisted_4 = {
  key: 0,
  class: "q-modal__hanging-footer text-xs"
}
const _hoisted_5 = { class: "q-modal__hanging-content" }

import { nextTick, ref, watch } from 'vue'
import { TransitionFade } from '@morev/vue-transitions'
import { XIcon } from '@klausapp/ui-kit/icons'
import { GlobalEvents } from '@klausapp/ui-kit'
import analytics from '@/utils/analytics'
import { embeddedRuntime } from '@/config'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'QModal',
  props: {
    title: {},
    subtitle: {},
    closeButton: { type: Boolean },
    show: { type: Boolean },
    dismissable: { type: Boolean },
    hangingContent: {},
    propagateEvents: { type: Boolean },
    full: { type: Boolean },
    wide: { type: Boolean },
    narrow: { type: Boolean },
    extraNarrow: { type: Boolean },
    scrollable: { type: Boolean },
    backdrop: {},
    footerBorder: { type: Boolean },
    headerDivider: { type: Boolean },
    noContentPadding: { type: Boolean }
  },
  emits: ["close", "focus"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit



const dialog = ref<HTMLDialogElement | null>(null)

const closeModal = () => {
  emit('close')
  if (props.title) analytics.modalClosed(props.title)
}
const handleEscape = (e: KeyboardEvent) => {
  if (props.dismissable && props.show && e.key === 'Escape') {
    closeModal()
  }
}

watch(
  () => props.show,
  async (val) => {
    if (val) {
      await nextTick()
      dialog.value?.focus()
      if (props.title) analytics.modalOpened(props.title)
      emit('focus')
    }
  },
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_unref(TransitionFade), { duration: 200 }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["q-modal__backdrop", _ctx.backdrop ? `-${_ctx.backdrop}` : '']),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dismissable ? _ctx.$emit('close') : null)),
              onKeydown: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.propagateEvents ? $event.stopPropagation() : null))
            }, [
              _withDirectives(_createElementVNode("dialog", _mergeProps({
                ref_key: "dialog",
                ref: dialog,
                open: _ctx.show,
                class: ["q-modal focus-visible:outline-none", [
            _ctx.$attrs.class,
            _unref(embeddedRuntime)() && 'q-modal--embedded',
            {
              '-wide': _ctx.wide,
              '-full': _ctx.full,
              '-narrow': _ctx.narrow,
              '-extraNarrow': _ctx.extraNarrow,
            },
          ]]
              }, _ctx.$attrs, { tabindex: "-1" }), [
                _createVNode(_unref(GlobalEvents), {
                  "no-filtering": "",
                  onKeyup: _withKeys(handleEscape, ["esc"])
                }),
                _createElementVNode("div", {
                  class: _normalizeClass(["q-modal__wrapper", { 'q-modal__wrapper-max-height': _ctx.scrollable, 'p-0': _ctx.headerDivider || _ctx.noContentPadding }]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, [
                  (_ctx.title || _ctx.$slots.header)
                    ? (_openBlock(), _createElementBlock("header", {
                        key: 0,
                        class: _normalizeClass({
                'q-modal__header text-lg font-medium': _ctx.title,
                'z-10 rounded-t-lg p-8 pb-4 shadow-sm': _ctx.headerDivider,
              })
                      }, [
                        (_ctx.$slots.header)
                          ? _renderSlot(_ctx.$slots, "header", { key: 0 })
                          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1 /* TEXT */),
                              _createElementVNode("p", _hoisted_3, [
                                _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                                  _createTextVNode(_toDisplayString(_ctx.subtitle), 1 /* TEXT */)
                                ])
                              ])
                            ])),
                        (_ctx.closeButton)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              type: "button",
                              class: "q-modal__wrapper__closeBtn flex items-center justify-center",
                              onClick: closeModal
                            }, [
                              _createVNode(_unref(XIcon), { class: "icon-m" })
                            ]))
                          : _createCommentVNode("v-if", true)
                      ], 2 /* CLASS */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("section", {
                    class: _normalizeClass(["q-modal__content", { '!mt-0': _ctx.headerDivider, 'p-8 pt-5': _ctx.headerDivider && !_ctx.noContentPadding }])
                  }, [
                    _renderSlot(_ctx.$slots, "default")
                  ], 2 /* CLASS */),
                  (_ctx.$slots.actions)
                    ? (_openBlock(), _createElementBlock("footer", {
                        key: 1,
                        class: _normalizeClass(['q-modal__footer', { 'q-modal__footer-bordered': _ctx.footerBorder }])
                      }, [
                        _renderSlot(_ctx.$slots, "actions"),
                        (_ctx.hangingContent)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.hangingContent), 1 /* TEXT */)
                            ]))
                          : _createCommentVNode("v-if", true)
                      ], 2 /* CLASS */))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ], 16 /* FULL_PROPS */, _hoisted_1), [
                [_vShow, _ctx.show]
              ])
            ], 34 /* CLASS, NEED_HYDRATION */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}
}

})