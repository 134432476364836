import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiSpinner',
  props: {
    delay: { default: 200 },
    background: { default: 'solid' },
    noSpinnerBackground: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    spinnerColor: { default: 'active-primary' }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: [_ctx.$style.overlay, _ctx.$style[_ctx.background], _ctx.inline && _ctx.$style.inline, _ctx.$attrs.class]
  }, _ctx.$attrs), [
    (_openBlock(), _createElementBlock("svg", {
      width: "16",
      height: "16",
      viewBox: "0 0 16 16",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      class: _normalizeClass([_ctx.$style.spinner, _ctx.noSpinnerBackground && _ctx.$style.noBackground]),
      style: _normalizeStyle({ '--spinner-delay': _ctx.delay })
    }, [
      _createElementVNode("path", {
        d: "M4.42164 15.1551C3.0065 14.4474 1.833 13.3359 1.04955 11.9612C0.266095 10.5865 -0.0921263 9.01042 0.0201825 7.43216C0.132491 5.8539 0.710286 4.34438 1.6805 3.0945C2.65072 1.84463 3.96978 0.910516 5.47088 0.410302C6.97198 -0.0899118 8.5877 -0.133765 10.1137 0.284289C11.6397 0.702343 13.0075 1.56353 14.0441 2.75894C15.0807 3.95435 15.7395 5.4303 15.9373 7.00015C16.135 8.56999 15.8628 10.1632 15.1551 11.5784L12.8902 10.4457C13.3739 9.47847 13.56 8.38956 13.4248 7.31664C13.2896 6.24372 12.8394 5.23497 12.1309 4.41795C11.4224 3.60094 10.4876 3.01236 9.44464 2.72664C8.40167 2.44091 7.29739 2.47088 6.27145 2.81276C5.24551 3.15464 4.34399 3.79306 3.68089 4.6473C3.01779 5.50154 2.62289 6.53323 2.54613 7.6119C2.46937 8.69058 2.7142 9.76779 3.24966 10.7073C3.78512 11.6468 4.58715 12.4065 5.55434 12.8902L4.42164 15.1551Z",
        style: _normalizeStyle({ fill: `var(--${_ctx.spinnerColor})` })
      }, null, 4 /* STYLE */)
    ], 6 /* CLASS, STYLE */))
  ], 16 /* FULL_PROPS */))
}
}

})