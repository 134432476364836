import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "data-testid": "q-error-cat",
  class: "fixed bottom-6 right-6 z-10 w-[35rem] overflow-hidden rounded bg-white shadow-sm"
}
const _hoisted_2 = { class: "flex h-16 items-center bg-red-50 px-6 py-4" }
const _hoisted_3 = { class: "text-lg font-medium text-white" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "p-6" }
const _hoisted_6 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_7 = { class: "text-xs leading-[18px]" }
const _hoisted_8 = { class: "list-disc pl-4 text-xs leading-[18px]" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "mb-6 mt-2 break-words text-xs"
}
const _hoisted_11 = { class: "flex flex-wrap items-center gap-2" }
const _hoisted_12 = { class: "text-xs text-gray-50" }

import { ref } from 'vue'

import { XIcon } from '@klausapp/ui-kit/icons'

import i18n from '@/i18n'
import { useEvent } from '@/composables/useEvent'
import { copy } from '@/utils/clipboard'
import { openSupport } from '@/utils/support'
import analytics from '@/utils/analytics'
import { formatDateIntl } from '@/utils/date'

import type { CatError } from '../types'


export default /*@__PURE__*/_defineComponent({
  __name: 'QErrorCat',
  setup(__props) {

const dateFormatting: Intl.DateTimeFormatOptions = {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
}

const show = ref(false)
const message = ref('')
const code = ref<string | number>(0)
const status = ref<number | undefined>(undefined)
const date = ref(new Date())
const debugLog = ref('')
let errorTimeout: ReturnType<typeof setTimeout> | undefined

const handleError = async (err: CatError) => {
  if (typeof err.error === 'string') {
    try {
      err = JSON.parse(err.error)
    } catch {
      console.error('Error is not valid JSON. Displaying plain-text error')
    }
  }

  show.value = true

  if (err.ref === 'access-denied') message.value = err.error
  else if (err.status === 401) message.value = i18n.t('universal.errors.401_v2')
  else if (err.status === 403) message.value = i18n.t('universal.errors.403')

  code.value = err.ref || err.code || 0
  status.value = err.status

  const parsedDate = err.dateTime ? Date.parse(err.dateTime) : err.timestamp
  date.value = parsedDate ? new Date(parsedDate) : new Date()

  debugLog.value = await generateDebugLog(err)

  errorTimeout = setTimeout(resetError, 3 * 60 * 1000)
}

const buildDebugObject = async (obj: Request) => {
  let body

  try {
    body = await obj.clone().json()
  } catch {
    body = obj.body
  }

  const debug = {
    url: obj.url,
    method: 'method' in obj ? obj.method : undefined,
    headers: {
      Account: obj.headers.get('X-Klaus-Account'),
      Workspace: obj.headers.get('X-Klaus-Workspace'),
    },
    body,
  }

  return JSON.stringify(debug, null, 2)
}

const generateDebugLog = async ({ ref, request, response, error, message }: CatError) => {
  const log = []
  console.info('req', request)

  if (request) {
    log.push('Request:')
    const formattedReq = await buildDebugObject(request)
    log.push(formattedReq)
  }

  if (response) {
    log.push('Response:')
    log.push(JSON.stringify(response))
  }

  if (ref) {
    log.push('Reference:\n' + ref)
  }

  if (error || message) {
    log.push('Error:\n' + typeof error === 'object' && 'en' in error ? error.en : error || message)
  }

  log.push('Time:')
  log.push(Intl.DateTimeFormat('en-US', dateFormatting).format(date.value))

  return log.join('\n')
}

const copyDebugInfo = () => {
  copy(debugLog.value)
}

const openChat = () => {
  openSupport()
  analytics.navbarEvent('Clicked navbar popup item', 'Chat with us')
}

const resetError = () => {
  show.value = false
  message.value = ''
  code.value = 0
  debugLog.value = ''
  errorTimeout && clearTimeout(errorTimeout)
  errorTimeout = undefined
}

useEvent('universal-error', handleError)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('modules.application.error_cat.heading')) + " ", 1 /* TEXT */),
          (status.value)
            ? (_openBlock(), _createElementBlock("strong", _hoisted_4, "(" + _toDisplayString(status.value) + ")", 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("button", {
          class: "absolute right-0 top-0 p-4 text-white",
          type: "button",
          onClick: resetError
        }, [
          _createVNode(_unref(XIcon), { class: "icon-m" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (code.value !== 'access-denied')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('modules.application.error_cat.description')), 1 /* TEXT */),
              _createElementVNode("ul", _hoisted_8, [
                (debugLog.value)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_9, _toDisplayString(_ctx.$t('modules.application.error_cat.bullet1')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t('modules.application.error_cat.bullet2')), 1 /* TEXT */)
              ])
            ]))
          : _createCommentVNode("v-if", true),
        (message.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(message.value), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("footer", _hoisted_11, [
          (debugLog.value && code.value !== 'access-denied')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "bg-blue-20 h-6 rounded-full px-3 text-xs font-medium",
                onClick: copyDebugInfo
              }, _toDisplayString(_ctx.$t('modules.application.error_cat.copy_debug')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (code.value !== 'access-denied')
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "bg-active-primary mr-4 h-6 rounded-full px-3 text-xs font-medium text-white",
                type: "button",
                onClick: openChat
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.chat')), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(formatDateIntl)(date.value, dateFormatting)), 1 /* TEXT */)
        ])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, show.value]
    ])
  ]))
}
}

})