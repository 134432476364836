import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

import { defineComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiEmptyState',
  props: {
    title: { default: '' },
    subtitle: { default: '' },
    alignment: { default: 'vertical' },
    size: { default: 'medium' },
    lightIconBg: { type: Boolean }
  },
  setup(__props: any) {



defineComponent({
  name: 'UiEmptyState',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.wrapper, _ctx.alignment === 'horizontal' ? _ctx.$style.horizontal : _ctx.$style.vertical, _ctx.$style[_ctx.size]])
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.icon, _ctx.lightIconBg && 'bg-white mix-blend-normal'])
        }, [
          _renderSlot(_ctx.$slots, "icon")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-gray-80 mb-4 font-medium", _ctx.$style.title])
      }, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.subtitle)
      }, [
        _renderSlot(_ctx.$slots, "subtitle", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.subtitle), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */),
      (_ctx.$slots.action)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.action)
          }, [
            _renderSlot(_ctx.$slots, "action")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.$slots.link)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.link)
          }, [
            _renderSlot(_ctx.$slots, "link")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}
}

})