import config, { hasZendeskProxy, embeddedRuntime } from '@/config'
import { api } from '@/api'
import { session } from './composables/useSession'

declare global {
  interface Window {
    __z2SunCoWidgetRef: any
    Z2SunCoWidget: any
  }
}

export const getJWTTokenProxy = () => api.get('account/sunco-z2-jwt').json<any>()

export function loadZ2SunCoWidget() {
  const Z2_SUNCO_WIDGET_URL_STAGING = 'https://static-staging.zdassets.com/z2-sunco-widget/z2-sunco-widget.js'
  const Z2_SUNCO_WIDGET_URL_PROD = 'https://static.zdassets.com/z2-sunco-widget/z2-sunco-widget.js'

  if (!session.features.z2SunCoWidgetDev || embeddedRuntime()) return

  const nonce = '__CSP_NONCE__'
  const isStaging = config.flagManager

  // Create the script element
  const widgetScript = document.createElement('script')
  widgetScript.setAttribute('nonce', nonce)
  widgetScript.async = true

  // Set the script and jwt sources based on the environment
  const widgetScriptUrl = isStaging ? Z2_SUNCO_WIDGET_URL_STAGING : Z2_SUNCO_WIDGET_URL_PROD
  widgetScript.setAttribute('src', widgetScriptUrl)

  // Configure the script's `onload` behavior
  widgetScript.onload = function () {
    console.info('[Z2SunCoWidget] | widgetScript - LOADING...')

    window.__z2SunCoWidgetRef = new window.Z2SunCoWidget({
      inProduct: true,
      resetAuth: true,
      jwtUrl: getJWTTokenProxy,
      position: {
        top: 'auto',
        right: '16px',
        bottom: '16px',
        left: 'auto',
      },
      orientation: 'right',
      openWidgetToNewConversation: false,
    })

    console.info('[Z2SunCoWidget] | widgetScript - DONE', { isStaging, hasZendeskProxy })
  }

  // Set the script source
  widgetScript.onerror = () => console.info('[Z2SunCoWidget] | ERROR while loading the script')

  // Append the script to the document head
  document.head.appendChild(widgetScript)
}
