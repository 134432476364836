import { ref, computed, onMounted } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { getLookerDashboards } from '@/modules/dashboard/api'
import { getLookerDashboardByName } from '@/modules/dashboard/looker/utils/lookup'
import type { LookerDashboard } from '@/modules/dashboard/looker/types'

import { session } from './useSession'

export const useDashboardsList = createSharedComposable(() => {
  const dashboardList = ref<LookerDashboard[]>([])

  const lookerAutoQaDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'auto_qa'))
  const lookerReviewsDashboard = computed(() => {
    let dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited' : 'reviews'
    if (session.features.advancedScorecardsDev) {
      dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited_v2' : 'reviews_v2'
    }
    return getLookerDashboardByName(dashboardList.value, dashboardName)
  })
  const lookerReviewsAgentDashboard = computed(() => {
    let dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited_agent' : 'reviews_agent'
    if (session.features.advancedScorecardsDev) {
      dashboardName = session.features.lookerReviewNoPassRateDashboard ? 'reviews_limited_agent_v2' : 'reviews_agent_v2'
    }
    return getLookerDashboardByName(dashboardList.value, dashboardName)
  })
  const lookerBotQaDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'botqa'))
  const lookerAssignmentsDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'assignments'))
  const lookerCategoriesDashboard = computed(() => getLookerDashboardByName(dashboardList.value, 'categories'))
  const lookerCategoriesAgentDashboard = computed(() =>
    getLookerDashboardByName(dashboardList.value, 'categories_agent'),
  )

  onMounted(async () => {
    const { dashboards } = await getLookerDashboards()
    dashboardList.value = dashboards
  })

  return {
    dashboardList,
    lookerAutoQaDashboard,
    lookerReviewsDashboard,
    lookerReviewsAgentDashboard,
    lookerBotQaDashboard,
    lookerAssignmentsDashboard,
    lookerCategoriesDashboard,
    lookerCategoriesAgentDashboard,
  }
})
