import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import { UiBadgeTheme } from '../types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiBadge',
  props: {
    theme: { default: UiBadgeTheme.Default }
  },
  setup(__props: any) {

const props = __props

const classMap = {
  [UiBadgeTheme.Default]: 'bg-gray-20 text-gray-60',
  [UiBadgeTheme.Dark]: 'bg-badge-dark-bg text-badge-dark-color',
  [UiBadgeTheme.Highlighted]: 'bg-blue-50 text-white',
  [UiBadgeTheme.Yellow]: 'bg-badge-yellow-bg text-badge-yellow-color',
  [UiBadgeTheme.Pink]: 'bg-badge-pink-bg text-badge-pink-color',
}

const badgeClass = computed(() => classMap[props.theme] || classMap[UiBadgeTheme.Default])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["flex h-4 min-w-5 items-center justify-center rounded-md p-1 text-xs font-medium", badgeClass.value])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})