import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-expanded", "aria-controls"]
const _hoisted_2 = { class: "text-nav-icon flex *:h-5 *:w-5 empty:hidden" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id", "aria-labelledby", "hidden"]
const _hoisted_5 = {
  key: 0,
  class: "text-gray-80 px-4 pb-4 text-xs"
}
const _hoisted_6 = {
  key: 1,
  class: "rounded-b bg-white"
}

import { inject, computed, useSlots } from 'vue'
import { kebabCase } from 'lodash-es'
import { ChevronDownIcon } from '@klausapp/ui-kit/icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAccordionItem',
  props: {
    title: {}
  },
  setup(__props: any) {

const props = __props

const slots = useSlots()

const toggleActiveItem = inject<(name: string) => void>('toggleActiveItem')
const getActiveItems = inject<() => string[]>('getActiveItems')

if (!toggleActiveItem || !getActiveItems) {
  throw new Error('UiAccordionItem must be used inside an UiAccordion component.')
}

if (!slots.default && !slots.list) {
  throw new Error('UiAccordionItem must have a filled slot.')
}

const transformedTitle = computed(() => kebabCase(props.title))
const isActive = computed(() => getActiveItems().includes(transformedTitle.value))

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Enter' || event.key === 'Spacebar') {
    event.preventDefault()

    toggleActiveItem(transformedTitle.value)
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["border-gray-30 border border-b-0 first:rounded-t last:rounded-b last:border-b", isActive.value && _unref(slots).default ? 'bg-white' : 'bg-gray-10'])
  }, [
    _createElementVNode("button", {
      type: "button",
      "aria-expanded": isActive.value,
      "aria-controls": transformedTitle.value,
      class: "flex w-full items-center gap-2 p-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleActiveItem)(transformedTitle.value))),
      onKeydown: handleKeydown
    }, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon")
      ]),
      _createElementVNode("h3", {
        id: `${transformedTitle.value}-title`,
        class: "text-gray-80 grow text-start text-sm font-semibold"
      }, _toDisplayString(_ctx.title), 9 /* TEXT, PROPS */, _hoisted_3),
      _createVNode(_unref(ChevronDownIcon), {
        class: _normalizeClass(["icon-m text-gray-60", isActive.value && 'rotate-180'])
      }, null, 8 /* PROPS */, ["class"])
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1),
    _createElementVNode("div", {
      id: transformedTitle.value,
      "aria-labelledby": `${transformedTitle.value}-title`,
      hidden: !isActive.value
    }, [
      (_unref(slots).default)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("v-if", true),
      (_unref(slots).list)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
            _renderSlot(_ctx.$slots, "list")
          ]))
        : _createCommentVNode("v-if", true)
    ], 8 /* PROPS */, _hoisted_4)
  ], 2 /* CLASS */))
}
}

})