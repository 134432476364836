import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["onClick", "onKeydown"]

import { ref } from 'vue'
import useOverflowCheck from '@klausapp/shared/composables/useOverflowCheck'

import UiBadge from '../../UiBadge.vue'
import UiMenuBaseTrigger from './UiMenuBaseTrigger.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuQuickNavTrigger',
  props: {
    active: { type: Boolean },
    content: {},
    loadingText: {}
  },
  setup(__props: any) {



const delay = [400, 0]
const titleRef = ref<HTMLElement>()

const { isOverflowing } = useOverflowCheck(titleRef)

const buttonRef = ref<HTMLButtonElement | null>(null)

const handleFocus = () => {
  buttonRef.value?.focus()
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(UiMenuBaseTrigger, { onFocus: handleFocus }, {
    default: _withCtx(({ openMenu }) => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        ref_key: "buttonRef",
        ref: buttonRef,
        type: "button",
        "data-testid": "quick-nav",
        class: _normalizeClass([
          'focus:bg-gray-20 hover:bg-gray-20 text-gray-90 group flex cursor-pointer items-center gap-2 rounded px-2 py-1 font-medium duration-200 ease-out',
          _ctx.active && 'bg-gray-20',
        ]),
        onClick: openMenu,
        onKeydown: _withKeys(openMenu, ["enter"])
      }, [
        _withDirectives((_openBlock(), _createElementBlock("span", {
          ref_key: "titleRef",
          ref: titleRef,
          key: _ctx.content,
          class: "truncate"
        }, [
          _createTextVNode(_toDisplayString(_ctx.content || _ctx.loadingText), 1 /* TEXT */)
        ])), [
          [_directive_tippy, {
            content: _unref(isOverflowing) ? _ctx.content : null,
            delay: delay,
          }]
        ]),
        (_ctx.$slots.badge)
          ? (_openBlock(), _createBlock(UiBadge, {
              key: 0,
              class: _normalizeClass(['group-hover:bg-white', _ctx.active && 'bg-white'])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "badge")
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["class"]))
          : _createCommentVNode("v-if", true)
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)), [
        [_directive_tippy, { content: _ctx.content }]
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})