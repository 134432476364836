import { Centrifuge, type SubscriptionOptions } from 'centrifuge'
import type { PushAuthResponse } from '@zendesk/zqa-services/users'

import config from '@/config'
import { api } from '@/api'

export type { Subscription } from 'centrifuge'

export const centrifuge = new Centrifuge(`wss://app.${config.domainRoot}/push/connection/websocket`, {
  async getToken() {
    const { token } = await api.get('push/authorize-connection').json<PushAuthResponse>()
    return token
  },
})

export function newSubscription(channel: string, metadata = {}, options: Partial<SubscriptionOptions> = {}) {
  return centrifuge.newSubscription(channel, {
    async getToken({ channel }) {
      const { token } = await api
        .post(`push/authorize-channel/${channel}`, { json: { metadata } })
        .json<PushAuthResponse>()
      return token
    },
    ...options,
  })
}
