import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent, ref, watch } from 'vue'

import UiInput from './UiInput.vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiEmailInput',
  props: {
    modelValue: { default: '' }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const email = ref('')
const domain = ref('')

watch(
  () => props.modelValue,
  (value: string) => {
    const index = value.lastIndexOf('@')
    email.value = value.substring(0, index)
    domain.value = value.substring(index)
  },
  { immediate: true },
)

const handleUpdate = () => {
  emit('update:modelValue', `${email.value}${domain.value}`)
}



defineComponent({
  name: 'UiEmailInput',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(UiInput, _mergeProps(_ctx.$attrs, {
      modelValue: email.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
        handleUpdate
      ],
      class: _ctx.$style.input,
      maxlength: "64"
    }), null, 16 /* FULL_PROPS */, ["modelValue", "class"]),
    _createElementVNode("div", {
      class: _normalizeClass(["text-gray-60 text-sm font-normal", _ctx.$style.domain])
    }, _toDisplayString(domain.value), 3 /* TEXT, CLASS */)
  ], 2 /* CLASS */))
}
}

})