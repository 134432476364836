import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { GlobalEvents } from 'vue-global-events'


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalEvents',
  props: {
    noFiltering: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

/**
 * The filtering is used so that different text inputs (e.g. comment editor, inputs in the Filter modal etc)
 * wouldn't trigger a random sort (R) or navigating to the next ticket (vim shortcuts for j, k, arrows etc.)
 */
const filterKeyboardEvents = (event) => {
  if (props.noFiltering) return true

  const INPUT_ELEMENTS = ['INPUT', 'TEXTAREA'] as const
  const targetIsInput = INPUT_ELEMENTS.includes(event.target.tagName)
  const shadowRootActiveElementIsInput = INPUT_ELEMENTS.includes(event.target?.shadowRoot?.activeElement?.tagName)

  const textInput = targetIsInput || shadowRootActiveElementIsInput || event.target.contentEditable === 'true'
  return event.metaKey || event.ctrlKey || !textInput
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(GlobalEvents), _mergeProps(_ctx.$attrs, { filter: filterKeyboardEvents }), null, 16 /* FULL_PROPS */))
}
}

})