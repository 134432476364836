import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { XIcon } from '@klausapp/ui-kit/icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCloseButton',
  props: {
    small: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["bg-gray-10 enabled:hover:bg-gray-20 enabled:hover:text-gray-80 text-gray-70 disabled:bg-gray-10 enabled:active:!bg-active-secondary enabled:active:!text-active-icon flex shrink-0 items-center justify-center mix-blend-multiply transition-colors disabled:cursor-not-allowed disabled:text-gray-50", [_ctx.small ? 'h-6 w-6' : 'h-8 w-8', _ctx.rounded ? 'rounded-full' : 'rounded']])
  }, [
    _createVNode(_unref(XIcon), {
      class: _normalizeClass([_ctx.small ? 'icon-s' : 'icon-m'])
    }, null, 8 /* PROPS */, ["class"])
  ], 2 /* CLASS */))
}
}

})