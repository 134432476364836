import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "rows", "placeholder", "disabled"]


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTextarea',
  props: {
    modelValue: {},
    placeholder: {},
    rows: {},
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const handleInput = (event: Event) => {
  if (event.target instanceof HTMLTextAreaElement) emit('update:modelValue', event.target.value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("textarea", {
    value: _ctx.modelValue,
    rows: _ctx.rows,
    placeholder: _ctx.placeholder,
    disabled: _ctx.disabled,
    class: "border-gray-30 bg-gray-10 text-gray-90 disabled:bg-gray-20 disabled:border-gray-20 disabled:placeholder-gray-60 focus:shadow-input-focus focus:border-active-primary min-w-[216px] rounded border p-2 text-sm placeholder-gray-50 focus:outline-0",
    onInput: handleInput
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})