import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "truncate" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { computed } from 'vue'
import { getColorByThreshold } from '../utils/colors'
import { UiPillColor, UiPillDarkness, UiPillSize } from '../types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPill',
  props: {
    value: { default: 'N/A' },
    color: { default: UiPillColor.Default },
    size: { default: UiPillSize.Medium },
    appendix: { default: '' },
    loading: { type: Boolean, default: false },
    appendixColor: { default: UiPillColor.Green },
    colorDarkness: { default: UiPillDarkness.Default },
    textual: { type: Boolean, default: false },
    threshold: { default: 0 },
    align: { default: 'center' },
    hasDividingBorder: { type: Boolean, default: false },
    removeBlendMode: { type: Boolean, default: false },
    valueMaxWidth: { default: 'max-w-full' }
  },
  setup(__props: any) {

const props = __props

const negativeAppendix = computed(() => props.appendix?.toString().startsWith('-'))
const removeMinus = computed(() => negativeAppendix.value && props.appendix.toString().slice(1))
const positiveAppendix = computed(() => props.appendix?.toString().startsWith('+'))
const removePlus = computed(() => positiveAppendix.value && props.appendix.toString().slice(1))
const thresholdColor = computed(() => {
  return getColorByThreshold(parseInt(props.value.toString(), 10), props.threshold || 0)
})

const colorMap = {
  default: 'text-gray-60 bg-gray-10',
  brand: 'text-pill-brand-color bg-pill-brand-bg',
  blue: 'text-blue-60 bg-blue-10',
  yellow: 'text-yellow-60 bg-yellow-10',
  'gray-30': 'text-gray-80 bg-gray-30',
  'orange-20': 'text-orange-70 bg-orange-20',
  'red-10': 'text-red-60 bg-red-10',
  'red-20': 'text-red-70 bg-red-20',
  'red-30': 'text-red-80 bg-red-30',
  'green-10': 'text-green-60 bg-green-10',
  'green-20': 'text-green-70 bg-green-20',
  'green-30': 'text-green-80 bg-green-30',
  'blue-10': 'text-blue-60 bg-blue-10',
  dispute_NEW: 'text-gray-80 bg-gray-20',
  dispute_ACCEPTED: 'text-green-80 bg-green-20',
  dispute_REJECTED: 'text-red-80 bg-red-20',
  dispute_PARTIALLY_ACCEPTED: 'bg-teal-80 bg-teal-20',
  zblue: 'text-zblue-60 bg-zblue-10',
}

const borderMap = {
  blue: 'border-r-2 border-blue-20',
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.appendix && '-mr-5',
      'whitespace-nowrap font-semibold',
      _ctx.align === 'center' && 'inline-flex justify-center',
      _ctx.align === 'right' && 'flex justify-end',
      _ctx.align === 'left' && 'flex justify-start',
    ])
  }, [
    (_ctx.value || _ctx.value === 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([
        _ctx.color === 'green' || _ctx.color === 'red' ? colorMap[_ctx.color + '-' + _ctx.colorDarkness] : colorMap[_ctx.color],
        _ctx.threshold && colorMap[thresholdColor.value],
        _ctx.valueMaxWidth,
        'z-[1]',
        'inline-flex items-center justify-center rounded-full',
        {
          [_ctx.$style.loading]: _ctx.loading,
          'h-5 min-w-6 px-2.5 text-xs': _ctx.size === 'xs',
          'h-6 min-w-8 px-2 text-xs': _ctx.size === 'sm',
          'h-12 min-w-16 px-4 text-2xl': _ctx.size === 'md',
          'outline outline-white': _ctx.appendix,
          'rounded-r-none pl-2 pr-1.5 outline-none outline-0': _ctx.appendix && _ctx.size === 'xs',
          'rounded-r-none pl-3 pr-2 outline-2': _ctx.appendix && _ctx.size === 'sm',
          'outline-4': _ctx.appendix && _ctx.size === 'md',
          'mix-blend-multiply': !_ctx.appendix && !_ctx.removeBlendMode && (!_ctx.threshold || !thresholdColor.value),
          [borderMap[_ctx.color]]: _ctx.hasDividingBorder && _ctx.appendix,
        },
      ])
        }, [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "icon"),
            _createTextVNode(" " + _toDisplayString(!_ctx.loading ? _ctx.value : ''), 1 /* TEXT */)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (!_ctx.loading && _ctx.appendix)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([
        colorMap[`${_ctx.appendixColor}-${_ctx.colorDarkness}`],
        'relative -left-5 z-0 inline-flex items-center justify-end truncate whitespace-nowrap rounded-e-full text-xs',
        { 'h-5 pl-6 pr-2.5': _ctx.size === 'xs', 'h-6 pl-7 pr-3': _ctx.size === 'sm', 'h-12 pl-8 pr-3': _ctx.size === 'md' },
      ])
        }, [
          (negativeAppendix.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "−"))
            : _createCommentVNode("v-if", true),
          (!negativeAppendix.value && !_ctx.textual)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "+"))
            : _createCommentVNode("v-if", true),
          _createTextVNode(" " + _toDisplayString(negativeAppendix.value ? removeMinus.value : positiveAppendix.value ? removePlus.value : _ctx.appendix), 1 /* TEXT */)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})