import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

import { computed, useSlots } from 'vue'

import UiMenuFilterTrigger from '../UiMenu/triggers/UiMenuFilterTrigger.vue'
import { UiMenuTriggerTheme } from '../UiMenu/types'
import { type DropdownProps, SelectAllModes, type Modes } from './types'
import { ALL_ID } from './constants'
import type useList from './composables/useList'

interface Props extends DropdownProps<T, P, L, M, V, O> {
  expanded: boolean
  totalSelectedCount?: number
  selectedOptions: ReturnType<typeof useList<T, P, L, M, V, O>>['selectedOptions']['value']
  formattedCount: string | number
  excludedValues: ReturnType<typeof useList<T, P, L, M, V, O>>['excludedValues']['value']
  selectedIds: ReturnType<typeof useList<T, P, L, M, V, O>>['selectedIds']['value']
  allOptions: ReturnType<typeof useList<T, P, L, M, V, O>>['allOptions']['value']
  searchQuery: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownTrigger',
  props: {
    expanded: { type: Boolean },
    totalSelectedCount: {},
    selectedOptions: {},
    formattedCount: {},
    excludedValues: {},
    selectedIds: {},
    allOptions: {},
    searchQuery: {},
    mode: {},
    isObject: {},
    propToCheck: {},
    options: {},
    modelValue: {},
    closeOnSelect: { type: Boolean },
    selectAllLabel: {},
    allowSingleDeselect: { type: Boolean },
    allowDeselectAll: { type: Boolean },
    maxHeight: {},
    triggerLabel: {},
    triggerPlaceholder: {},
    label: {},
    searchPlaceholder: {},
    noResultsText: {},
    loadingText: {},
    selectAllMode: {},
    filterResults: { type: Boolean },
    total: {},
    maxWidth: {},
    fullWidth: { type: Boolean },
    isLoading: { type: Boolean },
    reorderAfterSelect: { type: Boolean },
    hasSearch: { type: Boolean },
    itemsLabel: {},
    size: {},
    appendToDocument: { type: Boolean },
    disabled: { type: Boolean },
    excludedOptions: {},
    canRemove: { type: Boolean },
    triggerTheme: {},
    groups: {},
    placement: {},
    display: {},
    helpText: {},
    error: { type: Boolean }
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const slots = useSlots()

const showTriggerContent = computed(() => props.selectAllMode === SelectAllModes.IntermediaryAll || !props.isLoading)

const defaultLabelSlotMap = computed(() => ({
  'trigger-single-selected': props.label ? props.selectedOptions[0]?.[props.label] : '',
  'trigger-multiple-selected': props.formattedCount,
  'trigger-all-selected': props.selectAllLabel,
  'trigger-placeholder': props.triggerPlaceholder,
}))

const placeholderColors = {
  [UiMenuTriggerTheme.WhiteBordered]: 'text-gray-50',
  [UiMenuTriggerTheme.Light]: 'text-gray-50',
}

const isPlaceholder = computed(() => labelSlotName.value === 'trigger-placeholder')

const textColor = computed(() => {
  if (props.triggerLabel || slots['option-icon']) return 'text-gray-80'

  if (isPlaceholder.value && props.triggerTheme && placeholderColors[props.triggerTheme]) {
    return placeholderColors[props.triggerTheme]
  }

  return 'text-gray-90'
})

const labelSlotName = computed<keyof typeof defaultLabelSlotMap.value>(() => {
  if (props.excludedValues.length) return 'trigger-multiple-selected'
  if (
    props.selectedIds[0] === ALL_ID &&
    (props.allOptions.filter((o) => o[props.propToCheck] !== ALL_ID).length || props.searchQuery)
  ) {
    return 'trigger-all-selected'
  }
  if (props.selectedOptions.length === 1) return 'trigger-single-selected'
  if (props.modelValue.length) return 'trigger-multiple-selected'

  return 'trigger-placeholder'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuFilterTrigger, {
    "data-testid": "dropdown-trigger",
    disabled: _ctx.disabled,
    expanded: _ctx.expanded,
    "can-remove": _ctx.canRemove,
    theme: _ctx.triggerTheme,
    "full-width": _ctx.fullWidth,
    error: _ctx.error,
    onRemove: _cache[0] || (_cache[0] = ($event: any) => (emit('remove')))
  }, _createSlots({
    default: _withCtx(() => [
      (showTriggerContent.value)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["truncate", textColor.value])
          }, [
            _renderSlot(_ctx.$slots, labelSlotName.value, { count: _ctx.formattedCount }, () => [
              _createTextVNode(_toDisplayString(defaultLabelSlotMap.value[labelSlotName.value]), 1 /* TEXT */)
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.$slots.icon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon")
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.triggerLabel)
      ? {
          name: "label",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.triggerLabel), 1 /* TEXT */)
          ]),
          key: "1"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "expanded", "can-remove", "theme", "full-width", "error"]))
}
}

})