import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

import { useOnline } from '@vueuse/core'
import { theme } from '@klausapp/ui-kit'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavLogo',
  setup(__props) {

const hasNetworkAccess = useOnline()
const appendTo = document.body

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(["relative flex justify-center py-4", !_unref(hasNetworkAccess) && 'grayscale']),
    "aria-label": _ctx.$t('modules.application.navbar.homepage'),
    to: "/"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).logos.standard)))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "aria-label"])), [
    [_directive_tippy, {
      placement: 'right',
      content: !_unref(hasNetworkAccess) && _ctx.$t('modules.application.offline'),
      interactive: true,
      allowHTML: true,
      appendTo: _unref(appendTo),
    }]
  ])
}
}

})