import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["checked"]

import { computed, defineComponent, useAttrs } from 'vue'
import { omit } from 'lodash-es'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiPillToggle',
  props: {
    modelValue: { type: Boolean, default: false },
    label: { default: '' },
    help: { default: '' },
    small: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit



const attrs = useAttrs()
const inputAttrs = computed(() => omit(attrs, 'class'))

const toggle = (event: Event) => {
  if (!(event.target instanceof HTMLInputElement)) return
  emit('update:modelValue', event.target.checked)
}

defineComponent({
  name: 'UiPillToggle',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["relative text-sm", [
      _ctx.$style.wrapper,
      _ctx.$attrs.disabled && _ctx.$style.disabled,
      (_ctx.help || _ctx.$slots.help) && _ctx.$style.multirow,
      _ctx.small ? _ctx.$style.small : _ctx.$style.large,
      _ctx.$attrs.class,
    ]]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("input", _mergeProps({
      class: [_ctx.$style.input, 'sr-only']
    }, inputAttrs.value, {
      checked: _ctx.modelValue,
      type: "checkbox",
      onInput: toggle
    }), null, 16 /* FULL_PROPS */, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.$style.toggle, _ctx.modelValue && 'bg-form-selected-background after:left-1/2'])
    }, null, 2 /* CLASS */),
    (_ctx.label || _ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.$style.label, "h-max text-sm"])
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.help || _ctx.$slots.help)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["text-gray-60 text-xs", _ctx.$style.help])
        }, [
          _renderSlot(_ctx.$slots, "help", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.help), 1 /* TEXT */)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})