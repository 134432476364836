import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

import UiMenuItem from '../UiMenu/UiMenuItem.vue'

import { Modes } from './types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownItem',
  props: {
    selected: { type: Boolean },
    indeterminate: { type: Boolean },
    mode: {},
    option: {},
    label: {},
    disabled: { type: Boolean },
    separator: { type: Boolean },
    propToCheck: {}
  },
  emits: ["toggle"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const handleCheck = () => handleClick()

const handleSelect = (e?: MouseEvent | KeyboardEvent) => {
  e?.preventDefault()
  handleClick()
}

const isDisabledOption = computed(() => !!('disabled' in props.option && !!props.option.disabled))

const getTooltipContent = (option: T) => ('tooltip' in option ? (option.tooltip as string) : undefined)

const tooltipContent = computed(() => (isDisabledOption.value ? getTooltipContent(props.option) : undefined))

const handleClick = () => {
  if (isDisabledOption.value) return

  emit('toggle', props.option)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuItem, {
    "tooltip-content": tooltipContent.value,
    "data-testid": `dropdown-item-${props.option[props.propToCheck]}`,
    "data-index": props.option[props.propToCheck],
    "auto-height": _ctx.separator,
    disabled: isDisabledOption.value,
    selectable: !_ctx.disabled,
    checkbox: _unref(Modes).Multiple === _ctx.mode && !_ctx.separator,
    selected: _ctx.selected,
    indeterminate: _ctx.indeterminate,
    onCheck: handleCheck,
    onSelect: handleSelect
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "option", {
        option: props.option
      }, () => [
        _createTextVNode(_toDisplayString(props.option[props.label]), 1 /* TEXT */)
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.$slots['option-icon'])
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "option-icon", {
              option: props.option
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["tooltip-content", "data-testid", "data-index", "auto-height", "disabled", "selectable", "checkbox", "selected", "indeterminate"]))
}
}

})