import { createI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import type { Session } from '@/types/session'

import { Locales, ProdLocales, type Locale } from './types'

export const localePersistenceKey = 'klausLocale.v2'

export const FALLBACK_LOCALE = 'en-fallback' as const

export const getSupportedLocale = (locale = '') => {
  if (locale === 'pt-br-old') return Locales.PtBr
  return Object.values(Locales).find((key) => key.toLowerCase() === locale.toLowerCase())
}

const i18n = createI18n({
  legacy: false,
  fallbackLocale: Object.values(Locales).reduce<Record<Locale, (typeof FALLBACK_LOCALE)[]>>(
    (acc, cur) => ({ ...acc, [cur]: [FALLBACK_LOCALE] }),
    {} as Record<Locale, (typeof FALLBACK_LOCALE)[]>,
  ),
  silentFallbackWarn: process.env.NODE_ENV !== 'production',
  warnHtmlMessage: false,
})

export const i18nInst = i18n

const setI18nLanguage = (lang: Locale | typeof FALLBACK_LOCALE) => {
  i18n.global.locale.value = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  sessionStorage.setItem(localePersistenceKey, lang)
  return lang
}

export const getLocale = () => i18n.global.locale.value

/**
 * Fetch the session locale
 */
export const getSessionLocale = (session?: Session) => {
  if (session?.isAuthenticated) {
    // Get the language from user session; fresh accounts lack user profile in session
    return getSupportedLocale(session.user?.locale) || Locales.EnUs
  } else {
    // Get the language from query param or session storage
    const query = new URLSearchParams(location.search)
    const langQueryParam = query.get('lang') || ''
    const availableLanguages = Object.values(ProdLocales) as string[]
    const browserLocale = availableLanguages.find((l) => l.toLowerCase() === window.navigator.language.toLowerCase())
    const localeQueryParam = getSupportedLocale(langQueryParam)
    return localeQueryParam || sessionStorage.getItem(localePersistenceKey) || browserLocale || Locales.EnUs
  }
}

export const loadLanguageAsync = async (lang: Locale | string) => {
  const locale = getSupportedLocale(lang) || Locales.EnUs

  const dayJsLocale = await initDayJsLocale(locale)
  dayjs.locale(dayJsLocale)
  dayjs.extend(localizedFormat)

  const messagesFallback = await import(/* webpackChunkName: "language/en-fallback" */ './locale/en.generated.json')

  i18n.global.setLocaleMessage(FALLBACK_LOCALE, messagesFallback.default)

  if ('Cypress' in window) return setI18nLanguage(FALLBACK_LOCALE)

  if (locale === Locales.Latin) {
    const messages = await import(/* webpackChunkName: "language/latin" */ './locale/latin.generated.json')
    i18n.global.setLocaleMessage(locale, messages.default)
  } else {
    const messages = await import(/* webpackChunkName: "language/[request]" */ `./locale/rosetta/${locale}.json`)
    i18n.global.setLocaleMessage(locale, messages.default)
  }

  return setI18nLanguage(locale)
}

type LocaleValue = (typeof Locales)[keyof typeof Locales]
const dayjsLocales: Record<LocaleValue, () => Promise<any>> = {
  [Locales.EnUs]: () => import('dayjs/locale/en'),
  [Locales.EnGb]: () => import('dayjs/locale/en-gb'),
  [Locales.Latin]: () => import('dayjs/locale/en'),
  [Locales.EnPseudo]: () => import('dayjs/locale/en'),
  [Locales.PtBr]: () => import('dayjs/locale/pt-br'),
  [Locales.De]: () => import('dayjs/locale/de'),
  [Locales.Es]: () => import('dayjs/locale/es'),
  [Locales.Fr]: () => import('dayjs/locale/fr'),
  [Locales.FrCa]: () => import('dayjs/locale/fr-ca'),
  [Locales.Ja]: () => import('dayjs/locale/ja'),
  [Locales.ZhCn]: () => import('dayjs/locale/zh-cn'),
  [Locales.ZhTw]: () => import('dayjs/locale/zh-tw'),
  [Locales.Bg]: () => import('dayjs/locale/bg'),
  [Locales.Cs]: () => import('dayjs/locale/cs'),
  [Locales.Da]: () => import('dayjs/locale/da'),
  [Locales.Nl]: () => import('dayjs/locale/nl'),
  [Locales.Fi]: () => import('dayjs/locale/fi'),
  [Locales.El]: () => import('dayjs/locale/el'),
  [Locales.Hi]: () => import('dayjs/locale/hi'),
  [Locales.Hu]: () => import('dayjs/locale/hu'),
  [Locales.Id]: () => import('dayjs/locale/id'),
  [Locales.It]: () => import('dayjs/locale/it'),
  [Locales.Ko]: () => import('dayjs/locale/ko'),
  [Locales.No]: () => import('dayjs/locale/nb'),
  [Locales.Pl]: () => import('dayjs/locale/pl'),
  [Locales.Ro]: () => import('dayjs/locale/ro'),
  [Locales.Ru]: () => import('dayjs/locale/ru'),
  [Locales.Sk]: () => import('dayjs/locale/sk'),
  [Locales.Sv]: () => import('dayjs/locale/sv'),
  [Locales.Th]: () => import('dayjs/locale/th'),
  [Locales.Tr]: () => import('dayjs/locale/tr'),
  [Locales.Uk]: () => import('dayjs/locale/uk'),
  [Locales.Vi]: () => import('dayjs/locale/vi'),
}

async function initDayJsLocale(locale: Locale) {
  const isSupportedLocale = locale in dayjsLocales
  const dayJsLocale = isSupportedLocale ? locale : Locales.EnUs

  await dayjsLocales[dayJsLocale]()

  return localeWithoutSuffix(dayJsLocale)
}

function localeWithoutSuffix(locale: Locale) {
  return `${locale.split('-')[0].toLowerCase()}`
}

export default i18n.global
