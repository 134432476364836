import { v4 as uuid } from 'uuid'
import { ref, computed, type Ref } from 'vue'

import type { PropsWithDefaults, Modes } from '../../types'

import { sortOptions, getId } from '../utils'

export default function useSortedList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>) {
  const initialSelected = ref([]) as Ref<T[P][]>

  const reorderList = () => {
    initialSelected.value = props.modelValue.map((v: T | T[P]) => getId(v, props))
  }

  const sortList = (options: T[]) =>
    options.toSorted((a, b) => sortOptions(a, b, initialSelected.value, props.propToCheck))

  const sortedOptions = computed(() => {
    if (!props.options) return null

    return sortList([...props.options])
  })

  const sortedGroups = computed(() => {
    if (!props.groups) return null

    return props.groups.map((group) => {
      const sortedOptions = group.options ? sortList(group.options) : null

      return {
        ...group,
        id: group.id || uuid(),
        options: sortedOptions,
        total: group.total || sortedOptions?.length || 0,
      }
    })
  })

  const firstOption = computed(() => {
    if (sortedOptions.value) return sortedOptions.value[0]

    if (sortedGroups.value) return sortedGroups.value[0].options[0]

    return null
  })

  return {
    reorderList,
    sortedOptions,
    sortedGroups,
    firstOption,
  }
}
