import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-1" }

import { computed, ref } from 'vue'
import { XIcon } from '@klausapp/ui-kit/icons'
import { type UiTagThemeType, UiTagTheme } from '../types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTag',
  props: {
    theme: { default: UiTagTheme.Default },
    dismissible: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    capitalized: { type: Boolean, default: true },
    tag: { default: 'span' },
    small: { type: Boolean }
  },
  emits: ["dismiss"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const TAG_COLORS: Record<UiTagThemeType, string> = {
  default: 'text-gray-80 bg-gray-20',
  brand: 'text-white bg-tag-brand-bg',
  'brand-light': 'text-tag-light-color bg-tag-light-bg',
  blue: 'text-white bg-blue-50',
  green: 'text-white bg-green-50',
  'green-light': 'text-green-70 bg-green-20',
  yellow: 'text-white bg-yellow-50',
  'yellow-light': 'text-yellow-80 bg-yellow-20',
  red: 'text-white bg-red-50',
  'red-light': 'text-red-70 bg-red-20',
  white: 'text-gray-80 bg-white',
  orange: 'text-orange-50 bg-orange-20',
  'orange-dark': 'text-orange-80 bg-orange-20',
} as const

const TAG_INTERACTIVE_COLORS = {
  orange: 'hover:text-orange-60 hover:bg-orange-30',
} as const

const themeColorClass = computed(() => TAG_COLORS[props.theme])
const themeInteractiveColorClass = computed(() => TAG_INTERACTIVE_COLORS[props.theme])

const pxClass = computed(() => {
  if (props.small) return 'px-1'

  return props.rounded ? 'px-2' : 'px-1.5'
})

const dismissed = ref(false)

const dismiss = () => {
  dismissed.value = true
  emit('dismiss')
}

return (_ctx: any,_cache: any) => {
  return (!dismissed.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
        key: 0,
        class: _normalizeClass([
      'inline-flex items-center justify-center whitespace-nowrap rounded border-none py-px text-xs font-medium',
      themeColorClass.value,
      _ctx.tag === 'button' && themeInteractiveColorClass.value,
      { 'shadow-sm': _ctx.theme === 'white' },
      _ctx.rounded && 'rounded-full',
      _ctx.small ? 'h-4' : 'h-5',
      pxClass.value,
    ]),
        type: _ctx.tag === 'button' ? 'button' : undefined
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass({ 'lowercase first-letter:uppercase': _ctx.capitalized })
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2 /* CLASS */),
            _renderSlot(_ctx.$slots, "icon")
          ]),
          (_ctx.dismissible)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "ml-1.5 flex",
                type: "button",
                onClick: dismiss
              }, [
                _createVNode(_unref(XIcon), { class: "icon-s text-inherit opacity-60 transition-opacity duration-300 hover:opacity-100" })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["class", "type"]))
    : _createCommentVNode("v-if", true)
}
}

})