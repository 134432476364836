import { v4 as uuid } from 'uuid'
import { uploadToPrivateSignedUrl, type SignedUrlResponse } from './image'

export interface UnsavedImageMeta {
  unsavedImageKey: string
  src: string
}

export const unsavedImages: Record<
  string,
  { file: File; signedUrlResponse?: SignedUrlResponse; uploading?: boolean; uploaded?: boolean }
> = {}

export const uploadUnsavedImages = (text = '') => {
  const promises = Object.entries(unsavedImages)
    .filter(([_key, img]) => img.signedUrlResponse && !img.uploaded)
    .filter(([_key, img]) => text.includes(img.signedUrlResponse.url))
    .map(([key, img]) =>
      uploadToPrivateSignedUrl(img.file, img.signedUrlResponse).then(() => (unsavedImages[key].uploaded = true)),
    )

  return Promise.all(promises)
}

export const readFileAsObjectURL = (file: File): UnsavedImageMeta => {
  const unsavedImageKey = uuid()
  const src = URL.createObjectURL(file)
  unsavedImages[unsavedImageKey] = { file }

  return { unsavedImageKey, src }
}
