export enum UiBadgeTheme {
  Default = 'default',
  Dark = 'dark',
  Highlighted = 'highlighted',
  Yellow = 'yellow',
  Pink = 'pink',
}

export const UiTagTheme = {
  Default: 'default',
  BrandLight: 'brand-light',
  Brand: 'brand',
  Blue: 'blue',
  Green: 'green',
  GreenLight: 'green-light',
  Yellow: 'yellow',
  YellowLight: 'yellow-light',
  Red: 'red',
  RedLight: 'red-light',
  White: 'white',
  Orange: 'orange',
  OrangeDark: 'orange-dark',
} as const

export type UiTagThemeType = (typeof UiTagTheme)[keyof typeof UiTagTheme]

export enum UiPillColor {
  Default = 'default',
  Brand = 'brand',
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Yellow = 'yellow',
  Zblue = 'zblue',
}

export enum UiPillSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
}

export enum UiPillDarkness {
  Default = 10,
  Darker = 20,
  Dark = 30,
}
