import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  width: "16",
  height: "16",
  fill: "#fff"
}
const _hoisted_2 = {
  key: 1,
  "data-testid": "checkbox-indeterminate",
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none"
}
const _hoisted_3 = ["name", "required", "disabled", "checked", "tabindex"]

import { computed, useAttrs } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCheckbox',
  props: {
    label: { default: '' },
    help: { default: '' },
    name: { default: '' },
    required: { type: Boolean },
    disabled: { type: Boolean },
    multiline: { type: Boolean },
    checked: { type: Boolean, default: false },
    indeterminate: { type: Boolean },
    noTransition: { type: Boolean },
    light: { type: Boolean },
    preserveCheckIcon: { type: Boolean },
    color: { default: '' },
    tabindex: { default: '' },
    error: { type: Boolean, default: false }
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const listeners = useAttrs()

const inputStyle = {
  '--background-color': props.color || 'var(--form-selected-background)',
}

const getListeners = computed(() => {
  const { change, class: clazz, ...otherListeners } = listeners
  return otherListeners
})

const handleChange = (event: Event) => {
  if (!(event.target instanceof HTMLInputElement)) return

  emit('change', event.target.checked)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _mergeProps({
    class: ["text-sm", [
      _ctx.$style.container,
      {
        [_ctx.$style.disabled]: _ctx.disabled,
        [_ctx.$style.noTransition]: _ctx.noTransition,
        [_ctx.$style.multiline]: _ctx.help || _ctx.$slots.secondary,
        [_ctx.$style.alignTop]: _ctx.multiline,
      },
    ]]
  }, _toHandlers(getListeners.value, true)), [
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.$style.checkbox, { [_ctx.$style.checked]: _ctx.checked }])
    }, [
      (_ctx.checked || _ctx.preserveCheckIcon)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("path", { d: "M10.9 4.8a1 1 0 011.4 0l.1.1c.3.4.3 1 0 1.4L7.8 11a1 1 0 01-1.5 0L3.7 8.2a1 1 0 111.5-1.4l1.9 2 3.8-4z" }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      (_ctx.indeterminate)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("rect", {
              width: "16",
              height: "16",
              rx: "3",
              class: "fill-form-selected-background"
            }, null, -1 /* HOISTED */),
            _createElementVNode("rect", {
              x: "4",
              y: "7",
              width: "8",
              height: "2",
              rx: "1",
              fill: "#fff"
            }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      _createElementVNode("input", {
        type: "checkbox",
        name: _ctx.name,
        required: _ctx.required,
        disabled: _ctx.disabled,
        checked: _ctx.checked,
        style: inputStyle,
        class: _normalizeClass([_ctx.$style.input, _ctx.error && _ctx.$style.error]),
        tabindex: _ctx.tabindex,
        onChange: handleChange
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)
    ], 2 /* CLASS */),
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.light ? _ctx.$style.labelLight : _ctx.$style.label, { [_ctx.$style.multilineLabel]: _ctx.multiline }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
      ])
    ], 2 /* CLASS */),
    (_ctx.help || _ctx.$slots.secondary)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["text-gray-60 text-xs", _ctx.$style.help])
        }, [
          _renderSlot(_ctx.$slots, "secondary", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.help), 1 /* TEXT */)
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 16 /* FULL_PROPS */))
}
}

})