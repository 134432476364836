import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTab',
  props: {
    active: { type: Boolean },
    count: { default: null }
  },
  emits: ["click"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["hover:text-active-primary flex h-8 items-center justify-center gap-1 rounded-xl px-3 text-sm font-medium leading-none", [
      _ctx.active && 'bg-active-secondary text-active-primary cursor-default',
      !_ctx.active && 'bg-gray-10 text-gray-60 hover:bg-active-secondary-hover',
    ]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _renderSlot(_ctx.$slots, "icon"),
    (typeof _ctx.count === 'number')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["flex h-4 min-w-4 items-center justify-center rounded-md px-1 text-xs leading-none", [_ctx.active && 'bg-active-secondary mix-blend-multiply', !_ctx.active && 'bg-gray-20']])
        }, _toDisplayString(_ctx.count), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})