import i18n from '@/i18n'
import emoji1 from '@/assets/scales/emoji-1@2x.png'
import emoji2 from '@/assets/scales/emoji-2@2x.png'
import emoji3 from '@/assets/scales/emoji-3@2x.png'
import emoji4 from '@/assets/scales/emoji-4@2x.png'
import emoji5 from '@/assets/scales/emoji-5@2x.png'
import emojiThumbsUp from '@/assets/scales/emoji-thumbs-up@2x.png'
import emojiThumbsDown from '@/assets/scales/emoji-thumbs-down@2x.png'
import { AutoQaCategoryType } from '@/types/category'

export interface Scale {
  id: number
  label: number
  emoji: string
  emojiLabel: string
}

export enum DisplayType {
  Emotes = 'EMOTES',
  Numbers = 'NUMBERS',
}

export type ScaleType = 'SCALE_2' | 'SCALE_3' | 'SCALE_4' | 'SCALE_5'

export const SCALE_OPTIONS: { label: string; value: ScaleType }[] = [
  {
    label: 'universal.scale_labels.scale_2',
    value: 'SCALE_2',
  },
  {
    label: 'universal.scale_labels.scale_3',
    value: 'SCALE_3',
  },
  {
    label: 'universal.scale_labels.scale_4',
    value: 'SCALE_4',
  },
  {
    label: 'universal.scale_labels.scale_5',
    value: 'SCALE_5',
  },
]

export const SCALE_VALUES: Record<ScaleType, Scale[]> = {
  SCALE_2: [
    {
      id: 0,
      label: 1,
      emoji: emojiThumbsDown,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_2.0'),
    },
    {
      id: 1,
      label: 2,
      emoji: emojiThumbsUp,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_2.1'),
    },
  ],
  SCALE_3: [
    {
      id: 0,
      label: 1,
      emoji: emoji1,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_3.0'),
    },
    {
      id: 1,
      label: 2,
      emoji: emoji3,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_3.1'),
    },
    {
      id: 2,
      label: 3,
      emoji: emoji5,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_3.2'),
    },
  ],
  SCALE_4: [
    {
      id: 0,
      label: 1,
      emoji: emoji1,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_4.0'),
    },
    {
      id: 1,
      label: 2,
      emoji: emoji2,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_4.1'),
    },
    {
      id: 2,
      label: 3,
      emoji: emoji4,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_4.2'),
    },
    {
      id: 3,
      label: 4,
      emoji: emoji5,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_4.3'),
    },
  ],
  SCALE_5: [
    {
      id: 0,
      label: 1,
      emoji: emoji1,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_5.0'),
    },
    {
      id: 1,
      label: 2,
      emoji: emoji2,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_5.1'),
    },
    {
      id: 2,
      label: 3,
      emoji: emoji3,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_5.2'),
    },
    {
      id: 3,
      label: 4,
      emoji: emoji4,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_5.3'),
    },
    {
      id: 4,
      label: 5,
      emoji: emoji5,
      emojiLabel: i18n.t('universal.scale_emoji_labels.scale_5.4'),
    },
  ],
}

export const AutoQaScaleMap: Partial<Record<AutoQaCategoryType, ScaleType>> = {
  [AutoQaCategoryType.Readability]: 'SCALE_3',
  [AutoQaCategoryType.Greeting]: 'SCALE_2',
  [AutoQaCategoryType.Closing]: 'SCALE_2',
  [AutoQaCategoryType.Empathy]: 'SCALE_2',
  [AutoQaCategoryType.Comprehension]: 'SCALE_2',
  [AutoQaCategoryType.Solution]: 'SCALE_2',
  [AutoQaCategoryType.Custom]: 'SCALE_2',
  [AutoQaCategoryType.Prompt]: 'SCALE_2',
}
