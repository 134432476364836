import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { key: 1 }

import { defineComponent, computed } from 'vue'
import { capitalize } from 'lodash-es'

import i18n from '@/i18n'
import config from '@/config'


export default /*@__PURE__*/_defineComponent({
  __name: 'SupportDeskIcon',
  props: {
    source: {},
    noTooltip: { type: Boolean },
    grayscale: { type: Boolean },
    small: { type: Boolean },
    large: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

defineComponent({ name: 'SupportDeskIcon' })

const images = {
  aircall: 'aircall.svg',
  aircallMono: 'aircall_mono.svg',
  browserextension: 'browser_extension_mono.svg',
  browserextensionMono: 'browser_extension_mono.svg',
  cordlessmanualimport: 'cordless.svg',
  cordlessmanualimportMono: 'cordless_mono.svg',
  dixa: 'dixa.svg',
  dixaMono: 'dixa_mono.svg',
  drift: 'drift.svg',
  driftMono: 'drift_mono.svg',
  freshchat: 'freshchat.svg',
  freshchatMono: 'freshchat_mono.svg',
  front: 'front.svg',
  frontMono: 'front_mono.svg',
  freshdesk: 'freshdesk.svg',
  freshdeskMono: 'freshdesk_mono.svg',
  genesys: 'genesys.svg',
  genesysMono: 'genesys_mono.svg',
  gorgias: 'gorgias.svg',
  gorgiasMono: 'gorgias_mono.svg',
  helpscout: 'helpscout.svg',
  helpscoutMono: 'helpscout_mono.svg',
  helpshift: 'helpshift.svg',
  helpshiftMono: 'helpshift_mono.svg',
  intercom: 'intercom.svg',
  intercomMono: 'intercom_mono.svg',
  kustomer: 'kustomer.svg',
  kustomerMono: 'kustomer_mono.svg',
  liveagent: 'liveagent.svg',
  liveagentMono: 'liveagent_mono.svg',
  livechat: 'livechat.svg',
  livechatMono: 'livechat_mono.svg',
  manualimport: 'manual_import_mono.svg',
  manualimportMono: 'manual_import_mono.svg',
  salesforce: 'salesforce.svg',
  salesforceMono: 'salesforce_mono.svg',
  talkdesk: 'talkdesk.svg',
  talkdeskMono: 'talkdesk_mono.svg',
  wix: 'wix.svg',
  wixMono: 'wix_mono.svg',
  zendesk: 'zendesk.svg',
  zendeskMono: 'zendesk_mono.svg',
  zendeskchat: 'zendesk_chat.svg',
  zendeskchatMono: 'zendesk_chat_mono.svg',
}

const sourceImage = computed(() => {
  let key = props.source?.toLowerCase().replace(/_/g, '')

  if (props.grayscale) key += 'Mono'

  return images[key] || null
})

const iconPath = computed(() => `${config.baseUrl}/assets/img/helpdesk/${sourceImage.value}`)

const sourceTitle = computed(() => {
  let formattedSource = capitalize(props.source?.replace(/_/g, ' '))

  if (['MANUAL_IMPORT', 'CORDLESS_MANUAL_IMPORT'].includes(props.source))
    formattedSource = i18n.t('components.helpdesk_icon.manual_import')

  return i18n.t('components.helpdesk_icon.generic_source', [formattedSource])
})

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    title: _ctx.noTooltip ? '' : sourceTitle.value,
    class: "support-desk-icon"
  }, [
    (sourceImage.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: iconPath.value,
          alt: sourceTitle.value,
          class: _normalizeClass(_ctx.large ? 'icon-l' : _ctx.small ? 'icon-s' : 'icon-m')
        }, null, 10 /* CLASS, PROPS */, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "🔌"))
  ], 8 /* PROPS */, _hoisted_1)), [
    [_directive_tippy]
  ])
}
}

})