import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTable',
  props: {
    fixed: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["w-full border-separate border-spacing-0 text-xs", _ctx.fixed ? 'table-fixed' : 'table-auto'])
  }, [
    _createElementVNode("thead", {
      class: _normalizeClass(["empty:hidden", _ctx.$style.thead])
    }, [
      _renderSlot(_ctx.$slots, "head")
    ], 2 /* CLASS */),
    _createElementVNode("tbody", {
      class: _normalizeClass(_ctx.$style.tbody)
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}
}

})