import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xs font-semibold text-white" }

export type KeySize = 'large' | 'small'
export type KeyTheme = 'dark' | 'light'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiKeyboardKey',
  props: {
    keyboardKey: {},
    size: { default: 'large' },
    theme: { default: 'dark' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-fit items-center justify-center rounded-sm", [
      _ctx.theme === 'dark' ? 'bg-gray-90' : 'bg-gray-70',
      _ctx.size === 'large' ? 'h-8 min-w-8 px-2' : 'h-5 min-w-5 px-1',
    ]])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.keyboardKey), 1 /* TEXT */)
  ], 2 /* CLASS */))
}
}

})