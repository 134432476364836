import i18n, { getLocale } from '@/i18n'
import { FilterTimeStepKey } from '../types'

const getValueKey = (value: number) => {
  const pr = new Intl.PluralRules(getLocale(), {
    type: 'ordinal',
  })
  return pr.select(value)
}

export function getLastString(value: number, unit: string = FilterTimeStepKey.Day): string {
  const valueKey = getValueKey(value)
  return i18n.t(`dashboard.looker.filters.time_period_picker.last.${unit}.${valueKey}`, { value })
}

export function getLastOptionString(value: number): string {
  const valueKey = getValueKey(value)
  return i18n.t(`dashboard.time_periods.last.${valueKey}`, value)
}
