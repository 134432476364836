import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-1" }

import UiKeyboardKey, { type KeySize, type KeyTheme } from './UiKeyboardKey.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiKeyCombo',
  props: {
    combo: {},
    alternative: { default: undefined },
    size: { default: 'large' },
    theme: { default: undefined }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combo, (key) => {
      return (_openBlock(), _createBlock(UiKeyboardKey, {
        key: key,
        "keyboard-key": key,
        theme: _ctx.theme,
        size: _ctx.size
      }, null, 8 /* PROPS */, ["keyboard-key", "theme", "size"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.alternative)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("span", {
            class: _normalizeClass(["font-medium text-gray-50", { 'mx-1': _ctx.size === 'large' }])
          }, "/", 2 /* CLASS */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alternative, (key) => {
            return (_openBlock(), _createBlock(UiKeyboardKey, {
              key: key,
              "keyboard-key": key,
              theme: _ctx.theme,
              size: _ctx.size
            }, null, 8 /* PROPS */, ["keyboard-key", "theme", "size"]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})