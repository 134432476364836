import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiButton',
  props: {
    type: { default: 'button' },
    small: { type: Boolean },
    xsmall: { type: Boolean },
    secondary: { type: Boolean },
    light: { type: Boolean },
    borderless: { type: Boolean },
    active: { type: Boolean },
    href: { default: '' }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props

const innerRef = ref<HTMLButtonElement | HTMLAnchorElement | null>(null)

const tag = computed(() => (props.href ? 'a' : 'button'))

const focusInner = () => {
  innerRef.value?.focus()
}

__expose({
  focus: focusInner,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(tag.value), {
    ref_key: "innerRef",
    ref: innerRef,
    type: !_ctx.href ? _ctx.type : undefined,
    href: _ctx.href,
    class: _normalizeClass([
      'cursor-pointer',
      _ctx.$style.btn,
      _ctx.small ? _ctx.$style.small : _ctx.$style.large,
      _ctx.xsmall ? _ctx.$style.xsmall : _ctx.$style.large,
      _ctx.light && _ctx.$style.light,
      _ctx.borderless && _ctx.$style.borderless,
      _ctx.active && _ctx.$style.active,
      _ctx.secondary && !_ctx.light && _ctx.$style.secondary,
      !_ctx.secondary && !_ctx.light && _ctx.$style.primary,
    ])
  }, {
    default: _withCtx(() => [
      _createCommentVNode(" @slot content of the button "),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["type", "href", "class"]))
}
}

})