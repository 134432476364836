import { uniqBy } from 'lodash-es'
import type {
  AdvancedScorecard_WorkspaceScorecard as WorkspaceScorecard,
  AdvancedScorecard_ScorecardRatingCategory as AdvancedCategory,
} from '@zendesk/zqa-services/scorecards'
import { type Category, type CategoryGroup } from '@/modules/shared/Review/utils'
import { type ScaleType } from '@/data/scales'
import { type AutoQaCategoryType } from '@/types/category'

export const mapCategoriesById = (categories: Category[]): Record<number, Category> => {
  return categories.reduce<Record<number, Category>>((mappedCategories, cat) => {
    mappedCategories[cat.categoryId] = cat
    return mappedCategories
  }, {})
}

export const getSortedGroupsFromCategories = (categoryOptions: Record<string, Category>) => {
  return uniqBy(Object.values(categoryOptions), 'groupId')
    .map((cat) => ({
      id: cat.groupId,
      name: cat.groupName,
      position: cat.groupPosition,
      categories: Object.values(categoryOptions)
        .filter((c) => c.groupId === cat.groupId)
        .sort((a, b) => a.position - b.position),
    }))
    .filter((g): g is CategoryGroup => !!g.id)
    .sort((a, b) => a.position - b.position)
}

interface ExtendedAdvancedCategory extends AdvancedCategory {
  groupId?: string
  groupName?: string
  groupPosition: number
  advancedRootCauses?: AdvancedCategory['rootCauses']
}

const getFlatScorecardCategories = (scorecard: WorkspaceScorecard): ExtendedAdvancedCategory[] => {
  if (!scorecard) return []

  return [
    ...(scorecard.categories.map((cat) => ({
      ...cat,
      groupId: undefined,
      groupName: undefined,
      groupPosition: cat.position,
      advancedRootCauses: cat.rootCauses,
    })) || []),
    ...(
      scorecard.groups.map((g) =>
        g.categories.map((cat) => ({
          ...cat,
          groupId: g.id,
          groupName: g.name,
          groupPosition: g.position,
          advancedRootCauses: cat.rootCauses,
        })),
      ) ?? []
    ).flat(),
  ]
}

const mapCategory = (cat: ExtendedAdvancedCategory, scorecard: WorkspaceScorecard): Category => ({
  autoQaApplicabilities: cat.autoQaApplicabilities,
  categoryId: Number(cat.ratingCategoryId),
  categoryName: cat.name,
  categoryDescription: cat.description,
  categoryRatings: cat.ratings,
  groupId: cat.groupId,
  groupName: cat.groupName,
  groupPosition: cat.groupPosition,
  scale: cat.scale as ScaleType,
  requireReason: true, // TODO: Reasons are rating specific now
  multipleRequireReasons: cat.rootCauses?.multipleRequireReasons,
  requireReasonVisibility: undefined, // TODO: Reasons are rating specific now
  freeTextAllowed: cat.rootCauses?.freeTextAllowed,
  weight: cat.weight,
  critical: cat.critical,
  groupCritical: cat.groupCritical,
  reviewCritical: cat.reviewCritical,
  position: cat.position,
  isArchived: false, // TODO: cannot be archived as categories are read from scorecards
  scorecards: [{ id: scorecard.id, tag: scorecard.name, ratingCategoryIds: null }],
  rootCauses: null,
  advancedRootCauses: cat.advancedRootCauses,
  autoQaCategory: cat.autoQaCategory as AutoQaCategoryType,
  autoQaCustomCategoryTemplateId: cat.autoQaCustomCategoryTemplateId,
  skippable: cat.skippable,
  isAdvancedScorecardCategory: true,
})

export const getCategoriesFromScorecard = (scorecard: WorkspaceScorecard): Category[] => {
  const categories = getFlatScorecardCategories(scorecard)

  return categories.map((cat) => mapCategory(cat, scorecard))
}
