import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { session } from '@/composables/useSession'
import { useEvent } from '@/composables/useEvent'
import useNavbarRoutes from '@/composables/useNavbarRoutes'
import { coachingSessionsCount, getCoachingSessionsCount, getPinsCount, pinsCount } from './useCoachingCounts'
import NavbarLink from './NavbarLink.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarCoachingIcon',
  setup(__props) {

const route = useRoute()
const linkActive = computed(() => ['quizzes', 'pins', 'coaching'].some((r) => route.name?.toString().startsWith(r)))
const { navbarRoutesMap } = useNavbarRoutes()

getPinsCount()
getCoachingSessionsCount()

// Timeout to account for read-replica delay
useEvent('refresh-pins-count', () => setTimeout(getPinsCount, 1000))
useEvent('refresh-coaching-sessions-count', () => setTimeout(getCoachingSessionsCount, 1000))

const notificationsCount = computed(() => pinsCount.value + coachingSessionsCount.value)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NavbarLink, {
    to: { name: _unref(session).features.coaching ? 'coaching.sessions' : _unref(session).features.quizzes ? 'quizzes' : 'pins' },
    "link-active": linkActive.value,
    class: _normalizeClass([_ctx.$style.wrapper, notificationsCount.value && _ctx.$style.notificationBubble]),
    "data-highlight-count": (notificationsCount.value > 99 ? '99+' : notificationsCount.value) || null,
    "data-testid": "navbar-coaching",
    title: _ctx.$t(_unref(navbarRoutesMap)['coaching.sessions'].label)
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap)['coaching.sessions'].icon)))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to", "link-active", "class", "data-highlight-count", "title"]))
}
}

})