import { zendeskApi } from '@/api'
import type { ZendeskProductResponse } from './types'

const ZD_PRODUCT_KEY_MAP = {
  guide: 'knowledge_base',
  talk: 'voice',
  explore: 'analytics',
  gather: 'community',
  sell: 'sales',
}

export const getZendeskProducts = async (): Promise<ZendeskProductResponse> => {
  const response = await zendeskApi
    .get(`v2/products.json?host=quality_assurance&trayversion=24.0.3`)
    .json<ZendeskProductResponse>()

  // This mapping ideally should be done on the backend
  const products = response.products?.map((product) => {
    if (Object.hasOwn(ZD_PRODUCT_KEY_MAP, product.key)) {
      return { key: ZD_PRODUCT_KEY_MAP[product.key] }
    }
    return product
  })

  return { ...response, products }
}
