import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

import { defineComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDotLoader',
  props: {
    small: { type: Boolean }
  },
  setup(__props: any) {



defineComponent({ name: 'UiDotLoader' })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center", [_ctx.small && 'h-4 w-4 gap-[0.167rem]', !_ctx.small && 'h-6 w-6 gap-1']])
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: _normalizeClass([_ctx.$style.dot, _ctx.small && 'h-0.5 w-0.5', !_ctx.small && 'h-1 w-1']),
        style: _normalizeStyle({ '--delay': `${i * 150}ms` })
      }, null, 6 /* CLASS, STYLE */)
    }), 64 /* STABLE_FRAGMENT */))
  ], 2 /* CLASS */))
}
}

})