import { api } from '@/api'
import type { CalibrationSession } from '@/api/calibration'
import type { Feedback } from '@/modules/shared/TicketContent/types/feedback'
import type { TicketListItemExt } from '@/modules/conversations/types'
import { uploadUnsavedImages } from '@/modules/shared/CommentEditor/plugins/useUnsavedImages'
import { getRequestHeaders } from './index'

interface ConversationsParams {
  sessionId: string
  offset?: number
}

interface ConversationsResponse {
  tickets: TicketListItemExt[]
}

interface CalibrationFeedbackResponse {
  feedback: Feedback[]
}

export interface CalibrationReview {
  conversationExternalId: string
  ratings: {
    categoryId: number
    rating: number
    cause: string[]
  }[]
  comment: string
  revieweeId?: number
  scorecardId?: string
}

export interface CalibrationSessionsListResponse {
  belongsToSessions: CalibrationSession[]
  canBeAddedToSessions: CalibrationSession[]
}

export let getCalibrationConversationsController: AbortController

export const getCalibrationConversations = (params: ConversationsParams) => {
  const query = new URLSearchParams()
  query.append('sessionId', params.sessionId.toString())
  query.append('offset', params.offset?.toString() || '0')
  query.append('sort', '-createdAt')
  query.append('limit', '100')

  getCalibrationConversationsController = new AbortController()
  const { signal } = getCalibrationConversationsController

  return api
    .get(`calibration/${params.sessionId}/conversations?${query}`, { headers: getRequestHeaders(), signal })
    .json<ConversationsResponse>()
}

export const getCalibrationFeedback = (conversationId: string, connectionId: number, sessionId: string) => {
  const query = new URLSearchParams()
  query.append('conversationExternalId', conversationId)

  return api
    .get(`calibration/${sessionId}/connection/${connectionId}/reviews?${query}`, {
      headers: getRequestHeaders(),
    })
    .json<CalibrationFeedbackResponse>()
}

export const postCalibrationReview = async (
  connectionId: number,
  sessionId: string,
  data: CalibrationReview,
): Promise<CalibrationFeedbackResponse> => {
  const response = await api
    .post(`calibration/${sessionId}/connection/${connectionId}/reviews`, {
      json: data,
      headers: getRequestHeaders(),
    })
    .json<CalibrationFeedbackResponse>()

  await uploadUnsavedImages(data.comment)

  return response
}

export const updateCalibrationReview = async (
  connectionId: number,
  sessionId: string,
  calibrationReviewId: string,
  data: CalibrationReview,
): Promise<CalibrationFeedbackResponse> => {
  const response = await api
    .put(`calibration/${sessionId}/connection/${connectionId}/reviews/${calibrationReviewId}`, {
      json: data,
      headers: getRequestHeaders(),
    })
    .json<CalibrationFeedbackResponse>()

  await uploadUnsavedImages(data.comment)

  return response
}

export const deleteCalibrationReview = (
  conversationId: string,
  connectionId: number,
  sessionId: string,
  calibrationReviewId: string,
): Promise<CalibrationFeedbackResponse> => {
  const query = new URLSearchParams()
  query.append('conversationExternalId', conversationId)

  return api
    .delete(`calibration/${sessionId}/connection/${connectionId}/reviews/${calibrationReviewId}?${query}`, {
      headers: getRequestHeaders(),
    })
    .json()
}

export const addConversationToSession = (conversationId: string, connectionId: number, sessionId: string) => {
  return api
    .post(`calibration/${sessionId}/connection/${connectionId}/conversation?conversationExternalId=${conversationId}`, {
      headers: getRequestHeaders(),
    })
    .json<CalibrationSessionsListResponse>()
}

export const removeConversationFromSession = (conversationId: string, connectionId: number, sessionId: string) => {
  return api
    .delete(
      `calibration/${sessionId}/connection/${connectionId}/conversation?conversationExternalId=${conversationId}`,
      { headers: getRequestHeaders() },
    )
    .json<CalibrationSessionsListResponse>()
}

export const markReviewAsBaseline = (
  connectionId: number,
  sessionId: string,
  calibrationReviewId: string,
  data: {
    conversationExternalId: string
    final: boolean
  },
) => {
  return api
    .put(`calibration/${sessionId}/connection/${connectionId}/reviews/${calibrationReviewId}/final`, {
      json: data,
      headers: getRequestHeaders(),
    })
    .json<CalibrationFeedbackResponse>()
}

export const getSessionsForConversation = (conversationId: string, connectionId: number) => {
  const query = new URLSearchParams()
  query.append('conversationExternalId', conversationId.toString())

  return api
    .get(`calibration/connection/${connectionId}/list?${query}`, { headers: getRequestHeaders() })
    .json<CalibrationSessionsListResponse>()
}
