import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_3 = {
  key: 1,
  class: "flex w-full items-center justify-between overflow-x-hidden text-left"
}
const _hoisted_4 = { class: "flex items-center gap-2" }

import { ChevronDownIcon } from '@klausapp/ui-kit/icons'
import { ref, computed, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCollapsiblePanel',
  props: {
    collapsed: { type: Boolean },
    disabled: { type: Boolean },
    collapseToggleFull: { type: Boolean },
    contentClass: {},
    panelClass: {},
    panelExpandedClass: {},
    hasAppendix: { type: Boolean }
  },
  emits: ["toggle"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const isCollapsed = ref(props.collapsed)
const panelHover = ref(false)

const classList = computed(() => {
  if (props.contentClass) return props.contentClass
  return props.collapseToggleFull ? 'mt-2' : 'mt-3'
})

function collapse() {
  if (props.disabled) return

  isCollapsed.value = !isCollapsed.value
  emit('toggle')
}

watch(
  () => props.collapsed,
  (value) => (isCollapsed.value = value),
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'group/collapsible-panel',
      { 'bg-gray-10': !isCollapsed.value && _ctx.collapseToggleFull, 'hover:bg-gray-10 mb-2 rounded-md': _ctx.collapseToggleFull },
      _ctx.panelClass,
      !isCollapsed.value && _ctx.panelExpandedClass,
    ])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([
        'text-gray-60 group/collapsible-panel-button px-2 py-2',
        { 'cursor-default': _ctx.disabled, 'text-gray-80 w-full': _ctx.collapseToggleFull },
        _ctx.hasAppendix && 'bg-zblue-10 hover:bg-zblue-10',
      ]),
      type: "button",
      "aria-expanded": !isCollapsed.value,
      onClick: collapse,
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (panelHover.value = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (panelHover.value = false)),
      "on:focusIn": _cache[2] || (_cache[2] = ($event: any) => (panelHover.value = true)),
      "on:focusOut": _cache[3] || (_cache[3] = ($event: any) => (panelHover.value = false))
    }, [
      (_ctx.$slots.appendix)
        ? _renderSlot(_ctx.$slots, "appendix", { key: 0 })
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(['flex w-full items-center gap-2', { 'w-full justify-between': _ctx.collapseToggleFull }])
      }, [
        _renderSlot(_ctx.$slots, "trigger", {}, () => [
          (_ctx.$slots.icon || _ctx.$slots.title)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(['font-bold', _ctx.collapseToggleFull ? 'flex items-center gap-2.5 text-xs' : 'text-xxs'])
              }, [
                (_ctx.$slots.icon)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                      _renderSlot(_ctx.$slots, "icon")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.$slots.title)
                  ? _renderSlot(_ctx.$slots, "title", { key: 1 })
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (_ctx.$slots.customContent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "customContent")
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "action-menu", {
              panelCollapsed: isCollapsed.value,
              panelHover: panelHover.value
            }),
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass([
                'flex items-center justify-center p-1',
                {
                  'rotate-180': !isCollapsed.value,
                  'rounded-full leading-[0] mix-blend-multiply': _ctx.collapseToggleFull,
                },
              ])
                }, [
                  _createVNode(_unref(ChevronDownIcon), { class: "icon-s text-gray-60" })
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ], 2 /* CLASS */),
      (_ctx.$slots.dispute)
        ? _renderSlot(_ctx.$slots, "dispute", { key: 1 })
        : _createCommentVNode("v-if", true)
    ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass([
        'transition-spacing px-2 pb-2 duration-200',
        isCollapsed.value ? 'mt-0 hidden h-0 overflow-hidden' : classList.value,
      ])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}
}

})