import type { Options } from 'ky'
import { isFunction, pick } from 'lodash-es'
import qs from 'query-string'
import type { ChannelFilterResponse } from '@zendesk/zqa-services/dashboard'
import type { ConnectionsResponse } from '@zendesk/zqa-services/connections'
import type { TagsSearchResponse } from '@zendesk/zqa-services/tickets'
import type { LookerCookielessSession, LookerSdkSession } from '@zendesk/zqa-services/users'

import { api, oldApi } from '@/api'
import { session } from '@/composables/useSession'

import type { InitialAssignmentFilter, LookerDashboard } from '../looker/types'
import { getDatesFromPeriod } from '../utils/period-options'
import type {
  CategoryRatingsByTime,
  CoreFilterParams,
  FilterParams,
  Member,
  RatingScaleChangeResponse,
  RatingsOverview,
  ReviewerRatingsByCategory,
  ReviewerRatingsByTime,
  Reviews,
  ScoreByTime,
  ScoresByTime,
  UsersOverview,
  WorkspacesOverview,
} from '../types'

export type CoreParams = Pick<FilterParams, 'workspaceIds'>

export const apiCallGenerator =
  <S extends CoreFilterParams>(path: string | ((params: S) => string), type: 'json' | 'blob' = 'json') =>
  <T>(url: string, options?: Options, fieldWhiteList?: (keyof S)[]) =>
  async (filterParams: S, signal?: AbortSignal): Promise<T> => {
    const { workspaceIds, timePeriod, fromDate, toDate, helpdesksCustomFilters, ...params } = filterParams
    const customFilters = helpdesksCustomFilters?.filter((filter) => filter.values.length)
    const flatParams = {
      ...getDatesFromPeriod({ timePeriod, fromDate, toDate }),
      helpdesksCustomFilters: customFilters?.length ? customFilters?.map((filter) => JSON.stringify(filter)) : [],
      ...params,
    }
    const whitelistedParams = fieldWhiteList ? pick(flatParams, fieldWhiteList) : flatParams

    const pathString = isFunction(path) ? path(filterParams) : path

    const req = await api.get(`${pathString}/${url}`, {
      searchParams: qs.stringify(whitelistedParams, { skipNull: true }),
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      signal,
      ...options,
    })

    if (type === 'json') {
      return req.json<T>()
    } else {
      return req.blob() as Promise<T>
    }
  }

const createApiCall = apiCallGenerator<FilterParams>('dashboard')
const createBlobApiCall = apiCallGenerator<FilterParams>('dashboard', 'blob')

export const getAllExportsCsv = createBlobApiCall<Blob>('export.csv')

export const getOverview = createApiCall<RatingsOverview>('overview')

export const getScoreByTime = createApiCall<ScoreByTime>('score-by-time')

export const getScoresByTime = createApiCall<ScoresByTime>('scores-by-time')

export const getReviewerRatingsByCategory = createApiCall<ReviewerRatingsByCategory>('reviewer-ratings-by-category')

export const getReviewerRatingsByCategoryCsv = createBlobApiCall<Blob>('reviewer-ratings-by-category.csv')

export const getReviewerRatingsByTime = createApiCall<ReviewerRatingsByTime>('reviewer-ratings-by-time')

export const getReviewerRatingsByTimeCsv = createBlobApiCall<Blob>('reviewer-ratings-by-time.csv')

export const getCategoryRatingsByTime = createApiCall<CategoryRatingsByTime>('category-ratings-by-time')

export const getCategoryRatingsByTimeCsv = createBlobApiCall<Blob>('category-ratings-by-time.csv')

export const getReviews = createApiCall<Reviews>('reviews')

export const getReviewsCsv = createBlobApiCall<Blob>('reviews.csv')

export const getRatingScaleChanges = createApiCall<RatingScaleChangeResponse>('rating-scale-changes', {}, [
  'fromDate',
  'toDate',
])

export const getWorkspacesOverview = createApiCall<WorkspacesOverview>('workspaces-overview')

export const getWorkspacesOverviewCsv = createBlobApiCall<Blob>('workspaces-overview.csv')

export const getUsersOverview = createApiCall<UsersOverview>('users-overview')

export const getUsersOverviewCsv = createBlobApiCall<Blob>('users-overview.csv')

export const getTicketChannels = createApiCall<ChannelFilterResponse>('ticket-channels')

export const getHashtags = async ({ workspaceIds }: CoreParams) => {
  const { tags } = await api
    .get('hashtags', { headers: { 'X-Klaus-Workspace': workspaceIds.join() } })
    .json<{ tags: { value: string; count: number }[] }>()

  return tags.map((t) => `#${t.value}`)
}

export const getDisputeHashtags = async ({ workspaceIds }: CoreParams) => {
  const { tags } = await api
    .get('hashtags/disputes', { headers: { 'X-Klaus-Workspace': workspaceIds.join() } })
    .json<{ tags: { value: string; count: number }[] }>()

  return tags.map((t) => `#${t.value}`)
}

export type SearchUsersReturn = Promise<{
  users: Member[]
  total: number
}>

export const searchUsers = async ({
  workspaceIds,
  name,
  ids,
  limit = 50,
  groupIds,
  signal,
}: {
  workspaceIds?: number[]
  name?: string
  ids?: number[]
  limit?: number
  groupIds?: string[]
  signal?: AbortSignal
}): Promise<SearchUsersReturn> => {
  const { users, total } = await api
    .get('users/search', {
      headers: workspaceIds ? { 'X-Klaus-Workspace': workspaceIds.join() } : {},
      searchParams: qs.stringify({ name, ids, limit, groupIds }),
      signal,
    })
    .json<{ users: { id: string; name: string; email: string; avatar: string }[]; total: string }>()

  return { users: users.map((u) => ({ ...u, id: parseInt(u.id) })), total: parseInt(total) }
}

export interface UserGroupSearchResult {
  id: string
  name: string
}
interface UserGroupsSearchResponse {
  userGroups: UserGroupSearchResult[]
  total: string
}

export const searchUserGroups = ({
  workspaceIds,
  name,
  ids,
  limit = 50,
  signal,
}: {
  workspaceIds: number[]
  name?: string
  ids?: string[]
  limit?: number
  signal?: AbortSignal
}): Promise<UserGroupsSearchResponse> =>
  api
    .get('user-groups-search', {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ name, ids, limit }),
      signal,
    })
    .json<UserGroupsSearchResponse>()

export const searchTicketTags = ({
  workspaceIds,
  tag,
  signal,
}: CoreParams & { tag?: string } & { signal?: AbortSignal }) =>
  api
    .get('ticket/tags/prefix', {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ tag, limit: 100 }),
      signal,
    })
    .json<TagsSearchResponse>()

export const searchAccountTicketTags = ({ tag, signal }: { tag?: string; signal?: AbortSignal }) =>
  api
    .get('ticket/account/tags/prefix', {
      searchParams: qs.stringify({ tag, limit: 100 }),
      signal,
    })
    .json<TagsSearchResponse>()

export const getWorkspaceConnections = async ({ workspaceIds }: CoreParams) => {
  const { connections } = await api
    .get('connections', { headers: { 'X-Klaus-Workspace': workspaceIds.join() } })
    .json<ConnectionsResponse>()
  return connections
}

interface TeamScorecardsResponse {
  data: {
    id: number
    ratingCategoryIds: number[]
    tag: string
  }[]
}

// TODO: Last old API endpoint under dashboard
export const getTeamScorecards = async ({ workspaceIds }: CoreParams) => {
  const { data } = await oldApi
    .get(`payments/${session.account.id}/teams/${workspaceIds[0]}/team-scorecards`)
    .json<TeamScorecardsResponse>()

  return data.map((r) => r.tag)
}

export const getLookerDashboards = () => api.get('looker/dashboards').json<{ dashboards: LookerDashboard[] }>()

export const getInitialAssignmentFilterData = async () => {
  return await api
    .get('assignments/v2/looker/filter', { headers: { 'X-Klaus-Workspace': session.workspace.id.toString() } })
    .json<InitialAssignmentFilter>()
}

export const getLookerSession = (id: string) =>
  api
    .get('looker/session', { searchParams: { dashboardId: id } })
    .json<LookerCookielessSession>()
    .then((session) => ({
      authentication_token: session.authenticationToken,
      authentication_token_ttl: Number(session.authenticationTokenTtl),
      navigation_token: session.navigationToken,
      navigation_token_ttl: Number(session.navigationTokenTtl),
      api_token: session.apiToken,
      api_token_ttl: Number(session.apiTokenTtl),
      // Needed for embed SDK
      session_reference_token_ttl: NaN,
    }))

export const getLookerSDKToken = (id: string) =>
  api
    .get('looker/sdk', { searchParams: { dashboardId: id } })
    .json<LookerSdkSession>()
    .then((tokenResponse) => ({
      access_token: tokenResponse.accessToken,
      token_type: tokenResponse.tokenType,
      expires_in: tokenResponse.expiresIn != null ? parseInt(tokenResponse.expiresIn, 10) : null,
      refresh_token: tokenResponse.refreshToken,
    }))
