import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "[&:not(:last-child)]:before:border-l-gray-30 relative flex gap-x-3 [&:not(:last-child)]:before:absolute [&:not(:last-child)]:before:bottom-0 [&:not(:last-child)]:before:left-3 [&:not(:last-child)]:before:top-8 [&:not(:last-child)]:before:border-l" }
const _hoisted_2 = { class: "flex flex-col gap-y-2 pb-4" }
const _hoisted_3 = { class: "text-gray-90 flex min-h-6 items-center text-sm font-semibold" }
const _hoisted_4 = { class: "text-gray-70 text-sm empty:hidden" }


export default /*@__PURE__*/_defineComponent({
  __name: 'UiStepperStep',
  props: {
    title: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["bg-gray-20 text-gray-70 flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-xs font-semibold", _ctx.$style.stepIndicator])
    }, null, 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})