import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["aria-labelledby", "aria-hidden"]
const _hoisted_2 = ["onKeyup"]
const _hoisted_3 = {
  key: 1,
  class: "sticky top-0 z-10 flex items-center gap-2 bg-white pb-10 pt-8"
}
const _hoisted_4 = {
  key: 0,
  class: "grow text-lg font-semibold"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 1,
  "data-testid": "drawer-subtitle",
  class: "text-gray-60"
}
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = { class: "grow text-sm" }
const _hoisted_9 = {
  key: 2,
  class: "flex justify-end gap-4 pt-10"
}

import { watch, ref, nextTick } from 'vue'
import { v4 as uuid } from 'uuid'
import { TransitionFade } from '@morev/vue-transitions'
import { UiSpinner } from '@klausapp/ui-kit'
import { XIcon } from '../icons'
import UiButton from './UiButton.vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiDrawer',
  props: {
    active: { type: Boolean },
    title: { default: '' },
    subtitle: { default: '' },
    loading: { type: Boolean },
    maxWidth: { default: '824px' },
    narrow: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit



const id = uuid()
const panelRef = ref<HTMLElement | null>(null)

watch(
  () => props.active,
  async (isActive) => {
    const app = document.getElementById('app')
    if (!app) return

    if (isActive) {
      app.inert = true
    } else {
      app.removeAttribute('inert')
    }

    app.setAttribute('tabindex', isActive ? '-1' : '0')

    // TODO: implement focus trap
    if (isActive) {
      await nextTick()
      panelRef.value?.focus()
    }
  },
)

const closePanel = () => {
  emit('close')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_unref(TransitionFade), { duration: 200 }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _mergeProps({
              key: 0,
              ref_key: "panelRef",
              ref: panelRef,
              "aria-modal": "true",
              "aria-labelledby": _ctx.title ? `overlay-panel-${_unref(id)}-title` : undefined,
              tabindex: "-1",
              role: "dialog",
              "aria-hidden": `${!_ctx.active}`,
              class: ["fixed inset-0 overflow-hidden", [_ctx.$attrs.class, !_ctx.active && 'hidden']]
            }, _ctx.$attrs), [
              _createElementVNode("div", {
                class: "fixed inset-0 bg-black/10",
                tabindex: "0",
                role: "button",
                "data-testid": "drawer-backdrop",
                onKeyup: [
                  _withKeys(_withModifiers(closePanel, ["exact"]), ["enter"]),
                  _withKeys(_withModifiers(closePanel, ["exact"]), ["space"])
                ],
                onClick: closePanel
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
              _createElementVNode("div", {
                class: _normalizeClass(["shadow-large no-scrollbar absolute bottom-0 right-0 top-0 z-10 flex w-full flex-col overflow-y-auto bg-white pb-8", _ctx.narrow ? 'px-5' : 'px-8']),
                style: _normalizeStyle({ maxWidth: _ctx.maxWidth }),
                role: "document",
                "data-testid": "drawer-wrapper"
              }, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_unref(UiSpinner), {
                      key: 0,
                      "data-testid": "drawer-loading-spinner"
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.$slots.header)
                        ? _renderSlot(_ctx.$slots, "header", { key: 0 })
                        : (_openBlock(), _createElementBlock("header", _hoisted_3, [
                            (_ctx.title || _ctx.subtitle)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  (_ctx.title)
                                    ? (_openBlock(), _createElementBlock("h2", {
                                        key: 0,
                                        id: `overlay-panel-${_unref(id)}-title`,
                                        "data-testid": "drawer-title",
                                        class: "text-gray-80"
                                      }, _toDisplayString(_ctx.title), 9 /* TEXT, PROPS */, _hoisted_5))
                                    : _createCommentVNode("v-if", true),
                                  (_ctx.subtitle)
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.subtitle), 1 /* TEXT */))
                                    : _createCommentVNode("v-if", true)
                                ]))
                              : _createCommentVNode("v-if", true),
                            _createElementVNode("div", _hoisted_7, [
                              _renderSlot(_ctx.$slots, "actions", {}, () => [
                                _createVNode(UiButton, {
                                  secondary: "",
                                  small: "",
                                  "data-testid": "drawer-default-close-button",
                                  onClick: closePanel
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(XIcon), { class: "icon-m" })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ])
                          ])),
                      _createElementVNode("div", _hoisted_8, [
                        _renderSlot(_ctx.$slots, "default")
                      ]),
                      (_ctx.$slots['bottom-actions'])
                        ? (_openBlock(), _createElementBlock("footer", _hoisted_9, [
                            _renderSlot(_ctx.$slots, "bottom-actions")
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
              ], 6 /* CLASS, STYLE */)
            ], 16 /* FULL_PROPS */, _hoisted_1))
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}
}

})