import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { delay } from '@/components/Tippy'
import analytics from '@/utils/analytics'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarLink',
  props: {
    title: {},
    to: {},
    hideTooltip: { type: Boolean },
    linkActive: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const route = useRoute()
const isActive = computed(() => route.name?.toString().startsWith(props.to.name) || props.linkActive)

const handleAnalytics = () => {
  if (!isActive.value) {
    analytics.navbarEvent('Clicked navbar link', props.title)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['navbar__link', isActive.value && 'router-link-active'])
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.to,
      "aria-label": _ctx.title,
      onClick: handleAnalytics
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", { active: isActive.value })
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["to", "aria-label"])
  ], 2 /* CLASS */)), [
    [_directive_tippy, { placement: 'right', content: _ctx.hideTooltip ? null : _ctx.title, delay: _unref(delay) }]
  ])
}
}

})