import config from '@/config'

export const whitelistedOrigins = (config.authWhitelistedExtensions || '')
  .split(',')
  .map((id) => `chrome-extension://${id}`)

const ancestors = location.ancestorOrigins || []

export const illegallyEmbedded =
  !('Cypress' in window) && ancestors.length && !whitelistedOrigins.includes(ancestors[0])

if (ancestors.length && !illegallyEmbedded) {
  config.embeddedRuntime = true
}

const extensionOriginPeristenceKey = 'klausExtensionOrigin.v1'

export const getExtensionOrigin = () => localStorage.getItem(extensionOriginPeristenceKey)

export const setExtensionOrigin = (value: string | null) =>
  value === null
    ? localStorage.removeItem(extensionOriginPeristenceKey)
    : localStorage.setItem(extensionOriginPeristenceKey, value)

// Helper for extension to get access to third-party cookies and storage
export const askForStorageAccess = async ({ silent } = { silent: false }): Promise<boolean> => {
  // API not supported or not in extension, do nothing
  if (!document.hasStorageAccess || !config.embeddedRuntime) return true

  const { state } = await navigator.permissions.query({ name: 'storage-access' })
  const willPrompt = state === 'prompt'

  // If access has been already granted, ask it to get access
  if (!willPrompt && silent) {
    await document.requestStorageAccess()
    return true
  }

  // Do not attempt storage request in silent mode if not given permission
  if (willPrompt && silent) return false

  // Browser can trigger permission prompt or access is explicitly denied
  try {
    await document.requestStorageAccess()
    return true
  } catch (err) {
    // If access is denied, return false
    if (err instanceof Error && err.name === 'NotAllowedError') return false
    throw err
  }
}
