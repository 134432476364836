import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-sidebar-link-background-active flex h-[56px] w-[64px] items-center justify-center" }

import { ref, watch } from 'vue'
import { UiGoal } from '@klausapp/ui-kit'
import i18n from '@/i18n'
import { delay } from '@/components/Tippy'
import { useUserGoal } from '@/composables/useUserGoal'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserGoal',
  setup(__props) {

const goal = ref(0)
const reviewCount = ref(0)
const goalTooltip = ref('')

const [userGoal] = useUserGoal()

watch(
  userGoal,
  (val, previous) => {
    if (!val) return
    const { goal: newGoal, reviewCount: newReviewCount, goalType } = val
    const { goal: previousGoal, reviewCount: previousCount } = previous || {}
    goal.value = newGoal
    reviewCount.value = newReviewCount

    if (goalType === 'UNKNOWN') return

    const period = {
      DAILY: i18n.t('modules.application.navbar.goal_daily'),
      WEEKLY: i18n.t('modules.application.navbar.goal_weekly'),
      MONTHLY: i18n.t('modules.application.navbar.goal_monthly'),
    }[goalType || 'WEEKLY']

    goalTooltip.value = i18n.t('modules.application.navbar.review_goal', {
      n: newReviewCount,
      total: newGoal.toString(),
      period,
    })

    if (newReviewCount >= newGoal && (previousCount || 0) < (previousGoal || 0)) throwConfetti()
  },
  { immediate: true },
)

const throwConfetti = async () => {
  const { confettiParty } = await import('../utils/confetti')
  confettiParty()
  setTimeout(confettiParty, 1200)
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_unref(UiGoal), {
      key: goal.value,
      goal: goal.value,
      dark: "",
      "action-count": reviewCount.value
    }, null, 8 /* PROPS */, ["goal", "action-count"])), [
      [_directive_tippy, { placement: 'right', content: goalTooltip.value, delay: _unref(delay), offset: [0, 14] }]
    ])
  ]))
}
}

})