import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onKeyup"]
const _hoisted_2 = { class: "mt-1 flex w-full cursor-default gap-1 text-xs font-medium" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-60"
}
const _hoisted_4 = {
  key: 1,
  class: "text-red-60 flex flex-col"
}
const _hoisted_5 = { class: "text-gray-60" }

import { defineComponent, useCssModule, computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiFormField',
  props: {
    label: { default: '' },
    help: { default: '' },
    error: { type: [String, Boolean, Array], default: '' },
    charCount: { default: undefined },
    maxChars: { default: undefined },
    preventLabelClick: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

const style = useCssModule()

const handleLabelClick = (event: Event) => {
  if (props.preventLabelClick && event.target instanceof Element && event.target.className !== style.label) {
    event.preventDefault()
  }
}

const charsAboveMax = computed(() => Boolean(props.charCount && props.maxChars && props.charCount > props.maxChars))
const hasError = computed(() => !!props.error || (Array.isArray(props.error) && props.error.length > 0))



defineComponent({
  name: 'UiFormField',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" eslint-disable-next-line vuejs-accessibility/no-static-element-interactions "),
    _createElementVNode("label", _mergeProps({
      class: [_ctx.$style.formField, { [_ctx.$style.error]: hasError.value }]
    }, _ctx.$attrs, {
      onClick: handleLabelClick,
      onKeyup: [
        _withKeys(_withModifiers(handleLabelClick, ["exact"]), ["enter"]),
        _withKeys(_withModifiers(handleLabelClick, ["exact"]), ["space"])
      ]
    }), [
      (_ctx.$slots.label || _ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["text-gray-70 mb-1 text-xs font-medium", _ctx.$style.label])
          }, [
            _createCommentVNode(" @slot custom content of the label "),
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" @slot content inside the form field "),
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("div", _hoisted_2, [
        (typeof _ctx.error === 'string' && _ctx.error !== '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error), 1 /* TEXT */))
          : (Array.isArray(_ctx.error))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.error, (message, index) => {
                  return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(message), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : (_ctx.help !== '' || _ctx.$slots.help)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createCommentVNode(" @slot custom content of the help section "),
                  _createElementVNode("span", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "help", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.help), 1 /* TEXT */)
                    ])
                  ])
                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
              : _createCommentVNode("v-if", true),
        _createCommentVNode(" optional character count for input in default slot"),
        (_ctx.charCount !== undefined && _ctx.maxChars)
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: _normalizeClass(["flex grow justify-end text-xs", charsAboveMax.value ? 'text-red-60' : 'text-gray-60'])
            }, _toDisplayString(_ctx.charCount) + "/" + _toDisplayString(_ctx.maxChars), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ])
    ], 16 /* FULL_PROPS */, _hoisted_1)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}
}

})