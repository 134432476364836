import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue'
import { CheckIcon } from '../icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiColor',
  props: {
    color: {},
    selected: { type: Boolean, default: false }
  },
  emits: ["click"],
  setup(__props: any) {





defineComponent({
  name: 'UiColor',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("\n    triggered on click\n    @event click\n  "),
    _createElementVNode("button", {
      class: _normalizeClass([_ctx.$style.color, { [_ctx.$style.selected]: _ctx.selected }]),
      style: _normalizeStyle({ '--swatch-color': _ctx.color }),
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, [
      (_ctx.selected)
        ? (_openBlock(), _createBlock(_unref(CheckIcon), {
            key: 0,
            class: "icon-m",
            style: { color: 'var(--white)' }
          }))
        : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}
}

})