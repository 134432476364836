import type { ScaleType } from '@/data/scales'

export enum RequireReasonVisibility {
  All = 'all',
  Negative = 'negative',
  NegativeAndNeutral = 'negative_and_neutral',
}

export interface RootCause {
  id: number | string
  cause: string
}

export enum AutoQaCategoryType {
  Grammar = 'grammar',
  Empathy = 'empathy',
  Greeting = 'greeting',
  Closing = 'closing',
  Readability = 'readability',
  Tone = 'tone',
  Comprehension = 'issue_understanding',
  Solution = 'solution',
  Custom = 'custom',
  Prompt = 'prompt',
}

export interface FullCategory {
  autoQaApplicabilities?: string[]
  autoQaCategory?: AutoQaCategoryType | null
  autoQaCustomCategoryTemplateId?: string
  conditions: { type: string; condition: any; values: string[] }[] | null
  created: string
  description: string
  dontCalcZero: boolean
  failCategory: boolean
  groupId?: number
  groupName?: string
  groupPosition?: number
  hideZero: boolean
  id: number
  isArchived: boolean
  isInactive: boolean
  name: string
  ownerId: number
  paymentId: number
  position: number
  ratingRangeMax: number
  ratingRangeMin: number
  requireReason: boolean
  multipleRequireReasons: boolean
  requireReasonVisibility: RequireReasonVisibility
  freeTextAllowed: boolean
  rootCauses: RootCause[]
  scale: ScaleType
  tags: string[]
  teamId: number
  updated: string
  weighting: number
  deletedAt: string | null
}
