import type { FeatherIconComponent } from '@klausapp/ui-kit/icons'
import type { CustomField } from '@zendesk/zqa-services/filters'

import type { RootCause, RequireReasonVisibility } from '@/types/category'
import type { ReviewStatus } from '@/types/review'

import type { CsatFilterParams } from './csat/types'
import type { CalibrationFilterParams } from './calibration/types'
import type { CategoryFilterParams } from './api/category'

export interface SortedResponse {
  sort: {
    field: string
    asc: boolean
  }
}

export interface PaginatedResponse {
  pagination: {
    page: number
    pageSize: number
    total: number
  }
}

export enum FilterSelfReview {
  Exclude = 'exclude',
  Include = 'include',
  Only = 'only',
}

export enum FilterDirection {
  Given = 'given',
  Received = 'received',
}

/**
 * Possible duration steps to query by
 */
export enum FilterTimeStep {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

/**
 * Possible duration steps to query by
 */
export enum FilterTimeStepKey {
  Day = 'days',
  Week = 'weeks',
  Month = 'months',
  Year = 'years',
}

/**
 * Time presets for the picker
 */
export enum FilterTimePeriod {
  ThisWeek = 'this_week',
  LastWeek = 'last_week',
  Week = 'week',
  ThisMonth = 'this_month',
  LastMonth = 'last_month',
  Month = 'month',
  Custom = 'custom',
  ThisYear = 'this_year',
  LastYear = 'last_year',
  Last = 'past',
}

export interface Member {
  id: number
  name: string
  email: string
  avatar?: string | null
  icon?: FeatherIconComponent
}

export interface HelpdeskCustomFieldFilter {
  externalId: CustomField['externalId']
  values: string[]
}

export interface CoreFilterParams {
  workspaceIds: number[]
  timePeriod: FilterTimePeriod
  fromDate?: string
  toDate?: string
  userGroups: string[]
  helpdesksCustomFilters?: HelpdeskCustomFieldFilter[]
  value?: number
  unit?: FilterTimeStep
}
export interface FilterParams extends CoreFilterParams {
  // workspaceId is a legacy param for bookmarks and pins backwards compatibility. Use workspaceIds instead
  workspaceId?: number
  step?: FilterTimeStep
  direction?: FilterDirection
  decimals: number
  selfReview?: FilterSelfReview
  userIds: number[]
  connectionIds: number[]
  ratingCategoryTags: string[]
  commentTags: string[]
  channels?: string[]
  ticketTags: string[]
  ticketDates: boolean
  sort?: string
  sortAsc?: boolean
  page?: number
  pageSize?: number
  categoryId: string
}

export interface DashboardFilterParams
  extends FilterParams,
    CsatFilterParams,
    CalibrationFilterParams,
    CategoryFilterParams {}

export type FilterUserActionParams = Pick<FilterParams, 'sort' | 'sortAsc' | 'page' | 'pageSize'>

export type FilterTimeParams = Pick<FilterParams, 'timePeriod' | 'fromDate' | 'toDate' | 'value' | 'unit'>

export interface RatingsOverview {
  change: number | null
  comments: string
  criticalErrorFreeRate?: number
  criticalErrorFreeRateChange?: number
  criticalErrorFreeReviews?: number
  passRate?: number
  passRateChange?: number
  passRateBaseline?: number
  passedReviews?: number
  reviewTime?: string
  score: number | null
  tickets: string
}

export interface ScoreByTime {
  labels: string[]
  score: (number | null)[]
}

export interface ScoresByTime {
  labels: string[]
  workspaces: {
    id: string
    reviews: number[]
    scores: (number | null)[]
  }[]
  averageScores: (number | null)[]
  totalReviews: number[]
  averageCsat: (number | null)[]
  totalCsatResponses: string[]
}

export interface Reviewer {
  id: string
  name: string
  avatar: string
}

interface RatingByCategoryRow {
  reviewer: Reviewer
  ratings: string
  comments: string
  score: number | null
  data: (number | null)[]
}

export interface ReviewerRatingsByCategory extends PaginatedResponse, SortedResponse {
  rows: RatingByCategoryRow[]
  dataLabels: string[]
  score: number | null
  ratings: string
  comments: string
  dataTotals: (number | null)[]
  active: boolean[]
}

interface RatingByTimeRow {
  reviewer: Reviewer
  score: number | null
  count: string
  data: (number | null)[]
}

export interface ReviewerRatingsByTime extends PaginatedResponse, SortedResponse {
  rows: RatingByTimeRow[]
  dataLabels: string[]
  score: number | null
  count: string
}

interface CategoryRatingByTimeRow {
  rows: CategoryRatingByTimeRow[]
  category: {
    archivedAt: string | null
    critical: boolean
    description: string
    id: string
    name: string
    scale: string
    workspaceId: string
  }
  score: number | null
  count: string
  data: (number | null)[]
  id: string
  active: boolean
  name: string
  hidden: string
}

export interface CategoryRatingsByTime extends SortedResponse {
  rows: CategoryRatingByTimeRow[]
  dataLabels: string[]
  score: number | null
  count: string
}

interface ReviewRow {
  reviewId: string
  reviewStatus: ReviewStatus | null
  workspaceId: string
  connectionId: string
  conversationExternalId: string
  messageExternalId?: string
  sourceType: string
  lastComment: string | null
  data: (number | null)[]
  reviewTime?: string
}

export interface Reviews extends PaginatedResponse, SortedResponse {
  rows: ReviewRow[]
  dataLabels: string[]
  active: boolean[]
}

export interface RatingScaleChange {
  fromScale: string
  toScale: string
  changedAt: string
}

export interface RatingScaleChangeResponse {
  changes: RatingScaleChange[]
}

interface RatingCategoryDetailsRow {
  label: string
  isScore: boolean
  data: number[]
  total: number
}

export interface RatingCategoryDetails extends SortedResponse {
  dataLabels: string[]
  scores: number[]
  score: number
  counts: number[]
  count: number
  rows: RatingCategoryDetailsRow[]
}

export interface PredefinedCategoryReasonsRow {
  reviewId: string
  ticketId: string
  conversationReview: boolean
  sourceType: string
  lastComment: string | null
  data: (number | null)[]
}

export interface PredefinedCategoryReasons extends SortedResponse {
  rows: PredefinedCategoryReasonsRow[]
  dataLabels: string[]
  score: number | null
  ratings: string
  comments: string
  dataTotals: (number | null)[]
  active: boolean[]
  requireReasonVisibility: RequireReasonVisibility
}

export interface OtherCategoryReasonsRow {
  reviewId: string
  connectionId: string
  conversationExternalId: string
  messageExternalId?: string
  sourceType: string
  lastComment: string | null
  data: (number | null)[]
}

export interface OtherCategoryReasons extends PaginatedResponse, SortedResponse {
  rows: OtherCategoryReasonsRow[]
  dataLabels: string[]
  score: number | null
  ratings: string
  comments: string
  dataTotals: (number | null)[]
  active: boolean[]
}

export interface RatingCategory {
  id: number
  name: string
  rootCauses: RootCause[]
  requireReason: boolean
  groupName?: string
  groupId?: number
  description?: string
}

interface WorkspaceOverviewRow {
  workspace: {
    id: string
    usersCount: string
  }
  score: number
  change: number
  conversations: string
  comments: string
  rows: WorkspaceOverviewRow[]
}
export interface WorkspacesOverview extends SortedResponse, PaginatedResponse {
  rows: WorkspaceOverviewRow[]
  score: number
  change: number
  conversations: string
  comments: string
  passRate?: string
  passRateChange?: string
  criticalErrorFreeRate?: string
  criticalErrorFreeRateChange?: string
}

interface UserOverviewRow {
  user: Reviewer
  score: number
  change: number
  conversations: number
  reviews: number
  comments: number
  rows: UserOverviewRow[]
  group: boolean
  reviewTime?: string
  passedReviews?: string
  passRate?: string
  passRateChange?: string
  criticalErrorFreeRate?: string
  criticalErrorFreeRateChange?: string
}

export interface UsersOverview extends SortedResponse, PaginatedResponse {
  rows: UserOverviewRow[]
  score: number
  change: number
  conversations: number
  reviews: number
  comments: number
}
