import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import i18n from '@/i18n'
import { FilterTimePeriod, type FilterTimeParams, FilterTimeStep, FilterTimeStepKey } from '../types'
import { getLastOptionString } from './map-last-string'

dayjs.extend(utc)
dayjs.extend(quarterOfYear)

interface CustomTimePeriodOption {
  name: string
  value: FilterTimePeriod.Custom
  group?: number
  getDynamicName?: (value: number) => string
}

export interface PresetTimePeriodOption {
  name: string
  value: FilterTimePeriod
  getPeriod: (value?: number, unit?: string) => { fromDate: string; toDate: string }
  getDynamicName?: (value: number) => string
  group?: number
}

export type TimePeriodOption = PresetTimePeriodOption | CustomTimePeriodOption

interface TimeUnitOption {
  name: string
  value: FilterTimeStep
  key: FilterTimeStepKey
}

export const minDate = new Date('01/01/2018')

/**
 * List of time period presets
 */
export const getTimePeriodOptions = (): TimePeriodOption[] => [
  {
    name: i18n.t('dashboard.time_periods.this_week'),
    value: FilterTimePeriod.ThisWeek,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('week').toJSON(),
      toDate: dayjs().utc().endOf('week').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.last_week'),
    value: FilterTimePeriod.LastWeek,
    getPeriod: () => {
      const lastWeek = dayjs().utc().subtract(1, 'week')
      return {
        fromDate: lastWeek.startOf('week').toJSON(),
        toDate: lastWeek.endOf('week').toJSON(),
      }
    },
  },
  {
    name: i18n.t('dashboard.time_periods.last_7_days'),
    value: FilterTimePeriod.Week,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('day').subtract(6, 'day').toJSON(),
      toDate: dayjs().utc().endOf('day').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.this_month'),
    value: FilterTimePeriod.ThisMonth,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('month').toJSON(),
      toDate: dayjs().utc().endOf('month').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.last_month'),
    value: FilterTimePeriod.LastMonth,
    getPeriod: () => {
      const lastMonth = dayjs().utc().subtract(1, 'month')
      return {
        fromDate: lastMonth.startOf('month').toJSON(),
        toDate: lastMonth.endOf('month').toJSON(),
      }
    },
  },
  {
    name: i18n.t('dashboard.time_periods.last_30_days'),
    value: FilterTimePeriod.Month,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('day').subtract(29, 'day').toJSON(),
      toDate: dayjs().utc().endOf('day').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.custom'),
    value: FilterTimePeriod.Custom,
  },
]

export const getTimePeriodOptions2 = (): TimePeriodOption[] => [
  {
    name: i18n.t('dashboard.time_periods.this_week'),
    value: FilterTimePeriod.ThisWeek,
    getPeriod: () => ({
      fromDate: dayjs().startOf('week').toJSON(),
      toDate: dayjs().toJSON(),
    }),
    group: 1,
  },
  {
    name: i18n.t('dashboard.time_periods.this_month'),
    value: FilterTimePeriod.ThisMonth,
    getPeriod: () => ({
      fromDate: dayjs().startOf('month').toJSON(),
      toDate: dayjs().toJSON(),
    }),
    group: 1,
  },
  {
    name: i18n.t('dashboard.time_periods.this_year'),
    value: FilterTimePeriod.ThisYear,
    getPeriod: () => ({
      fromDate: dayjs().startOf('year').toJSON(),
      toDate: dayjs().toJSON(),
    }),
    group: 1,
  },
  {
    name: i18n.t('dashboard.time_periods.last_week'),
    value: FilterTimePeriod.LastWeek,
    getPeriod: () => {
      const lastWeek = dayjs().subtract(1, 'week')
      return {
        fromDate: lastWeek.startOf('week').toJSON(),
        toDate: lastWeek.endOf('week').toJSON(),
      }
    },
    group: 2,
  },
  {
    name: i18n.t('dashboard.time_periods.last_month'),
    value: FilterTimePeriod.LastMonth,
    getPeriod: () => {
      const lastMonth = dayjs().subtract(1, 'month')
      return {
        fromDate: lastMonth.startOf('month').toJSON(),
        toDate: lastMonth.endOf('month').toJSON(),
      }
    },
    group: 2,
  },
  {
    name: i18n.t('dashboard.time_periods.last_year'),
    value: FilterTimePeriod.LastYear,
    getPeriod: () => {
      const lastYear = dayjs().subtract(1, 'year')
      return {
        fromDate: lastYear.startOf('year').toJSON(),
        toDate: lastYear.endOf('year').toJSON(),
      }
    },
    group: 2,
  },
  {
    name: i18n.t('dashboard.time_periods.last.other'),
    getDynamicName: (value) => getLastOptionString(value),
    value: FilterTimePeriod.Last,
    getPeriod: (value, unit) => getDatesFromUnit(value, unit),
    group: 2,
  },
  {
    name: i18n.t('dashboard.time_periods.custom'),
    value: FilterTimePeriod.Custom,
    group: 3,
  },
]

export const getTimeUnitOptions = (): TimeUnitOption[] => [
  { name: i18n.t('dashboard.time_periods.units.days'), value: FilterTimeStep.Day, key: FilterTimeStepKey.Day },
  { name: i18n.t('dashboard.time_periods.units.weeks'), value: FilterTimeStep.Week, key: FilterTimeStepKey.Week },
  { name: i18n.t('dashboard.time_periods.units.months'), value: FilterTimeStep.Month, key: FilterTimeStepKey.Month },
  { name: i18n.t('dashboard.time_periods.units.years'), value: FilterTimeStep.Year, key: FilterTimeStepKey.Year },
]

/**
 * Pull dates from timeperiod, also normalize to UTC
 */
export const getDatesFromPeriod = ({ timePeriod, fromDate, toDate }: FilterTimeParams) => {
  const periodOption = getTimePeriodOptions().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.value === FilterTimePeriod.Custom ? { fromDate, toDate } : periodOption.getPeriod()
}

export const getTimePeriodLabel = (timePeriod: FilterTimePeriod) => {
  const periodOption = getTimePeriodOptions().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.name
}

export const getDatesFromPeriod2 = ({ timePeriod, fromDate, toDate, value, unit }: FilterTimeParams) => {
  const periodOption = getTimePeriodOptions2().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.value === FilterTimePeriod.Custom ? { fromDate, toDate } : periodOption.getPeriod(value, unit)
}

export const getTimePeriodLabel2 = (timePeriod: FilterTimePeriod) => {
  const periodOption = getTimePeriodOptions2().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.name
}

export const getDatesFromUnit = (value?: number, unit?: string) => {
  let fromDate
  const toDate = dayjs().toJSON()
  if (!unit || !value) return { fromDate: fromDate.toJSON(), toDate }

  switch (unit) {
    case FilterTimeStep.Day:
      fromDate = dayjs().subtract(value, 'day')
      break
    case FilterTimeStep.Week:
      fromDate = dayjs().subtract(value, 'week')
      break
    case FilterTimeStep.Month:
      fromDate = dayjs().subtract(value, 'month')
      break
    case FilterTimeStep.Year:
      fromDate = dayjs().startOf('year').subtract(value, 'year')
      break
    default:
      throw new Error('Invalid time unit')
  }

  if (fromDate.isBefore(minDate)) {
    fromDate = minDate
  }

  return {
    fromDate: fromDate.toJSON(),
    toDate,
  }
}
