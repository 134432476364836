import type { Bot } from '@zendesk/zqa-services/users'

import i18n from '@/i18n'
import type { User } from '@/composables/useReviewSidebar/useReviewSidebar'
import missingUserImage from '../assets/missingUserImage'

interface DefaultRevieweeParams {
  mode?: 'assignee' | 'dominant'
  members: (User | Bot)[]
  reviewAuthors: number[]
  readonly?: boolean
  defaultReviewee?: number | string
  messageAuthor?: string | null
  transcriptSpeaker?: string | null
  mostFrequentUser: User | Bot
  assignee?: string
}

export function getLoadingRevieweeOption() {
  return {
    name: i18n.t('conversations.sidebar.reviewee_loading'),
    avatar: missingUserImage,
  }
}

export function getSelectUserOption() {
  return {
    name: i18n.t('conversations.sidebar.reviewee_placeholder'),
    avatar: missingUserImage,
  }
}

export const getFirstMatchingMember = (ids: string[], users: (User | Bot)[]) => {
  for (const id of ids) {
    const reviewee = users.find((u) => String(u.id) === id)
    if (reviewee) return reviewee
  }

  return undefined
}

export function getDefaultReviewee({
  mode,
  members,
  reviewAuthors,
  readonly,
  defaultReviewee,
  messageAuthor,
  transcriptSpeaker,
  mostFrequentUser,
  assignee,
}: DefaultRevieweeParams) {
  let idsToFind: string[] = []

  if (transcriptSpeaker) idsToFind = [transcriptSpeaker]
  // In readonly mode, default to your reviewers
  // `readonly` means scenarios where ReviewInput is hidden, e.g. for Agents when selfReviews are disabled
  // or when you've already reviewed the selected reviewee
  else if (readonly && reviewAuthors?.length) idsToFind = reviewAuthors.map(String)
  // In Assignments, default to your peer to be reviewed
  // For an Agent, the default reviewee is always themselves
  else if (defaultReviewee) idsToFind.push(String(defaultReviewee))
  // If a message is selected, default to its author
  else if (!readonly && messageAuthor) idsToFind.push(messageAuthor)
  // Lastly, default to either the most active agent or the current assignee, depending on the workspace setting (mode)
  else if (mode === 'dominant') idsToFind = [String(mostFrequentUser?.id)]
  else if (mode === 'assignee' && assignee) idsToFind = [assignee]

  let reviewee: User | Bot | undefined
  if (idsToFind.length) reviewee = getFirstMatchingMember(idsToFind, members)
  if (!reviewee && assignee) reviewee = getFirstMatchingMember([assignee], members)

  // If we haven't matched a user by now, ask to explicitly select one
  return reviewee || getSelectUserOption()
}
