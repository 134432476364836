import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex h-full w-full items-center justify-between overflow-hidden" }
const _hoisted_3 = { class: "flex h-full grow overflow-auto" }

import { v4 as uuid } from 'uuid'
import { ref, inject, computed, watch } from 'vue'

import { CheckIcon } from '@klausapp/ui-kit/icons'

import UiCheckbox from '../UiCheckbox.vue'
import UiMenuItemContent from './UiMenuItemContent.vue'
import { UiMenuItemTheme, type MenuState } from './types'
import { MENU_ITEM_CLASS } from './constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuItem',
  props: {
    tooltipContent: { default: '' },
    selectable: { type: Boolean, default: true },
    disabled: { type: Boolean },
    autoHeight: { type: Boolean },
    checkbox: { type: Boolean },
    selected: { type: Boolean },
    indeterminate: { type: Boolean },
    theme: { default: UiMenuItemTheme.Default }
  },
  emits: ["check", "select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit

const id = ref(uuid())
const innerRef = ref<HTMLLIElement>()

const menuState = inject<MenuState>('menuState')

const isFocused = computed(() => menuState?.activeItemId.value === id.value)

const shouldSelect = computed(() => menuState?.itemIdToSelect?.value === id.value)

watch(isFocused, (focused) => {
  if (focused) {
    innerRef.value?.scrollIntoView({ inline: 'center', block: 'center' })
  }
})

watch(shouldSelect, (shouldSelect) => {
  if (shouldSelect) {
    emit('select')
    menuState?.resetSelectedItem()
  }
})

__expose({ innerRef })

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("li", {
    id: id.value,
    ref_key: "innerRef",
    ref: innerRef,
    class: _normalizeClass(["focus:bg-active-secondary hover:bg-active-secondary mx-1 mb-0.5 flex h-8 items-center rounded-sm outline-none last:mb-0", [
      _unref(MENU_ITEM_CLASS),
      {
        'bg-active-secondary': (!_ctx.checkbox && _ctx.selected) || isFocused.value,
        'h-auto': _ctx.autoHeight,
        'cursor-not-allowed bg-transparent text-gray-50 hover:bg-transparent': _ctx.disabled,
        'pointer-events-none bg-transparent hover:bg-transparent': !_ctx.selectable,
      },
    ]])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.checkbox)
          ? (_openBlock(), _createBlock(UiCheckbox, {
              key: 0,
              checked: _ctx.selected,
              indeterminate: _ctx.indeterminate,
              class: "w-full px-2",
              disabled: _ctx.disabled,
              tabindex: "-1",
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('check')))
            }, {
              default: _withCtx(() => [
                _createVNode(UiMenuItemContent, {
                  "data-testid": "dropdown-item-label",
                  theme: _ctx.theme
                }, _createSlots({
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _: 2 /* DYNAMIC */
                }, [
                  (_ctx.$slots.icon)
                    ? {
                        name: "icon",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "icon")
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["theme"])
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["checked", "indeterminate", "disabled"]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: _normalizeClass(["flex grow items-center gap-1 overflow-hidden text-left text-sm font-medium outline-none", [_ctx.$slots['option-icon'] ? 'px-2' : 'px-3', { 'cursor-not-allowed': _ctx.disabled }]]),
              type: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', $event)))
            }, [
              _createVNode(UiMenuItemContent, {
                disabled: _ctx.disabled,
                "data-testid": "dropdown-item-label",
                theme: _ctx.theme
              }, _createSlots({
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 2 /* DYNAMIC */
              }, [
                (_ctx.$slots.icon)
                  ? {
                      name: "icon",
                      fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "icon")
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "theme"]),
              (_ctx.selected)
                ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                    key: 0,
                    class: "icon-m text-active-primary shrink-0"
                  }))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
      ])
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1)), [
    [_directive_tippy, {
      content: _ctx.tooltipContent,
      placement: 'top',
      offset: [0, 16],
    }]
  ])
}
}

})