import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed, onBeforeUnmount } from 'vue'
import { Tippy } from 'vue-tippy'
import { popperOptions } from './index'


export default /*@__PURE__*/_defineComponent({
  __name: 'VueTippy',
  props: {
    appendTo: {},
    arrow: { type: Boolean, default: true }
  },
  emits: ["show", "hide"],
  setup(__props: any, { expose: __expose }) {

const props = __props



const tippyRef = ref()

const tip = computed(() => tippyRef.value?.tippy)

const hide = computed(() => tippyRef.value?.hide)

const state = computed(() => tippyRef.value?.state)

const appendTo = computed(() => props.appendTo || document.body)

__expose({ tip, hide, state })

onBeforeUnmount(() => tip.value?.destroy())

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Tippy), _mergeProps({
    ref_key: "tippyRef",
    ref: tippyRef
  }, _ctx.$attrs, {
    "append-to": appendTo.value,
    arrow: _ctx.arrow,
    "popper-options": _unref(popperOptions),
    onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide'))),
    onShow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show')))
  }), {
    default: _withCtx(({ state: tippyState }) => [
      _renderSlot(_ctx.$slots, "trigger", { state: tippyState })
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["append-to", "arrow", "popper-options"]))
}
}

})