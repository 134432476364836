import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { ref: "root" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs font-medium text-gray-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_loader = _resolveComponent("content-loader")!
  const _component_bar_chart2_icon = _resolveComponent("bar-chart2-icon")!
  const _component_message_square_icon = _resolveComponent("message-square-icon")!
  const _component_pin_icon = _resolveComponent("pin-icon")!
  const _component_support_desk_icon = _resolveComponent("support-desk-icon")!
  const _directive_sanitize = _resolveDirective("sanitize")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style.loaderContainer)
        }, [
          _createVNode(_component_content_loader, {
            height: 50,
            width: 250,
            speed: 2,
            "primary-color": "var(--gray-30)",
            "secondary-color": "#F2F4F8"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("rect", {
                x: "0",
                y: "2",
                ry: "5",
                width: "230",
                height: "14",
                rx: "5"
              }, null, -1 /* HOISTED */),
              _createElementVNode("rect", {
                x: "0",
                y: "27",
                ry: "5",
                width: "70.56",
                height: "12",
                rx: "5"
              }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          target: "_blank",
          rel: "noreferrer noopener",
          "data-testid": "pin-ref-link",
          href: _ctx.previewLink,
          class: _normalizeClass(_ctx.$style.container),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)), ["stop"]))
        }, [
          (_ctx.preview.dashboard || _ctx.preview.csatDashboard || _ctx.preview.disputesDashboard)
            ? (_openBlock(), _createBlock(_component_bar_chart2_icon, {
                key: 0,
                class: _normalizeClass(_ctx.$style.dashboardIcon)
              }, null, 8 /* PROPS */, ["class"]))
            : (_ctx.preview.conversation)
              ? (_openBlock(), _createBlock(_component_message_square_icon, {
                  key: 1,
                  class: _normalizeClass(_ctx.$style.conversationsIcon)
                }, null, 8 /* PROPS */, ["class"]))
              : (_ctx.preview.manual)
                ? (_openBlock(), _createBlock(_component_pin_icon, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style.conversationsIcon)
                  }, null, 8 /* PROPS */, ["class"]))
                : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            "data-testid": "pinned-reference-preview",
            class: _normalizeClass(_ctx.$style.content)
          }, [
            _withDirectives(_createElementVNode("div", {
              "data-testid": "pin-title",
              class: _normalizeClass(["text-sm", _ctx.deleted ? _ctx.$style.deletedTitle : _ctx.$style.title])
            }, null, 2 /* CLASS */), [
              [_directive_sanitize, _ctx.title]
            ]),
            (_ctx.preview.conversation || _ctx.preview.manual || _ctx.createdBy)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.secondRow)
                }, [
                  _createElementVNode("div", null, [
                    (_ctx.preview.conversation)
                      ? (_openBlock(), _createBlock(_component_support_desk_icon, {
                          key: 0,
                          source: _ctx.preview.conversation.sourceType
                        }, null, 8 /* PROPS */, ["source"]))
                      : _createCommentVNode("v-if", true),
                    ((_ctx.preview.conversation || _ctx.preview.manual) && _ctx.date)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: _normalizeClass(["text-gray-60 text-xs font-medium", _ctx.$style.date])
                        }, _toDisplayString(_ctx.date), 3 /* TEXT, CLASS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  (_ctx.createdBy)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('coaching.sessions.thread.actions.created_by', [_ctx.createdBy])), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ], 10 /* CLASS, PROPS */, _hoisted_2))
  ], 512 /* NEED_PATCH */))
}