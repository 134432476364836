import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["role", "aria-label"]


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTabs',
  props: {
    role: { default: 'group' },
    ariaLabel: { default: undefined }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex items-center gap-2",
    role: _ctx.role,
    "aria-label": _ctx.ariaLabel
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8 /* PROPS */, _hoisted_1))
}
}

})