import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-testid", "onClick", "onKeydown"]

import { ref } from 'vue'
import { ChevronDownIcon } from '../../../icons'

import { UiMenuSimpleTriggerTheme } from '../types'
import UiMenuBaseTrigger from './UiMenuBaseTrigger.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuSimpleTrigger',
  props: {
    active: { type: Boolean },
    theme: { default: UiMenuSimpleTriggerTheme.Default },
    iconSize: { default: 'md' },
    dataTestid: { default: '' }
  },
  setup(__props: any) {



const buttonRef = ref<HTMLButtonElement | null>(null)

const handleFocus = () => {
  buttonRef.value?.focus()
}

const iconSizeClasses = {
  sm: 'icon-s',
  md: 'icon-m',
}

const textClassesByTheme = {
  [UiMenuSimpleTriggerTheme.Default]: 'text-gray-70 hover:text-gray-90',
  [UiMenuSimpleTriggerTheme.Light]: 'text-gray-60',
  [UiMenuSimpleTriggerTheme.Blue]: 'text-active-primary',
}

const iconClassesByTheme = {
  [UiMenuSimpleTriggerTheme.Default]: 'text-gray-70',
  [UiMenuSimpleTriggerTheme.Light]: 'text-gray-60',
  [UiMenuSimpleTriggerTheme.Blue]: 'text-active-primary',
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuBaseTrigger, {
    class: "overflow-hidden",
    onFocus: handleFocus
  }, {
    default: _withCtx(({ openMenu }) => [
      _createElementVNode("button", {
        ref_key: "buttonRef",
        ref: buttonRef,
        type: "button",
        class: _normalizeClass(["flex items-center overflow-hidden", _ctx.$attrs.class]),
        "data-testid": _ctx.dataTestid,
        onClick: openMenu,
        onKeydown: _withKeys(openMenu, ["enter"])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([textClassesByTheme[_ctx.theme], "truncate"])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */),
        _createVNode(_unref(ChevronDownIcon), {
          class: _normalizeClass(["ml-1 flex shrink-0 duration-200 ease-out", [iconSizeClasses[_ctx.iconSize], iconClassesByTheme[_ctx.theme], _ctx.active && 'rotate-180 transform']])
        }, null, 8 /* PROPS */, ["class"])
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})