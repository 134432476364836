import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTriggerButton',
  props: {
    title: {}
  },
  setup(__props: any) {

// This component is deprecated and will be removed in the future
// Please use UiMenuFilterTrigger instead


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: [
      'text-gray-80 bg-gray-20 flex h-6 w-max items-center gap-2 rounded px-2 text-xs font-medium duration-200 ease-out',
      'hover:bg-gray-30 focus:bg-active-secondary aria-expanded:bg-active-secondary [&>svg]:text-gray-60 [&>svg]:icon-s [&>svg]:duration-200 [&>svg]:ease-out',
    ],
    type: "button"
  }), [
    _renderSlot(_ctx.$slots, "icon"),
    _createTextVNode(" " + _toDisplayString(_ctx.title), 1 /* TEXT */)
  ], 16 /* FULL_PROPS */))
}
}

})