import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex shrink-0 duration-200 ease-out empty:hidden" }
const _hoisted_2 = { class: "flex gap-1 empty:hidden" }

import { ref } from 'vue'
import { ChevronDownIcon, XIcon, PlusIcon } from '../../../icons'
import { UiMenuTriggerTheme } from '../types'
import UiMenuBaseTrigger from './UiMenuBaseTrigger.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuFilterTrigger',
  props: {
    active: { type: Boolean },
    disabled: { type: Boolean },
    expanded: { type: Boolean },
    canRemove: { type: Boolean },
    canAdd: { type: Boolean },
    theme: { default: UiMenuTriggerTheme.Default },
    size: { default: 'lg' },
    showChevron: { type: Boolean, default: true },
    dataTestid: { default: 'trigger-content' },
    fullWidth: { type: Boolean, default: false },
    tooltipContent: { default: '' },
    error: { type: Boolean },
    removeTooltipContent: { default: '' },
    disableConditions: { type: Boolean, default: false }
  },
  emits: ["remove", "add"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const sizeClassesMap = {
  sm: 'h-6 px-2',
  md: 'h-7 px-2',
  'md-wide': 'h-7 px-3',
  lg: 'h-8 px-3',
}

const themeClassesMap = {
  [UiMenuTriggerTheme.White]: 'hover:bg-gray-10 focus:bg-gray-10 bg-white text-gray-70',
  [UiMenuTriggerTheme.WhiteBordered]: 'hover:bg-gray-10 focus:bg-gray-10 bg-white border border-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Light]:
    'hover:bg-gray-10 focus:bg-gray-10 bg-gray-10 border border-gray-30 focus:border-active-primary focus:shadow-input-focus text-gray-70',
  [UiMenuTriggerTheme.Transparent]: 'hover:bg-gray-20 focus:bg-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Default]: 'bg-gray-20 hover:bg-gray-30 focus:bg-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Blue]: 'bg-gray-20 hover:bg-gray-30 focus:bg-gray-30 text-gray-70',
  [UiMenuTriggerTheme.BlueMultiButton]: 'bg-gray-20 hover:bg-gray-30 focus:bg-gray-30 text-gray-70 !cursor-default',
}

const activeClassesMap = {
  [UiMenuTriggerTheme.White]: 'hover:bg-gray-10 focus:bg-gray-10 bg-white text-gray-70',
  [UiMenuTriggerTheme.WhiteBordered]: 'hover:bg-gray-10 focus:bg-gray-10 bg-white border border-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Light]:
    'hover:bg-gray-10 focus:bg-gray-10 bg-gray-10 border border-gray-30 focus:border-active-primary focus:shadow-input-focus text-gray-70',
  [UiMenuTriggerTheme.Transparent]: 'hover:bg-gray-20 focus:bg-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Default]: 'bg-gray-20 hover:bg-gray-30 focus:bg-gray-30 text-gray-70',
  [UiMenuTriggerTheme.Blue]:
    'bg-active-secondary hover:bg-active-secondary focus:bg-active-secondary text-active-primary',
  [UiMenuTriggerTheme.BlueMultiButton]:
    'bg-active-secondary hover:bg-active-secondary focus:bg-active-secondary text-active-primary !cursor-default',
}

const buttonExpandedColorClassesMap = {
  [UiMenuTriggerTheme.White]: 'aria-expanded:!bg-active-secondary',
  [UiMenuTriggerTheme.WhiteBordered]: 'aria-expanded:!bg-gray-10',
  [UiMenuTriggerTheme.Light]: 'aria-expanded:!bg-gray-10',
  [UiMenuTriggerTheme.Transparent]: 'aria-expanded:!bg-gray-10',
  [UiMenuTriggerTheme.Default]: 'aria-expanded:!bg-gray-10',
  [UiMenuTriggerTheme.Blue]: 'aria-expanded:!bg-gray-10',
  [UiMenuTriggerTheme.BlueMultiButton]: 'aria-expanded:!bg-gray-10',
}

const iconClassesMap = {
  [UiMenuTriggerTheme.White]: 'bg-white hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.WhiteBordered]: 'bg-white hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.Light]: 'bg-white hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.Transparent]: 'bg-white hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.Default]: 'bg-white hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.Blue]: 'hover:bg-gray-10 focus:bg-gray-10 text-gray-60',
  [UiMenuTriggerTheme.BlueMultiButton]: 'hover:bg-white hover:text-zblue-60 focus:bg-gray-10 text-gray-60',
}

const disabledSlotsMap = {
  [UiMenuTriggerTheme.BlueMultiButton]: 'px-1 h-6 -m-1 text-gray-80',
}

const hoverableSlotsMap = {
  [UiMenuTriggerTheme.BlueMultiButton]:
    'hover:text-active-primary hover:bg-gray-20 hover:rounded-sm px-1 h-6 -m-1 text-gray-80',
}

const activeHoverableSlotsMap = {
  [UiMenuTriggerTheme.BlueMultiButton]: 'hover:bg-active-secondary-hover hover:rounded-sm px-1 h-6 -m-1',
}

const labelSlotMap = {
  [UiMenuTriggerTheme.BlueMultiButton]: 'text-gray-70',
}

const buttonRef = ref<HTMLButtonElement | null>(null)

const handleFocus = () => {
  buttonRef.value?.focus()
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(UiMenuBaseTrigger, {
    disabled: _ctx.disabled,
    expanded: _ctx.expanded,
    class: "overflow-hidden",
    onFocus: handleFocus
  }, {
    default: _withCtx(({ openMenu }) => [
      _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.theme === _unref(UiMenuTriggerTheme).BlueMultiButton ? 'div' : 'button'), {
        ref_key: "buttonRef",
        ref: buttonRef,
        type: _ctx.theme === _unref(UiMenuTriggerTheme).BlueMultiButton ? undefined : 'button',
        class: _normalizeClass(["flex cursor-pointer items-center gap-2 truncate rounded-md duration-200 ease-out", [
          _ctx.active ? activeClassesMap[_ctx.theme] : themeClassesMap[_ctx.theme],
          buttonExpandedColorClassesMap[_ctx.theme],
          sizeClassesMap[_ctx.size],
          _ctx.fullWidth && 'w-full',
          _ctx.disabled && 'bg-gray-20 hover:bg-gray-20 text-gray-60 cursor-not-allowed border-0',
          _ctx.error && 'bg-red-10 border-red-40 hover:bg-red-10 focus:bg-red-10 border',
        ]]),
        tabindex: "0",
        disabled: _ctx.disabled,
        "data-testid": _ctx.dataTestid,
        onClick: openMenu,
        onKeydown: _withKeys(openMenu, ["enter"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "icon")
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["whitespace-nowrap text-xs font-medium duration-200 ease-out empty:hidden", labelSlotMap[_ctx.theme]])
          }, [
            _renderSlot(_ctx.$slots, "label")
          ], 2 /* CLASS */),
          _createElementVNode("span", {
            class: _normalizeClass(["flex shrink-0 text-xs font-medium empty:hidden", 
            _ctx.disableConditions
              ? disabledSlotsMap[_ctx.theme]
              : _ctx.active
                ? activeHoverableSlotsMap[_ctx.theme]
                : hoverableSlotsMap[_ctx.theme]
          ])
          }, [
            _renderSlot(_ctx.$slots, "condition")
          ], 2 /* CLASS */),
          _createElementVNode("span", {
            class: _normalizeClass(["flex truncate text-xs font-medium empty:hidden", _ctx.active ? activeHoverableSlotsMap[_ctx.theme] : hoverableSlotsMap[_ctx.theme]])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2 /* CLASS */),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.canRemove)
              ? _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: 0,
                  "data-testid": "filter-trigger-remove-button",
                  class: _normalizeClass(["ml-auto flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full", iconClassesMap[_ctx.theme]]),
                  role: "button",
                  tabindex: "0",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('remove'))),
                  onKeyup: [
                    _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (emit('remove')), ["exact"]), ["enter"])),
                    _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (emit('remove')), ["exact"]), ["space"]))
                  ]
                }, [
                  _createVNode(_unref(XIcon), { class: "icon-s" })
                ], 34 /* CLASS, NEED_HYDRATION */)), [
                  [_directive_tippy, { content: _ctx.removeTooltipContent }]
                ])
              : _createCommentVNode("v-if", true),
            (_ctx.canAdd)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  role: "button",
                  tabindex: "0",
                  class: _normalizeClass(["bg-active-secondary flex h-4 w-4 cursor-pointer items-center justify-center rounded-full", iconClassesMap[_ctx.theme]]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('add'))),
                  onKeyup: [
                    _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (emit('add')), ["exact"]), ["enter"])),
                    _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (emit('add')), ["exact"]), ["space"]))
                  ]
                }, [
                  _createVNode(_unref(PlusIcon), { class: "icon-s" })
                ], 34 /* CLASS, NEED_HYDRATION */))
              : _createCommentVNode("v-if", true)
          ]),
          (_ctx.showChevron)
            ? (_openBlock(), _createBlock(_unref(ChevronDownIcon), {
                key: 0,
                class: "icon-m text-gray-60 ml-auto flex shrink-0 duration-200 ease-out"
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1064 /* PROPS, NEED_HYDRATION, DYNAMIC_SLOTS */, ["type", "class", "disabled", "data-testid", "onClick", "onKeydown"])), [
        [_directive_tippy, { content: _ctx.tooltipContent }]
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["disabled", "expanded"]))
}
}

})