import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["disabled", "value", "placeholder"]

import { computed, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'UiInput',
  props: {
    modelValue: { default: '' },
    error: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: { default: '' },
    shrinkablePlaceholder: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit



const placeholderElement = ref<HTMLElement | null>(null)

const input = ref<HTMLInputElement | null>(null)

const handleInput = (event: Event) => {
  if (event.target instanceof HTMLInputElement) emit('update:modelValue', event.target.value, event)
}

const showPlaceholderElement = computed(() => props.placeholder && !props.shrinkablePlaceholder)

const inputStyle = computed(() => {
  if (showPlaceholderElement.value) {
    const placeholderWidth = placeholderElement.value?.clientWidth
    return { minWidth: `calc(${placeholderWidth}px + 32px)` } // 32px is the right-padding for input
  }
  return {}
})

const focusInput = () => input.value?.focus()

__expose({ focus: focusInput })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", _mergeProps({
      ref_key: "input",
      ref: input,
      "data-testid": "input-field",
      class: ["read-only:text-gray-60 read-only:border-gray-20 read-only:bg-gray-20 disabled:border-gray-20 disabled:bg-gray-20 disabled:text-gray-60 text-ellipsis px-[11px] leading-6 read-only:cursor-default disabled:cursor-not-allowed", [
      _ctx.$style.input,
      { [_ctx.$style.error]: _ctx.error, 'bg-white': _ctx.light },
      _ctx.small ? 'py-[3px] text-xs' : 'py-[7px] text-sm',
    ]],
      style: inputStyle.value
    }, _ctx.$attrs, {
      disabled: _ctx.disabled,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onInput: handleInput
    }), null, 16 /* FULL_PROPS */, _hoisted_1),
    (showPlaceholderElement.value)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          ref_key: "placeholderElement",
          ref: placeholderElement,
          class: "invisible absolute whitespace-nowrap"
        }, _toDisplayString(_ctx.placeholder), 513 /* TEXT, NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})