import { computed, watch, reactive, type ComputedRef } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { orderBy } from 'lodash-es'

import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'
import { isRated, getUngroupedCategoryGroup } from '@/modules/shared/Review/utils'
import type { Feedback } from '@/modules/shared/TicketContent/types/feedback'
import { getLoadingRevieweeOption } from '@/modules/shared/TicketContent/utils/defaultReviewee'
import { getFeedback, getInternalCommentTags, getAutoQaConversationReviews } from '@/modules/shared/TicketContent/api'
import { getCalibrationFeedback } from '@/modules/shared/TicketContent/api/calibration'
import type { TicketData, ExternalCommentExt } from '@/modules/conversations/types'
import useDispute from '@/modules/shared/TicketContent/composables/useDispute'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import timeTracking from '@/modules/time-tracking/timeTracking'
import type { StopParams } from '@/modules/time-tracking/types'
import { DisplayType } from '@/data/scales'
import { searchUsers } from '@/modules/dashboard/api'
import { getTicketSurveys } from '@/modules/shared/TicketContent/components/csat/api'
import { centrifuge, newSubscription } from '@/utils/push'
import useFeedbackForm from '@/modules/shared/Review/composables/useFeedbackForm'
import { type State } from './types'
import useLegacyScorecards from './useLegacyScorecards'
import useAdvancedScorecards from './useAdvancedScorecards'

export * from './types'

export const defaultState: State = {
  isFooterCollapsed: true,
  selectedUser: getLoadingRevieweeOption(),
  settings: {
    unbiasedGrading: false,
    threshold: 50,
    selfReviews: false,
    internalTagsLocked: true,
  },
  metadata: {
    sort: undefined,
    reviewedViaVisualFilters: false,
    translatedConversation: false,
  },
  workspaceUsers: [],
  workspaceUsersLoading: false,
  feedbackList: [],
  feedbackScore: undefined,
  autoQaFeedbackScore: undefined,
  internalTags: [],
  loadingFeedback: false,
  defaultReviewee: undefined,
  assignmentRevieweeIds: [],
  replacingConversation: false,
  removingConversation: false,
  ticket: undefined,
  ticketContentNotFound: false,
  autoQaReviews: undefined,
  surveys: [],
  usersViewing: new Map(),
}

export default createSharedComposable(() => {
  const { state: disputeState, resetDispute, loadDisputes } = useDispute()
  const { connectionId, conversationId, transcriptionMessageId, transcriptionId, messageId, calibrationSessionId } =
    useTicketRouteParams()
  const {
    state: legacyScorecardsState,
    loadScorecards: loadLegacyScorecards,
    setScorecard: setLegacyScorecard,
    setup: setupLegacyScorecards,
    clearSelectedScorecard: clearLegacyScorecard,
    activeScorecardCategories: activeLegacyScorecardCategories,
    categoryOptions: legacyCategoryOptions,
    resetScorecards: resetLegacyScorecards,
    isDynamicScorecard: isDynamicLegacyScorecard,
    selectedScorecardName: legacySelectedScorecardName,
    categoryGroups: legacyCategoryGroups,
    setDraftedScorecard: setDraftedLegacyScorecard,
    generateScorecardStorageKey: generateLegacyScorecardStorageKey,
  } = useLegacyScorecards()
  const {
    state: advancedScorecardsState,
    loadScorecards: loadAdvancedScorecards,
    setScorecard: setAdvancedScorecard,
    clearSelectedScorecard: clearAdvancedScorecard,
    activeScorecardCategories: activeAdvancedScorecardCategories,
    categoryOptions: advancedCategoryOptions,
    resetScorecards: resetAdvancedScorecards,
    isDynamicScorecard: isDynamicAdvancedScorecard,
    selectedScorecardName: advancedSelectedScorecardName,
    categoryGroups: advancedCategoryGroups,
    setDraftedScorecard: setDraftedAdvancedScorecard,
    generateScorecardStorageKey: generateAdvancedScorecardStorageKey,
    categoryOptionsByScorecardId: advancedCategoryOptionsByScorecardId,
    categoryGroupsByScorecardId: advancedCategoryGroupsByScorecardId,
  } = useAdvancedScorecards()

  const { resetFormState: resetFeedbackFormState } = useFeedbackForm()

  const generateScorecardStorageKey = (workspaceId: number) =>
    isAdvancedScorecards.value
      ? generateAdvancedScorecardStorageKey(workspaceId)
      : generateLegacyScorecardStorageKey(workspaceId)

  const isAdvancedScorecards = computed(() => session.features.advancedScorecardsDev)
  const scorecardState = computed(() => (isAdvancedScorecards.value ? advancedScorecardsState : legacyScorecardsState))

  const state = reactive(defaultState)
  const sectionStates = reactive({
    reviews: false,
    insights: false,
    surveys: false,
    helpdesk: false,
  })

  const displayAsEmoji = computed(() => session.account.ratingCategory.displayType === DisplayType.Emotes)

  const validReviews = computed(() =>
    state.feedbackList.filter(
      (feedback) => feedback.ratings.length && !feedback.deleted && feedback.ratings.some(isRated),
    ),
  )

  const activeScorecardCategories = computed(() =>
    isAdvancedScorecards.value ? activeAdvancedScorecardCategories.value : activeLegacyScorecardCategories.value,
  )

  /**
   * Feedback can get filtered
   * 1. for a specific message
   * 2. that only belongs to the logged in user (agent)
   * 3. if in a unbiased grading workspace
   * 4. when calibrating reviews
   * otherwise all feedback for the conversation is displayed
   */
  const isFeedbackFiltered = computed(() => {
    const userHasPostedFeedback = state.feedbackList.some(
      (feedback) => !feedback.deleted && feedback.reviewerId === session.user.id.toString(),
    )
    return state.settings.unbiasedGrading && !userHasPostedFeedback
  })

  const visibleManualFeedback = computed(() => {
    const { feedbackList } = state

    if (isFeedbackFiltered.value) {
      const userId = session.user.id.toString()
      return feedbackList.filter(
        (f) =>
          [f.revieweeId, f.reviewerId, ...f.tags.map((tag) => tag.userId)].includes(userId) &&
          (!messageId.value || messageId.value === f.messageId),
      )
    }

    if (transcriptionMessageId.value) {
      return feedbackList.filter((f) => f.transcriptionMessageId === transcriptionMessageId.value)
    } else if (messageId.value) {
      return feedbackList.filter((f) => f.messageId === messageId.value)
    }

    return feedbackList
  })

  const visibleAutoQaReviewsFiltered = computed(() => {
    if (calibrationSessionId.value) return []
    if (transcriptionMessageId.value) return []

    const reviews = orderBy(
      Object.values(state.autoQaReviews?.reviewsPerUser ?? {})
        .flatMap((r) => r.reviews)
        .filter((f) => !!f),
      'revieweeName',
    )

    return reviews.filter((r) => r.feedback?.transcriptionId === transcriptionId.value)
  })

  const visibleAutoQaReviews = computed(() => {
    if (calibrationSessionId.value) return []
    if (isFeedbackFiltered.value || transcriptionMessageId.value || messageId.value || transcriptionId.value) return []

    return orderBy(
      Object.values(state.autoQaReviews?.reviewsPerUser ?? {})
        .flatMap((r) => r.reviews)
        .filter((f) => !!f),
      'revieweeName',
    )
  })

  const visibleAutoQaFeedback = computed(() => {
    return visibleAutoQaReviews.value
      .filter((r) => !r.feedback?.transcriptionId)
      .flatMap((r) => r.feedback)
      .filter((f) => !!f)
  })

  const visibleVoiceAutoQaFeedback = computed(() => {
    return visibleAutoQaReviews.value
      .filter((f) => f.feedback?.transcriptionId)
      .flatMap((r) => r.feedback)
      .filter((f) => !!f)
  })

  const ticketReviews = computed(() => state?.feedbackList?.filter(_feedbackIsExistingReview) ?? [])
  const reviewsByLoggedInUser = computed(() => ticketReviews.value.filter(_feedbackReviewerIsLoggedInUser))

  /**
   * Users who have received reviews (not deleted feedback that has ratings) from the logged in user.
   * Recipients should be deduced from the reviews of the currently selected target (e.g. a single message),
   * or all reviews if no message is selected.
   */
  const reviewRecipients = computed(() =>
    visibleManualFeedback.value
      .filter(_feedbackIsExistingReview)
      .filter(_feedbackReviewerIsLoggedInUser)
      .filter(_feedbackTargetsSelectedInteraction)
      .map((r) => r.revieweeId),
  )

  function _feedbackIsExistingReview(feedback: Feedback) {
    return !feedback.deleted && !!feedback.ratings?.length
  }

  function _feedbackReviewerIsLoggedInUser(feedback: Feedback) {
    return feedback.reviewerId === session.user.id.toString()
  }

  function _feedbackTargetsSelectedInteraction(feedback: Feedback) {
    if (transcriptionMessageId.value) {
      return feedback.transcriptionMessageId === transcriptionMessageId.value
    }

    if (transcriptionId.value) {
      return !feedback.transcriptionMessageId && feedback?.transcriptionId === transcriptionId.value
    }

    if (messageId.value) {
      return feedback.messageId === messageId.value
    }

    return !feedback.messageId
  }

  const reviewTarget: ComputedRef<TicketData | ExternalCommentExt | undefined> = computed(() => {
    if (!messageId.value) return state.ticket

    const message = state.ticket?.externalComments?.find((c) => c.externalId === messageId.value)
    return message || state.ticket
  })

  // Load conversation-specific or dynamic data
  watch(() => [conversationId.value, connectionId.value, calibrationSessionId.value], _loadState)

  // Persist sectionStates to user settings
  watch(sectionStates, () => {
    updateUserSetting('reviewSidebar', {
      ...session.user.settings.reviewSidebar,
      sections: sectionStates,
    })
  })

  watch(() => state.feedbackList, updateScore, { deep: true })
  watch(visibleAutoQaFeedback, updateAutoQaFeedbackScore, { deep: true })

  const presenceChannel = computed(() =>
    conversationId.value
      ? `presence:view_conversation-${session.workspace?.id}-${connectionId.value}-${conversationId.value}`
      : '',
  )

  watch(
    presenceChannel,
    (newChannel, oldChannel) => {
      if (oldChannel) stopConversationPresence(oldChannel)
      _loadConversationPresence(newChannel)
    },
    { immediate: true },
  )

  async function _loadConversationPresence(channel: string, isReviewing = false) {
    if (!channel) return

    // Account for multiple sidebar composable instances
    const existingSubscription = centrifuge.getSubscription(channel)
    if (existingSubscription) return

    const subscription = newSubscription(channel, { isReviewing })

    subscription.on('join', ({ info }) => {
      if (info.user === session.user.id.toString()) return
      state.usersViewing.set(info.user, { ...info.connInfo, ...info.chanInfo })
    })

    subscription.on('leave', ({ info }) => state.usersViewing.delete(info.user))
    subscription.subscribe()

    // Fetch initial state
    state.usersViewing.clear()
    try {
      const { clients } = await subscription.presence()
      const otherClients = Object.values(clients).filter((c) => c.user !== session.user.id.toString())
      state.usersViewing = new Map(otherClients.map((c) => [c.user, { ...c.connInfo, ...c.chanInfo }]))
    } catch (e) {
      if (e instanceof Object && 'code' in e && e.code == 7) return
      throw e
    }
  }

  function stopConversationPresence(channel?: string) {
    const existingSubscription = centrifuge.getSubscription(channel || presenceChannel.value)
    if (!existingSubscription) return
    existingSubscription.removeAllListeners()
    centrifuge.removeSubscription(existingSubscription)
  }

  function setConversationReviewing(isReviewing: boolean) {
    stopConversationPresence(presenceChannel.value)
    _loadConversationPresence(presenceChannel.value, isReviewing)
  }

  // Load data once that does not change often
  function setup() {
    _resetState()
    if (!state.workspaceUsers.length) initWorkspaceUserList()

    const settings = session.workspace.settings
    state.settings.internalTagsLocked = settings.tagsLocked
    state.settings.threshold = settings.threshold
    state.settings.unbiasedGrading =
      hasAccountRole('USER') && hasWorkspaceRole('REVIEWER', 'AGENT') && settings.calibrated
    state.settings.selfReviews = settings.selfReviews
    state.defaultReviewee = undefined

    setupLegacyScorecards()
    loadInternalTags()
    _loadState()

    if (session.user.settings.reviewSidebar && 'sections' in session.user.settings.reviewSidebar) {
      Object.assign(sectionStates, session.user.settings.reviewSidebar.sections)
    }
  }

  function resetScorecards() {
    resetLegacyScorecards()
    resetAdvancedScorecards()
  }

  function _resetState() {
    resetScorecards()
    resetFeedbackFormState()
    state.feedbackList = []
    state.autoQaReviews = undefined
    state.surveys = []
    disputeState.disputesList = []
  }

  function _loadState() {
    _resetState()
    loadScorecards()
    loadFeedback()
    _loadTicketSurveys()
    _loadAutoQa()
    loadInternalTags()

    if (session.features.disputes) {
      resetDispute()
      if (connectionId.value && conversationId.value) loadDisputes(connectionId.value, conversationId.value)
    }
  }

  function resetMetadata() {
    state.metadata.sort = undefined
    state.metadata.reviewedViaVisualFilters = false
    state.metadata.translatedConversation = false
  }

  function resetUsersList() {
    state.workspaceUsers = []
  }

  async function loadScorecards() {
    await loadLegacyScorecards()
    await loadAdvancedScorecards()
  }

  async function loadInternalTags() {
    const { data } = await getInternalCommentTags()
    state.internalTags = data
  }

  async function loadFeedback(language?: string) {
    if (!connectionId.value || !conversationId.value) return

    state.loadingFeedback = true

    const settingEnabled = session.workspace.settings.calibrationEnabled
    const isCalibrationEnabled = session.features.calibrationPro && settingEnabled

    let conversationFeedback: Feedback[] = []

    if (calibrationSessionId.value && isCalibrationEnabled) {
      const { feedback } = await getCalibrationFeedback(
        conversationId.value,
        connectionId.value,
        calibrationSessionId.value,
      )
      conversationFeedback = feedback
    } else {
      const { feedback } = await getFeedback({
        connectionId: connectionId.value,
        conversationId: conversationId.value,
        language,
      })
      conversationFeedback = feedback
    }

    if (!session.features.advancedScorecardsDev) {
      conversationFeedback = conversationFeedback.filter((f) => !f.isAdvancedScorecard)
    }

    state.feedbackList = conversationFeedback
    state.loadingFeedback = false

    return conversationFeedback
  }

  function updateScore() {
    const scores = validReviews.value.map((feedback) => feedback.score)
    const averageScore = scores.reduce((sum, score) => sum + score, 0) / scores.length
    state.feedbackScore = averageScore
  }

  function updateAutoQaFeedbackScore() {
    state.autoQaFeedbackScore = visibleAutoQaFeedback.value.length
      ? visibleAutoQaFeedback.value.reduce((sum, f) => sum + f?.score, 0) / visibleAutoQaFeedback.value.length
      : 0
  }

  async function _loadTicketSurveys() {
    if (!connectionId.value || !conversationId.value) return

    const surveys = await getTicketSurveys(conversationId.value, connectionId.value)

    state.surveys = surveys.types.reduce((result, survey) => {
      if (survey.responses.length) {
        survey.responses.forEach((response) => {
          result.push({
            surveyName: survey.name,
            ...response,
          })
        })
      }

      return result
    }, [])

    return surveys
  }

  async function _loadAutoQa() {
    if (!session.features.autoQa || !connectionId.value || !conversationId.value) return

    state.autoQaReviews = await getAutoQaConversationReviews({
      connectionId: connectionId.value.toString(),
      conversationId: conversationId.value,
    })
  }

  async function stopAndSetReviewTime(params: StopParams) {
    const id = params.reviewId ? String(params.reviewId) : params.calibrationReviewId
    const review = state.feedbackList.find((f) => f.id === id)
    if (!review) return

    if (!params.calibrationReviewId) review.reviewTime = 'loading'

    const { reviewTime } = await timeTracking.stop(params)
    if (reviewTime && !params.calibrationReviewId) review.reviewTime = reviewTime
  }

  async function initWorkspaceUserList() {
    state.workspaceUsersLoading = true
    const { users } = await searchUsers({ workspaceIds: [session.workspace.id], limit: 20000 })
    state.workspaceUsers = users
    state.workspaceUsersLoading = false
  }

  const clearScorecard = () => {
    clearLegacyScorecard()
    clearAdvancedScorecard()
  }

  const hasSelectedScorecard = computed(
    () => scorecardState.value.selectedScorecard && Object.keys(scorecardState.value.selectedScorecard).length,
  )

  const selectedScorecardId = computed(() => scorecardState.value.selectedScorecard?.id)

  const selectedScorecardName = computed(() =>
    isAdvancedScorecards.value ? advancedSelectedScorecardName.value : legacySelectedScorecardName.value,
  )

  const isDynamicScorecard = computed(() =>
    isAdvancedScorecards.value ? isDynamicAdvancedScorecard.value : isDynamicLegacyScorecard.value,
  )

  const categoryOptions = computed(() => {
    return isAdvancedScorecards.value ? advancedCategoryOptions.value : legacyCategoryOptions.value
  })

  const scorecardCount = computed(() => scorecardState.value.scorecards.length)

  const isEmptyDynamicScorecard = computed(() => isDynamicScorecard.value && !activeScorecardCategories.value.length)

  const categoryGroups = computed(() => {
    return isAdvancedScorecards.value ? advancedCategoryGroups.value : legacyCategoryGroups.value
  })

  const setDraftedScorecard = (scorecardId: string) => {
    if (isAdvancedScorecards.value) {
      setDraftedAdvancedScorecard(scorecardId)
    } else {
      setDraftedLegacyScorecard(scorecardId)
    }
  }

  const getCategoryOptions = (legacy = true) => {
    return legacy ? legacyCategoryOptions.value : advancedCategoryOptions.value
  }

  const getCategoryOptionsByScorecardId = (scorecardId: string, legacy = true) => {
    return legacy ? legacyCategoryOptions.value : advancedCategoryOptionsByScorecardId.value[scorecardId] || {}
  }

  const getUngroupedCategoryOptionsByScorecardId = (scorecardId: string, legacy = true) => {
    const categoryOptions = getCategoryOptionsByScorecardId(scorecardId, legacy)

    return Object.values(categoryOptions).filter((c) => !c.groupId)
  }

  const getCategoryGroupsByScorecardId = (scorecardId: string, legacy = true) => {
    return legacy ? legacyCategoryGroups.value : advancedCategoryGroupsByScorecardId.value[scorecardId] || []
  }

  const getCategoryGroupsWithUngroupedByScorecardId = (scorecardId: string, legacy = true) => {
    const ungroupedCategories = getUngroupedCategoryOptionsByScorecardId(scorecardId, legacy)

    return [getUngroupedCategoryGroup(ungroupedCategories), ...getCategoryGroupsByScorecardId(scorecardId, legacy)]
  }

  const getCategoryByCategoryIdAndScorecardId = (categoryId: string, scorecardId: string) => {
    // only for advanced scorecards as legacy scorecards have all categories in one array
    const categoryOptions = getCategoryOptionsByScorecardId(scorecardId, false)
    return categoryOptions[categoryId]
  }

  return {
    generateScorecardStorageKey,
    state,
    advancedScorecardsState,
    legacyScorecardsState,
    sectionStates,
    displayAsEmoji,
    validReviews,
    setScorecard: setLegacyScorecard,
    activeScorecardCategories,
    emptyDynamicScorecard: isEmptyDynamicScorecard,
    categoryOptions,
    legacyCategoryOptions,
    advancedCategoryOptions,
    categoryGroups,
    legacyCategoryGroups,
    isFeedbackFiltered,
    visibleManualFeedback,
    visibleAutoQaReviews,
    visibleAutoQaFeedback,
    visibleVoiceAutoQaFeedback,
    visibleAutoQaReviewsFiltered,
    ticketReviews,
    reviewsByLoggedInUser,
    reviewRecipients,
    reviewTarget,
    setup,
    loadInternalTags,
    loadScorecards,
    loadFeedback,
    resetMetadata,
    resetUsersList,
    stopAndSetReviewTime,
    setDraftedScorecard,
    clearScorecard,
    hasSelectedScorecard,
    selectedScorecardId,
    selectedScorecardName,
    isDynamicScorecard,
    scorecardCount,
    stopConversationPresence,
    setConversationReviewing,
    // advanced scorecards
    setAdvancedScorecard,
    getCategoryOptions,
    getCategoryOptionsByScorecardId,
    getUngroupedCategoryOptionsByScorecardId,
    getCategoryGroupsByScorecardId,
    getCategoryByCategoryIdAndScorecardId,
    getCategoryGroupsWithUngroupedByScorecardId,
    getUngroupedCategoryGroup,
    advancedCategoryOptionsByScorecardId,
    advancedCategoryGroupsByScorecardId,
  }
})
