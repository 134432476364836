import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onBeforeMount } from 'vue'
import dayjs from 'dayjs'
import { UiTag } from '@klausapp/ui-kit'
import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'


export default /*@__PURE__*/_defineComponent({
  __name: 'QFeatureBadge',
  props: {
    feature: {},
    timeLimitDays: { default: 30 }
  },
  setup(__props: any) {

const props = __props

const show = ref(false)

onBeforeMount(async () => {
  const key = `klausNewFeatureBadge.v1.${props.feature}`
  const featureBadgeExpiration = session.user.settings.featureBadgeExpiration

  if (!featureBadgeExpiration?.[key]) {
    const expirationDate = dayjs().add(props.timeLimitDays, 'd').toString()
    const newExpiration = {
      ...(session.user.settings.featureBadgeExpiration || {}),
      [key]: expirationDate,
    }

    await updateUserSetting('featureBadgeExpiration', newExpiration)
    session.user.settings.featureBadgeExpiration = newExpiration
  }

  const expirationDatePassed = dayjs().isAfter(session.user.settings.featureBadgeExpiration?.[key])
  show.value = !expirationDatePassed
})

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? (_openBlock(), _createBlock(_unref(UiTag), {
        key: 0,
        theme: "brand"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('universal.new_feature')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}
}

})