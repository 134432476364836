import { api } from '@/api'
import { uploadUnsavedImages } from '@/modules/shared/CommentEditor/plugins/useUnsavedImages'
import type {
  Thread,
  ThreadResponse,
  SuggestedUser,
  Metrics,
  Action,
  UpdatedSession,
  Categories,
  CoachingPin,
  Session,
  NewSession,
  Comment,
  ThreadParticipant,
  ActionItemsBySession,
  CoachingUser,
  ProgressGraphData,
  SessionFeedback,
  OverviewData,
} from '../types'

interface DateParams {
  fromDate?: string
  toDate?: string
}

interface Params extends DateParams {
  sort: string
  sortAsc: boolean
}

interface SuggestedUsersParams {
  fromDate?: string
  toDate?: string
  sort?: string
  sortAsc?: boolean
  page?: string
  pageSize?: string
  name?: string
  excludeConnected?: boolean
}

export interface CoachListParams {
  name?: string
  limit?: number
}
export interface SessionsParams {
  page: number
  pageSize: number
  name: string
  role: string | undefined
}

export interface CreateSessionParams {
  coachId: string
  coacheeId: string
  title: string
  scheduledAt: string
}

export const getUnreadSessionsCount = () => api.get('coaching/sessions/unread-count').json<{ count: string }>()

export const markSessionsAsRead = (sessionIds: string[]) =>
  api.post('coaching/sessions/mark-viewed', { json: { sessionIds } }).json<{ count: string }>()

export const getThread = (threadId: string) => api.get(`coaching/session-threads/${threadId}`).json<Thread>()

export const getSuggestedUsers = (params?: SuggestedUsersParams) => {
  const query = new URLSearchParams()
  if (params?.fromDate) query.append('fromDate', params.fromDate)
  if (params?.toDate) query.append('toDate', params.toDate)
  if (params?.sort) query.append('sort', params.sort)
  if (params?.sortAsc) query.append('sortAsc', params.sortAsc.toString())
  if (params?.page) query.append('page', params.page)
  if (params?.pageSize) query.append('pageSize', params.pageSize)
  if (params?.name) query.append('name', params.name)
  if (params?.excludeConnected) query.append('excludeConnected', params.excludeConnected.toString())

  return api
    .get(`coaching/suggested-users?${query}`)
    .json<{ users: SuggestedUser[]; sort: string; sortAsc: boolean; total: string }>()
}

export const getCoachList = (params: CoachListParams) => {
  const query = new URLSearchParams()
  if (params?.name) query.append('name', params.name)
  if (params?.limit) query.append('limit', params.limit.toString())

  return api.get(`coaching/coach/list?${query}`).json<{ users: CoachingUser[]; total: string }>()
}

export const getMetrics = (threadId: string, params: DateParams) => {
  const query = new URLSearchParams({
    ...(params.fromDate ? { fromDate: params.fromDate } : {}),
    ...(params.toDate ? { toDate: params.toDate } : {}),
  })
  return api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`coaching/session-threads/${threadId}/overview/metrics?${query}`)
    .json<Metrics>()
}

export const getCategories = (threadId: string, params: Params) => {
  const query = new URLSearchParams()
  if (params.fromDate) query.append('fromDate', params.fromDate)
  if (params.toDate) query.append('toDate', params.toDate)
  query.append('sort', params.sort || 'score')
  query.append('sortAsc', params.sortAsc?.toString() || 'false')
  query.append('step', 'day')

  return api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`coaching/session-threads/${threadId}/overview/categories?${query}`)
    .json<Categories>()
}

export const removeThread = (threadId: string) => api.delete(`coaching/session-threads/${threadId}`).json()

export const getFeedback = (threadId: string, params: DateParams) => {
  const query = new URLSearchParams()
  if (params.fromDate) query.append('fromDate', params.fromDate)
  if (params.toDate) query.append('toDate', params.toDate)
  return api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`coaching/session-threads/${threadId}/overview/feedback?${query}`)
    .json<{ feedback: Comment[] }>()
}

export const updateSession = async (id: string, session: Pick<Session, 'title' | 'notes' | 'scheduledAt'>) => {
  const data = api.put(`coaching/sessions/${id}`, { json: session }).json<ThreadResponse>()
  await uploadUnsavedImages(session.notes)
  return data
}

export const removeSession = (sessionId: string) => api.delete(`coaching/sessions/${sessionId}`).json()

export const updatePrivateNote = async (sessionId: string, note: string) => {
  const data = api.put(`coaching/sessions/${sessionId}/private-note`, { json: { note } }).json()
  await uploadUnsavedImages(note)
  return data
}

export const addActionItem = (sessionId: string, title: string) =>
  api
    .post(`coaching/sessions/${sessionId}/action-items`, { json: { title } })
    .json<{ actionItem: Action; session: UpdatedSession }>()

export const updateActionItem = (sessionId: string, action: Partial<Action>) =>
  api
    .put(`coaching/sessions/${sessionId}/action-items/${action.id}`, {
      json: { title: action.title, completed: action.completed },
    })
    .json<{ actionItem: Action; session: UpdatedSession }>()

export const removeActionItem = (sessionId: string, actionId: string) =>
  api.delete(`coaching/sessions/${sessionId}/action-items/${actionId}`).json()

export const addPin = (sessionId: string, pinId: string) =>
  api.post(`coaching/sessions/${sessionId}/pins/${pinId}`).json<{ pin: CoachingPin; session: UpdatedSession }>()

export const removePin = (sessionId: string, pinId: string) =>
  api.delete(`coaching/sessions/${sessionId}/pins/${pinId}`).json<{ session: UpdatedSession }>()

export const markAsDone = (sessionId: string, done: boolean) =>
  api.put(`coaching/sessions/${sessionId}/mark-as-done`, { json: { done } }).json<{ session: Session }>()

export const updateThreadParticipants = (threadId: string, userIds: string[]) =>
  api
    .put(`coaching/sessions/session-threads/${threadId}/visibility`, { json: { userIds } })
    .json<{ participants: ThreadParticipant[] }>()

export const removeThreadParticipant = (threadId: string, userId: string) =>
  api
    .delete(`coaching/sessions/session-threads/${threadId}/visibility/${userId}`)
    .json<{ participants: ThreadParticipant[] }>()
interface ActionItemParams {
  page: number
  pageSize: number
  relationType: string
}

export const getActionItems = (params: ActionItemParams) => {
  const query = new URLSearchParams()
  query.append('page', params.page.toString())
  query.append('pageSize', params.pageSize.toString())
  query.append('relationType', params.relationType)

  return api.get(`coaching/sessions/action-items/group/sessions?${query}`).json<ActionItemsBySession>()
}

interface ProgressParams {
  fromDate: string
  toDate: string
  workspaceIds: number[]
  userIds: number[]
  progressByCoach: boolean
  step: 'week' | 'month'
}

export const getInsightsProgress = (params: ProgressParams) => {
  const query = new URLSearchParams()
  params.userIds.forEach((userId) => query.append('userIds', userId.toString()))
  params.workspaceIds.forEach((workspaceId) => query.append('workspaceIds', workspaceId.toString()))
  query.append('fromDate', params.fromDate)
  query.append('toDate', params.toDate)
  query.append('progressByCoach', String(params.progressByCoach))
  query.append('step', params.step)
  return api.get(`coaching/sessions/dashboard/progress-after-sessions?${query}`).json<ProgressGraphData>()
}

export const getSessions = (params: SessionsParams) => {
  const query = new URLSearchParams()
  query.append('page', params.page.toString())
  query.append('pageSize', params.pageSize.toString())
  if (params.name) query.append('name', params.name)
  if (params.role) query.append('role', params.role)

  return api.get(`coaching/sessions?${query}`).json<{ sessions: SessionFeedback[]; total: string }>()
}
interface OverviewParams {
  userIds: number[]
  workspaceIds: number[]
  page?: number
  pageSize?: number
  sort?: 'total' | 'coach' | 'coachee' | 'lastScheduled'
  sortAsc?: boolean
}

export const getInsightsOverview = (params: OverviewParams) => {
  const query = new URLSearchParams()
  params.userIds.forEach((userId) => query.append('userIds', userId.toString()))
  params.workspaceIds.forEach((workspaceId) => query.append('workspaceIds', workspaceId.toString()))
  query.append('page', params.page?.toString() || '1')
  query.append('pageSize', params.pageSize?.toString() || '10')
  query.append('sort', params.sort || 'lastScheduled')
  query.append('sortAsc', params.sortAsc?.toString() || 'false')

  return api.get(`coaching/sessions/overview?${query}`).json<OverviewData>()
}

export const createSession = (params: CreateSessionParams) =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .post('coaching/sessions', { json: { ...params } })
    .json<{ session: NewSession; coach: CoachingUser; coachee: CoachingUser }>()
