import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["disabled", "aria-expanded"]
const _hoisted_2 = { class: "flex grow flex-wrap gap-2 overflow-auto" }
const _hoisted_3 = ["value", "placeholder", "onFocus"]

import { computed, ref, inject } from 'vue'
import UiTag from '../UiTag.vue'
import { type MenuState } from '../UiMenu/types'
import UiMenuBaseTrigger from '../UiMenu/triggers/UiMenuBaseTrigger.vue'

import DoubleCaretIcon from './assets/double-caret.svg'
import { type DropdownProps, type Modes } from './types'
import type useList from './composables/useList'

interface Props extends DropdownProps<T, P, L, M, V, O> {
  expanded: boolean
  selectedOptions: ReturnType<typeof useList<T, P, L, M, V, O>>['selectedOptions']['value']
  label: L
  searchQuery: string
  dropdownVisible: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownTagsTrigger',
  props: {
    expanded: { type: Boolean },
    selectedOptions: {},
    label: {},
    searchQuery: {},
    dropdownVisible: { type: Boolean },
    mode: {},
    isObject: {},
    propToCheck: {},
    options: {},
    modelValue: {},
    closeOnSelect: { type: Boolean },
    selectAllLabel: {},
    allowSingleDeselect: { type: Boolean },
    allowDeselectAll: { type: Boolean },
    maxHeight: {},
    triggerLabel: {},
    triggerPlaceholder: {},
    searchPlaceholder: {},
    noResultsText: {},
    loadingText: {},
    selectAllMode: {},
    filterResults: { type: Boolean },
    total: {},
    maxWidth: {},
    fullWidth: { type: Boolean },
    isLoading: { type: Boolean },
    reorderAfterSelect: { type: Boolean },
    hasSearch: { type: Boolean },
    itemsLabel: {},
    size: {},
    appendToDocument: { type: Boolean },
    disabled: { type: Boolean },
    excludedOptions: {},
    canRemove: { type: Boolean },
    triggerTheme: {},
    groups: {},
    placement: {},
    display: {},
    helpText: {},
    error: { type: Boolean }
  },
  emits: ["toggle", "search-change", "add-option"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

const menuState = inject<MenuState>('menuState')

const input = ref<HTMLInputElement | null>(null)
const isEmpty = computed(() => !props.selectedOptions.length)

const handleSearchChange = (event: Event) => {
  emit('search-change', (event.target as HTMLInputElement)?.value)
}

const addingDisabled = computed(() => !!menuState?.activeItemId.value)

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
    event.preventDefault()
  } else if (event.key === 'Enter' && !addingDisabled.value && props.searchQuery.trim() !== '') {
    emit('add-option', props.searchQuery)
  } else if (event.key === 'Backspace' && !props.searchQuery && props.selectedOptions.length) {
    emit('toggle', props.selectedOptions[props.selectedOptions.length - 1])
  }
}

const focusInput = () => input.value?.focus()

__expose({ focus: focusInput })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuBaseTrigger, null, {
    default: _withCtx(({ openMenu }) => [
      _createElementVNode("button", {
        "data-testid": "dropdown-tags-trigger",
        disabled: _ctx.disabled,
        type: "button",
        class: "border-gray-30 flex min-h-8 w-full items-center rounded border bg-white px-2 py-1.5",
        "aria-expanded": _ctx.expanded,
        onFocus: focusInput
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (option) => {
            return (_openBlock(), _createBlock(UiTag, {
              key: String(option[props.propToCheck]),
              capitalized: false,
              dismissible: "",
              onDismiss: ($event: any) => (_ctx.$emit('toggle', option))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option[props.label]), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onDismiss"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("input", {
            ref_key: "input",
            ref: input,
            class: "grow text-xs font-normal outline-none placeholder:text-gray-50",
            value: _ctx.searchQuery,
            placeholder: isEmpty.value ? _ctx.triggerPlaceholder : '',
            onInput: handleSearchChange,
            onKeydown: handleKeydown,
            onFocus: openMenu
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3)
        ]),
        _createVNode(_unref(DoubleCaretIcon), { class: "icon-m text-gray-60 ml-auto flex shrink-0 duration-200 ease-out" })
      ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})