import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["alt", "src"]

import { ref, computed, watch, type StyleValue } from 'vue'
import BotIcon from '@/assets/bot.svg'
import missingUserImage from '@/assets/no-user.svg?raw'
import type { StringWithSuggestions } from '@/types/util'
import { getInitials } from './initials'


export default /*@__PURE__*/_defineComponent({
  __name: 'QAvatar',
  props: {
    username: { default: '' },
    size: { default: 50 },
    src: { default: '' },
    parser: { type: Function, default: getInitials },
    background: { default: '' },
    borderColor: { default: undefined },
    noBorder: { type: Boolean },
    noShadow: { type: Boolean },
    isBot: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const imgError = ref(false)

watch(
  () => props.src,
  () => (imgError.value = false),
)

const backgroundColors = [
  'var(--brand-40)',
  'var(--brand-50)',
  'var(--blue-40)',
  'var(--blue-50)',
  'var(--pink-40)',
  'var(--pink-50)',
  'var(--orange-40)',
  'var(--orange-50)',
  'var(--yellow-40)',
  'var(--yellow-50)',
  'var(--green-40)',
  'var(--green-50)',
  'var(--teal-40)',
  'var(--teal-50)',
  'var(--purple-40)',
  'var(--purple-50)',
]

const backgroundColor = computed(
  () => props.background || randomBackgroundColor(props.username?.length || 0, backgroundColors),
)

const imageLoadedSuccessfully = computed(() => !imgError.value && !!props.src)

const showBackgroundImage = computed(() => {
  const showUserFallbackImage = !userInitials.value && !props.isBot

  return imageLoadedSuccessfully.value || showUserFallbackImage
})

const dynamicStyles = computed(() => {
  const style: StyleValue = {
    width: `${props.size}px`,
    height: `${props.size}px`,
  }
  const imgBackgroundStyle: StyleValue = {
    backgroundImage: `url('${props.src || missingUserImage}')`,
    backgroundRepeat: 'no-repeat',
    borderColor: props.borderColor === 'inherit' ? undefined : 'var(--white)',
  }
  const initialBackgroundAndFontStyle: StyleValue = {
    backgroundColor: backgroundColor.value === 'inherit' || props.isBot ? undefined : backgroundColor.value,
    fontSize: `${Math.floor(props.size / 2.5)}px`,
  }

  const backgroundAndFontStyle = showBackgroundImage.value ? imgBackgroundStyle : initialBackgroundAndFontStyle

  return {
    ...style,
    ...backgroundAndFontStyle,
  }
})

const userInitials = computed(() =>
  !imageLoadedSuccessfully.value && props.username ? props.parser(props.username, getInitials) : '',
)

function randomBackgroundColor(seed: number, colors: string[]) {
  return colors[seed % colors.length]
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": "q-avatar",
    class: _normalizeClass(["flex shrink-0 select-none items-center justify-center bg-contain bg-center text-center font-bold text-white", [
      {
        'bg-white': _ctx.background !== 'inherit' && !_ctx.isBot,
        'border-white': _ctx.borderColor !== 'inherit',
        'border-2': !_ctx.noBorder,
        'shadow-md': !_ctx.noShadow,
      },
      _ctx.isBot ? 'bg-purple-40 rounded-md' : 'rounded-full',
    ]]),
    style: _normalizeStyle(dynamicStyles.value),
    "aria-hidden": "true"
  }, [
    _createCommentVNode(" Image used to detect failure-to-load of div background image "),
    (showBackgroundImage.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: {"display":"none"},
          alt: _ctx.username,
          src: _ctx.src || _unref(missingUserImage),
          onError: _cache[0] || (_cache[0] = ($event: any) => (imgError.value = true))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
      : (_ctx.isBot)
        ? (_openBlock(), _createBlock(_unref(BotIcon), {
            key: 1,
            class: _normalizeClass(_ctx.size >= 32 ? 'icon-m' : 'icon-s')
          }, null, 8 /* PROPS */, ["class"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createTextVNode(_toDisplayString(userInitials.value), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
  ], 6 /* CLASS, STYLE */))
}
}

})