import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import { ref, useSlots, computed } from 'vue'
import useOverflowCheck from '@klausapp/shared/composables/useOverflowCheck'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTableHeaderCell',
  setup(__props) {

const delay = [400, 0]
const headerRef = ref<HTMLElement>()
const { isOverflowing } = useOverflowCheck(headerRef)
const slots = useSlots()
const slotContent = computed(() => {
  if (slots.default) {
    const nodes = slots.default({})
    return nodes.map((node) => (typeof node.children === 'string' ? node.children : '')).join('')
  }
  return ''
})

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createElementBlock("th", {
    ref_key: "headerRef",
    ref: headerRef,
    class: "text-gray-90 bg-gray-10 border-gray-30 truncate whitespace-nowrap border px-3 py-2 text-start font-semibold"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ])), [
    [_directive_tippy, {
      content: _unref(isOverflowing) ? slotContent.value : null,
      delay: delay,
    }]
  ])
}
}

})