import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-testid", "onClick", "onKeydown"]
const _hoisted_2 = { class: "flex grow items-center gap-2 leading-none" }

import { computed, ref } from 'vue'
import DoubleCaretIcon from '../../UiDropdownV2/assets/double-caret.svg'
import UiMenuBaseTrigger from './UiMenuBaseTrigger.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuSelectTrigger',
  props: {
    active: { type: Boolean },
    small: { type: Boolean },
    disabled: { type: Boolean },
    placeholder: { type: Boolean },
    dataTestid: {}
  },
  setup(__props: any) {

const props = __props

const buttonRef = ref<HTMLButtonElement | null>(null)

const textClass = computed(() => {
  if (props.disabled) return 'text-gray-60'
  if (props.placeholder) return 'text-gray-50'
  return 'text-gray-90'
})

const handleFocus = () => {
  buttonRef.value?.focus()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenuBaseTrigger, { onFocus: handleFocus }, {
    default: _withCtx(({ openMenu }) => [
      _createElementVNode("button", {
        ref_key: "buttonRef",
        ref: buttonRef,
        type: "button",
        class: _normalizeClass(["flex grow items-center gap-2 rounded border px-3 py-2 transition-colors", [
          _ctx.active &&
            'border-button-primary-outline hover:border-button-primary-outline shadow-[0_0_4_0_rgba(59,76,191,0.6)]',
          !_ctx.active && !_ctx.disabled && 'border-gray-30 hover:border-gray-40',
          _ctx.small && 'h-8 text-xs',
          !_ctx.small && 'h-10 text-sm',
          _ctx.disabled && 'bg-gray-20 border-gray-20 pointer-events-none cursor-not-allowed',
          !_ctx.disabled && 'bg-gray-10',
          textClass.value,
        ]]),
        "data-testid": _ctx.dataTestid,
        onClick: openMenu,
        onKeydown: _withKeys(openMenu, ["enter"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon"),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(_unref(DoubleCaretIcon), {
          class: _normalizeClass(["icon-m", [_ctx.disabled && 'text-gray-50', !_ctx.disabled && 'text-gray-60']])
        }, null, 8 /* PROPS */, ["class"])
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})