import config from '@/config'
import { auth0 } from '@/modules/auth/api'

const ssoRedirectMap = Object.fromEntries(
  config.authSsoRedirects.split(';').map((redir) => {
    const [id, connection] = redir.split(/:(.+)/)
    return [id, connection]
  }),
)

export const ssoEnabled = (id: string | number) => id in ssoRedirectMap

export const redirectToSso = () => {
  const url = new URL(window.location.href)
  const accountId = parseInt(url.searchParams.get('account') || '0', 10)

  const ssoConnection = ssoRedirectMap[accountId]
  if (ssoConnection) {
    auth0.loginWithRedirect({ authorizationParams: { connection: ssoConnection } })
  } else {
    url.searchParams.delete('account')
    history.replaceState(null, '', url.toString())
  }
}
