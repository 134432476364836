import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot } from "vue"

import { inject, watch, nextTick } from 'vue'
import { type MenuState } from '../types'


export default /*@__PURE__*/_defineComponent({
  ...{ inheritAttrs: false },
  __name: 'UiMenuBaseTrigger',
  emits: ["focus"],
  setup(__props, { emit: __emit }) {

const emit = __emit



const menuState = inject<MenuState>('menuState')

const openMenu = () => {
  menuState?.openMenu()
}

watch(
  () => menuState?.shouldFocusTrigger.value,
  async (shouldFocusTrigger) => {
    if (shouldFocusTrigger) {
      await nextTick()
      emit('focus')
      menuState?.resetTriggerFocus()
    }
  },
)

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", { openMenu: openMenu })
}
}

})