import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass('-mx-2 flex w-[calc(100%+16px)] flex-col gap-1')
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mr-4 truncate text-base font-medium" }

import { CheckIcon } from '@klausapp/ui-kit/icons'

import QModal from '@/components/QModal.vue'
import { session } from '@/composables/useSession'
import { useAccountList } from '@/composables/useAccountList'
import analytics from '@/utils/analytics'


export default /*@__PURE__*/_defineComponent({
  __name: 'SwitchAccountModal',
  props: {
    show: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any) {





const [accounts] = useAccountList()

const switchAccount = (id: number) => {
  if (id === session.account.id) return
  analytics.navbarEvent('Clicked navbar popup item', 'Switch account')

  const url = new URL(window.location.origin)
  url.searchParams.append('account', id.toString())
  window.location.href = url.toString()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(QModal, {
      show: _ctx.show,
      "close-button": "",
      dismissable: "",
      "extra-narrow": "",
      title: _ctx.$t('modules.application.navbar.switch_account'),
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accounts), (account) => {
            return (_openBlock(), _createElementBlock("button", {
              key: account.id,
              class: _normalizeClass([
            'text-gray-80 flex h-8 w-full items-center justify-between gap-2 rounded-md px-2 py-1',
            _unref(session).account.id === account.id
              ? 'bg-active-secondary cursor-default'
              : 'hover:bg-gray-10 cursor-pointer',
          ]),
              type: "button",
              onClick: ($event: any) => (switchAccount(account.id))
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(account.name), 1 /* TEXT */),
              (_unref(session).account.id === account.id)
                ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                    key: 0,
                    class: "text-active-primary icon-m ml-auto shrink-0"
                  }))
                : _createCommentVNode("v-if", true)
            ], 10 /* CLASS, PROPS */, _hoisted_2))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "title"]),
    (_unref(accounts).length > 1)
      ? _renderSlot(_ctx.$slots, "trigger", { key: 0 })
      : _createCommentVNode("v-if", true)
  ]))
}
}

})