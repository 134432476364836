import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPageNavButton',
  props: {
    type: { default: 'button' },
    blank: { type: Boolean },
    active: { type: Boolean },
    disabled: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type === 'link' ? 'a' : 'button'), {
    type: _ctx.type === 'link' ? null : _ctx.type,
    target: _ctx.blank ? '_blank' : null,
    class: _normalizeClass(["flex h-5 w-5 shrink-0 items-center justify-center rounded-full", [
      _ctx.active && 'bg-kale-60 text-kale-10',
      !_ctx.active && 'hover:bg-kale-70 hover:text-kale-30 text-kale-40',
      !_ctx.disabled && 'cursor-pointer',
      _ctx.disabled && 'cursor-not-allowed',
    ]])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["type", "target", "class"]))
}
}

})