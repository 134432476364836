import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { PinReference_Type } from '@zendesk/zqa-services/pins'
import { useRoute, START_LOCATION } from 'vue-router'

import { GlobalEvents } from '@klausapp/ui-kit'
import { routeMetavalue } from '@/utils/routes'
import analytics, { track } from '@/utils/analytics'
import { openPinModal } from '@/composables/usePinModal'
import { session } from '@/composables/useSession'
import { useEvent } from '@/composables/useEvent'
import PinModal from '@/modules/pins/modal/PinModal.vue'
import { showPalette } from '@/modules/application/components/CommandPalette/utils/useCommandPalette'
import { relativeTimeLater } from '@/utils/date'
import { embeddedRuntime } from '@/config'

import QErrorCat from './components/QErrorCat.vue'
import Navbar from './components/Navbar.vue'
import CommandPalette from './components/CommandPalette'
import ApplicationBanners from './components/ApplicationBanners.vue'
import HelpDrawer from './components/HelpDrawer/HelpDrawer.vue'
import { listenForAppUpdate } from './utils/updateNotification'


export default /*@__PURE__*/_defineComponent({
  __name: 'Application',
  setup(__props) {

_useCssVars(_ctx => ({
  "6c61af8d": (bannerPadding.value)
}))

const route = useRoute()
const bannerPadding = ref('0px')

// TODO: Add watcher for route changes to update --app-background CSS var
const lightBackground = computed(() => routeMetavalue(route, 'lightBackground'))

const showSidebar = computed(() => {
  const initialRoute = route.fullPath === START_LOCATION.fullPath
  return session.user && !initialRoute && !routeMetavalue(route, 'hideNav')
})

const isExtension = computed(() => {
  return embeddedRuntime() || route.name?.toString().startsWith('extension.')
})

listenForAppUpdate()

onMounted(() => {
  const loader = document.getElementById('app-loader')

  loader?.classList.add('hidden')
  setTimeout(() => loader?.remove(), 600)
  relativeTimeLater()

  document.addEventListener('click', trackClicks)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', trackClicks)
})

const changeBannerPadding = (padding: string) => {
  bannerPadding.value = padding
}

const newManualPin = () => {
  openPinModal({ referenceType: PinReference_Type.MANUAL })
}

const trackClicks = (e: MouseEvent) => {
  if (!(e.target instanceof HTMLElement)) return
  const { dataset, baseURI, textContent } = e.target
  let name = dataset.trackId
  if (!name) return

  const useMethod = name.startsWith('track:')
  if (useMethod) {
    const method = name.replace('track:', '')
    return (analytics as any)[method]
  }

  track(name, { baseURI, textContent: (textContent || '').replace(/\s+/g, '') })
}

const toggleCommandPalette = () => {
  showPalette.value = !showPalette.value
}

useEvent('show-command-palette', toggleCommandPalette)

watch(lightBackground, (isLight) => {
  // TODO: Get values from Tailwind config
  document.documentElement.style.setProperty('--app-background', isLight ? 'white' : '#171A22')
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-container text-gray-80 fixed flex w-full text-base", lightBackground.value ? 'bg-white' : 'bg-nav-background'])
  }, [
    (showSidebar.value)
      ? (_openBlock(), _createBlock(_unref(GlobalEvents), {
          key: 0,
          onKeydown: [
            _withKeys(_withModifiers(newManualPin, ["ctrl","prevent"]), ["p"]),
            _withKeys(_withModifiers(newManualPin, ["meta","prevent"]), ["p"]),
            _withKeys(_withModifiers(toggleCommandPalette, ["ctrl","prevent"]), ["k"]),
            _withKeys(_withModifiers(toggleCommandPalette, ["meta","prevent"]), ["k"])
          ]
        }, null, 8 /* PROPS */, ["onKeydown"]))
      : _createCommentVNode("v-if", true),
    (_unref(session).account && _unref(session).user && !isExtension.value)
      ? (_openBlock(), _createBlock(ApplicationBanners, {
          key: 1,
          onPaddingChange: changeBannerPadding
        }))
      : _createCommentVNode("v-if", true),
    (showSidebar.value)
      ? (_openBlock(), _createBlock(Navbar, { key: 2 }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("article", {
      "data-testid": "view-container",
      class: _normalizeClass(["flex flex-1", isExtension.value ? 'w-full' : _ctx.$style.wrapper])
    }, [
      _createVNode(PinModal),
      _createCommentVNode(" <transition-fade> FIXME: backdrop-filter causes buggy background chopping with fade transition "),
      (_unref(showPalette))
        ? (_openBlock(), _createBlock(_unref(CommandPalette), {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (showPalette.value = false))
          }))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" </transition-fade> "),
      _createVNode(HelpDrawer),
      _createVNode(_component_router_view)
    ], 2 /* CLASS */),
    _createVNode(QErrorCat)
  ], 2 /* CLASS */))
}
}

})