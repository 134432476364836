import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { "data-testid": "dropdown-content" }

import { computed, ref, provide, watch, onMounted } from 'vue'
import { Tippy, type TippyComponent } from 'vue-tippy'
import { type Placement } from 'tippy.js'
import { useMutationObserver } from '@vueuse/core'

import GlobalEvents from '../GlobalEvents.vue'

import { MENU_ITEM_CLASS } from './constants'
import { sameWidth } from './popper-modifiers'
import useMenu from './useMenu'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenu',
  props: {
    disabled: { type: Boolean },
    placement: { default: 'bottom-start' },
    maxWidth: { default: '250px' },
    appendToDocument: { type: Boolean },
    size: { default: 'md' },
    matchWidth: { type: Boolean },
    maxHeight: { default: 300 },
    allowSelectionWithSpace: { type: Boolean, default: true }
  },
  emits: ["visibility-change"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

const tippyRef = ref<TippyComponent | null>(null)
const listRef = ref<HTMLDivElement | null>(null)

const menuState = useMenu(tippyRef)

const updateItemIds = () => {
  if (!listRef.value) return

  const menuItems = listRef.value.getElementsByClassName(MENU_ITEM_CLASS)
  const ids = Array.from(menuItems).map((item) => item.id)
  menuState.setItemIds(ids)
}

useMutationObserver(listRef, () => updateItemIds(), { childList: true, subtree: true })

const tippyTheme = computed(() => {
  const themes = ['light', 'dropdown', `dropdown-${props.size}`]
  return themes.join(' ')
})

const appendTo = computed(() => (props.appendToDocument ? document.body : 'parent'))

const popperOptions = computed(() => ({
  modifiers: props.matchWidth ? [sameWidth] : [],
}))

const handleTab = (e: KeyboardEvent) => {
  if (e.shiftKey) {
    menuState.focusPrevious()
  } else {
    menuState.focusNext()
  }
}

const handleSpace = (e: KeyboardEvent) => {
  if (props.allowSelectionWithSpace) {
    e.preventDefault()
    menuState.selectFocusedItem()
  }
}

const resetScroll = () => menuState.resetScroll()

const show = () => menuState.openMenu()
const hide = () => menuState.closeMenu()

const isVisible = computed(() => menuState.isVisible())

watch(isVisible, (visible) => {
  if (!visible) {
    menuState.resetScroll()
  }

  emit('visibility-change', visible)
})

onMounted(() => updateItemIds())

provide('menuState', menuState)

__expose({ tippyRef, resetScroll, show, hide, isVisible })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Tippy), {
    ref_key: "tippyRef",
    ref: tippyRef,
    trigger: "manual",
    placement: _ctx.placement,
    class: _normalizeClass(["flex [&>button]:overflow-hidden", _ctx.disabled && '[&>button]:cursor-not-allowed']),
    style: _normalizeStyle({ maxWidth: _ctx.maxWidth }),
    arrow: false,
    "append-to": appendTo.value,
    theme: tippyTheme.value,
    "popper-options": popperOptions.value,
    interactive: "",
    tabindex: "-1",
    "data-testid": "dropdown"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "before"),
        _createElementVNode("div", {
          ref_key: "listRef",
          ref: listRef,
          style: _normalizeStyle({ maxHeight: `${_ctx.maxHeight}px` }),
          class: "overflow-y-auto"
        }, [
          _renderSlot(_ctx.$slots, "list")
        ], 4 /* STYLE */),
        _renderSlot(_ctx.$slots, "after")
      ])
    ]),
    default: _withCtx(() => [
      (isVisible.value)
        ? (_openBlock(), _createBlock(GlobalEvents, {
            key: 0,
            "no-filtering": "",
            onKeydown: [
              _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => _unref(menuState).closeMenu(), ["prevent"]), ["esc"])),
              _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => _unref(menuState).selectFocusedItem(), ["prevent"]), ["enter"])),
              _withKeys(handleSpace, ["space"]),
              _withKeys(_withModifiers(handleTab, ["prevent"]), ["tab"]),
              _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => _unref(menuState).focusNext(), ["prevent"]), ["down"])),
              _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => _unref(menuState).focusPrevious(), ["prevent"]), ["up"]))
            ],
            onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["tab"]))
          }, null, 8 /* PROPS */, ["onKeydown"]))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["placement", "class", "style", "append-to", "theme", "popper-options"]))
}
}

})