import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-90 text-lg font-medium" }
const _hoisted_2 = { class: "mb-3 flex flex-col gap-3" }
const _hoisted_3 = { class: "text-gray-70 text-sm" }

import { UiKeyCombo } from '@klausapp/ui-kit'
import QModal from '@/components/QModal.vue'
import keyboardShortcuts from './keyboardShortcuts'


export default /*@__PURE__*/_defineComponent({
  __name: 'KeyboardShortcutsModal',
  props: {
    visible: { type: Boolean }
  },
  setup(__props: any) {


const shortcuts = keyboardShortcuts()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(QModal, {
    show: _ctx.visible,
    "close-button": "",
    dismissable: "",
    "propagate-events": "",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('modules.application.shortcuts.title')), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shortcuts), (shortcut) => {
          return (_openBlock(), _createElementBlock("li", {
            key: shortcut.description,
            class: "flex items-center justify-between"
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(shortcut.description), 1 /* TEXT */),
            _createVNode(_unref(UiKeyCombo), {
              combo: shortcut.combo,
              alternative: shortcut.alternative
            }, null, 8 /* PROPS */, ["combo", "alternative"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}
}

})