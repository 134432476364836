import { reactive, computed, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import type { AdvancedScorecard_WorkspaceScorecard as WorkspaceScorecard } from '@zendesk/zqa-services/scorecards'
import { getAdvancedScorecards, getAllAdvancedScorecards } from '@/modules/shared/TicketContent/api'
import { session } from '@/composables/useSession'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import type { CategoryGroup, Category } from '@/modules/shared/Review/utils'
import { mapCategoriesById, getSortedGroupsFromCategories, getCategoriesFromScorecard } from './utils'

export interface State {
  // Scorecards for leaving new feedback
  scorecards: WorkspaceScorecard[]
  selectedScorecard?: WorkspaceScorecard
  categories: Category[]
  // Scorecards for showing existing feedback
  allScorecards: WorkspaceScorecard[]
  categoriesByScorecard: Record<WorkspaceScorecard['id'], Category[]>
}

export const defaultState: State = {
  scorecards: [],
  selectedScorecard: undefined,
  categories: [],
  allScorecards: [],
  categoriesByScorecard: {},
}

const SCORECARD_STORAGE_KEY = 'zqaSelectedScorecard.v2'

export default createSharedComposable(() => {
  const state = reactive(defaultState)

  const { connectionId, conversationId } = useTicketRouteParams()

  const generateScorecardStorageKey = (workspaceId: number) => `${workspaceId}.${SCORECARD_STORAGE_KEY}`

  const isDynamicScorecard = computed(() => false)

  function setScorecard(selectedScorecard: WorkspaceScorecard) {
    state.selectedScorecard = selectedScorecard
    state.categories = getCategoriesFromScorecard(selectedScorecard)
  }

  const _setDefaultScorecard = (workspaceId: number) => {
    const key = generateScorecardStorageKey(workspaceId)
    const persisted: string | undefined = localStorage[key]
    const defaultScorecardId = persisted || state.selectedScorecard?.id

    if (defaultScorecardId) {
      const scorecard = state.scorecards.find((sc) => sc.id === defaultScorecardId)

      // Outdated scorecard persisted, remove it
      if (!scorecard) localStorage.removeItem(key)
      setScorecard(scorecard || state.scorecards[0])
    } else {
      setScorecard(state.scorecards[0])
    }
  }

  async function loadScorecards() {
    if (!session.features.advancedScorecardsDev) return
    if (!conversationId.value || !connectionId.value) return

    const { data: scorecards } = await getAdvancedScorecards(conversationId.value, connectionId.value)
    state.scorecards = scorecards

    if (scorecards[0] && !state.selectedScorecard) {
      _setDefaultScorecard(session.workspace.id)
    }
  }

  const { data: allScorecards } = useQuery({
    queryKey: ['advanced-scorecards-list'],
    queryFn: getAllAdvancedScorecards,
    select: ({ data: scorecards }) => {
      return {
        scorecards,
        categoriesByScorecard: scorecards.reduce((acc, scorecard) => {
          acc[scorecard.id] = getCategoriesFromScorecard(scorecard)
          return acc
        }, {}),
      }
    },
    enabled: computed(() => !!session.features.advancedScorecardsDev),
  })

  watch(
    () => allScorecards.value,
    (allScorecards) => {
      if (allScorecards) {
        state.allScorecards = allScorecards.scorecards
        state.categoriesByScorecard = allScorecards.categoriesByScorecard
      }
    },
  )

  const { data: scorecards } = useQuery({
    queryKey: ['advancedScorecards', conversationId.value, connectionId.value],
    queryFn: async () => {
      const { data } = await getAdvancedScorecards(conversationId.value, connectionId.value)
      return data
    },
    enabled: computed(() => !!(session.features.advancedScorecardsDev && conversationId.value && connectionId.value)),
  })

  watch(
    () => scorecards.value,
    (scorecards) => {
      if (scorecards) {
        state.scorecards = scorecards

        if (!scorecards.length) return

        if (state.selectedScorecard) {
          setScorecard(scorecards.find((s) => s.id === state.selectedScorecard.id))
        } else {
          _setDefaultScorecard(session.workspace.id)
        }
      }
    },
  )

  const clearSelectedScorecard = () => setScorecard(undefined)

  const categoryOptions = computed(() => mapCategoriesById(state.categories))

  const categoryOptionsByScorecardId = computed<Record<string, Record<number, Category>>>(() => {
    const options = Object.keys(state.categoriesByScorecard).reduce((acc, scorecardId) => {
      acc[scorecardId] = mapCategoriesById(state.categoriesByScorecard[scorecardId])
      return acc
    }, {})

    return options
  })

  const activeScorecardCategories = computed(() => Object.keys(categoryOptions.value).map(Number))

  const categoryGroups = computed(() => getSortedGroupsFromCategories(categoryOptions.value))

  const categoryGroupsByScorecardId = computed<Record<string, CategoryGroup[]>>(() => {
    return Object.keys(categoryOptionsByScorecardId.value).reduce((acc, scorecardId) => {
      acc[scorecardId] = getSortedGroupsFromCategories(categoryOptionsByScorecardId.value[scorecardId])
      return acc
    }, {})
  })

  const resetScorecards = () => {
    state.scorecards = []
  }

  const selectedScorecardName = computed(() => state.selectedScorecard?.name)

  async function setDraftedScorecard(scorecardId: string) {
    if (isDynamicScorecard.value) return
    if (!state.scorecards.length) await loadScorecards()

    const scorecard = state.scorecards?.find((s) => s.id === scorecardId)
    if (scorecard) setScorecard(scorecard)
  }

  return {
    state,
    loadScorecards,
    setScorecard,
    clearSelectedScorecard,
    activeScorecardCategories,
    categoryOptions,
    categoryOptionsByScorecardId,
    categoryGroups,
    resetScorecards,
    isDynamicScorecard,
    selectedScorecardName,
    setDraftedScorecard,
    generateScorecardStorageKey,
    categoryGroupsByScorecardId,
  }
})
