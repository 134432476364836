import { createSharedComposable } from '@vueuse/core'
import { ref } from 'vue'

export enum OmniPanelView {
  REVIEW = 'review',
  FEEDBACK = 'feedback',
  INFO = 'info',
  UNDEFINED = '',
}

export default createSharedComposable(() => {
  const panelView = ref(OmniPanelView.FEEDBACK)

  const activeFeedbackView = (routeName: string) => {
    if (routeName.includes('message')) {
      return 'message'
    } else if (routeName.endsWith('.dispute')) {
      return 'dispute'
    }

    return undefined
  }

  const setPanelView = (view: OmniPanelView) => {
    panelView.value = view
  }

  const toggleReviewPanel = () => {
    const view = panelView.value === OmniPanelView.REVIEW ? undefined : OmniPanelView.REVIEW
    setPanelView(view)
  }

  return { panelView, activeFeedbackView, setPanelView, toggleReviewPanel }
})
