import { mergeDefaults as _mergeDefaults, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-gray-60 cursor-default px-4 py-2 text-left text-xs font-medium"
}
const _hoisted_2 = {
  key: 1,
  class: "py-2 text-center text-xs font-medium text-gray-50"
}
const _hoisted_3 = {
  key: 2,
  class: "border-b-gray-20 mb-2 flex h-9 items-center border-b pb-1"
}
const _hoisted_4 = ["aria-label", "placeholder"]
const _hoisted_5 = {
  key: 3,
  class: "px-3 pb-[5.5px] pt-[3.5px] text-left text-sm font-medium text-gray-50"
}
const _hoisted_6 = {
  key: 4,
  class: "px-3 pb-[5.5px] pt-[3.5px] text-left text-sm font-medium text-gray-50"
}
const _hoisted_7 = {
  key: 0,
  class: "px-1"
}
const _hoisted_8 = {
  key: 1,
  class: "px-1"
}

import { ref, watch, computed, onMounted, nextTick } from 'vue'
import { formatCount } from '@klausapp/shared/utils/number'
import { useElementSize } from '@vueuse/core'

import { SearchIcon } from '../../icons'
import UiMenu from '../UiMenu/UiMenu.vue'

import defaultProps from './defaultProps'
import { type DropdownProps, SelectAllModes, type Modes, DisplayModes, type Selected } from './types'
import UiDropdownGroup from './UiDropdownGroup.vue'
import UiDropdownTrigger from './UiDropdownTrigger.vue'
import UiDropdownTagsTrigger from './UiDropdownTagsTrigger.vue'
import useList from './composables/useList'

interface TriggerSlotProps {
  count: string | number
  selectedOptions: T[]
}

interface ShowOptions {
  focusSearch?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownV2',
  props: /*@__PURE__*/_mergeDefaults({
    mode: {},
    isObject: {},
    propToCheck: {},
    options: {},
    modelValue: {},
    closeOnSelect: { type: Boolean },
    selectAllLabel: {},
    allowSingleDeselect: { type: Boolean },
    allowDeselectAll: { type: Boolean },
    maxHeight: {},
    triggerLabel: {},
    triggerPlaceholder: {},
    label: {},
    searchPlaceholder: {},
    noResultsText: {},
    loadingText: {},
    selectAllMode: {},
    filterResults: { type: Boolean },
    total: {},
    maxWidth: {},
    fullWidth: { type: Boolean },
    isLoading: { type: Boolean },
    reorderAfterSelect: { type: Boolean },
    hasSearch: { type: Boolean },
    itemsLabel: {},
    size: {},
    appendToDocument: { type: Boolean },
    disabled: { type: Boolean },
    excludedOptions: {},
    canRemove: { type: Boolean },
    triggerTheme: {},
    groups: {},
    placement: {},
    display: {},
    helpText: {},
    error: { type: Boolean }
  }, defaultProps),
  emits: ["update:modelValue", "search-change", "visibility-change", "remove", "add-option", "general-add-option"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit



if (typeof props.isObject === 'string' && props.isObject === '') {
  console.error(
    'UiDropdownV2: Use explicit `:is-object="true" instead of `is-object` as otherwise the prop will end up as `""` for some reason.',
  )
}

const tagsTriggerRef = ref<HTMLElement | null>(null)
const searchInput = ref<HTMLInputElement>()
const listRef = ref<HTMLDivElement>()
const menuRef = ref<InstanceType<typeof UiMenu>>()
const searchQuery = ref('')

const { height: tagTriggerHeight } = useElementSize(tagsTriggerRef)

const tippyRef = computed(() => menuRef.value?.tippyRef)

const show = async ({ focusSearch }: ShowOptions = {}) => {
  await nextTick()
  menuRef.value?.show()
  tagsTriggerRef.value?.focus()
  if (focusSearch) {
    await nextTick()
    searchInput.value?.focus()
  }
}
const hide = () => menuRef.value?.hide()

const {
  filteredGroups,
  isEmpty,
  isSelected,
  isIndeterminate,
  reorderList,
  selectedIds,
  selectedOptions,
  excludedValues,
  allOptions,
  toggle,
  onUpdate,
  addOrSelectOption,
} = useList<T, P, L, M, V, O>(props, emit, searchQuery)

onUpdate(({ selected, excluded }) => {
  emit('update:modelValue', selected, excluded)
})

const formattedCount = computed(() => formatCount(totalSelectedCount.value))

const totalSelectedCount = computed(() => {
  if (props.selectAllMode === SelectAllModes.IntermediaryAll) {
    if (excludedValues.value.length) return (props.total ?? props.modelValue.length) - excludedValues.value.length

    return selectedIds.value.length
  }

  return props.modelValue.length || selectedIds.value.length
})

const handleOptionClick = (option: T) => {
  toggle(option)

  if (props.display === DisplayModes.Tags) {
    searchQuery.value = ''
  }
  if (props.closeOnSelect) menuRef.value?.hide()
}

const handleContainerScroll = (top: number) => {
  listRef.value?.scrollTo({ top })
}

const handleAddOption = (query: string) => {
  addOrSelectOption(query)
  searchQuery.value = ''
  tagsTriggerRef.value?.focus()
}

const searchInputVisible = computed(() => {
  if (!props.hasSearch) return false

  if (props.display === DisplayModes.Tags) return false

  if (!props.filterResults) return true

  return searchQuery.value || allOptions.value.length
})

watch(searchQuery, (val) => {
  if (props.reorderAfterSelect) reorderList()
  menuRef.value?.resetScroll()
  emit('search-change', val)
})

const isVisible = ref(false)

const handleVisibilityChange = (isOpen: boolean) => {
  isVisible.value = isOpen
  emit('visibility-change', isOpen)
}

watch(isVisible, (visible) => {
  if (visible) {
    searchQuery.value = ''
    searchInput.value?.focus()
    if (props.reorderAfterSelect) reorderList()
  }
})

watch(tagTriggerHeight, () => {
  if (isVisible.value) tippyRef.value?.tippy?.popperInstance?.update()
})

onMounted(() => {
  if (props.reorderAfterSelect) reorderList()
})

__expose({ show, hide })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiMenu, {
    ref_key: "menuRef",
    ref: menuRef,
    disabled: _ctx.disabled,
    placement: _ctx.placement,
    size: _ctx.size,
    "max-height": _ctx.maxHeight,
    "append-to-document": _ctx.appendToDocument,
    "max-width": _ctx.fullWidth ? '100%' : `${_ctx.maxWidth}px`,
    class: _normalizeClass({ 'w-full': _ctx.fullWidth, 'overflow-hidden': _ctx.display === _unref(DisplayModes).Tags }),
    "match-width": _ctx.display === _unref(DisplayModes).Tags,
    "allow-selection-with-space": !searchQuery.value.length,
    onVisibilityChange: handleVisibilityChange
  }, _createSlots({
    before: _withCtx(() => [
      (_ctx.helpText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.helpText), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.itemsLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.itemsLabel), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (searchInputVisible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              ref_key: "searchInput",
              ref: searchInput,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchQuery).value = $event)),
              "data-testid": "dropdown-search-input",
              type: "text",
              "aria-label": _ctx.searchPlaceholder,
              placeholder: _ctx.searchPlaceholder,
              class: "text-gray-80 w-full appearance-none border-none px-3 text-sm outline-none placeholder:text-gray-50 focus:outline-none"
            }, null, 8 /* PROPS */, _hoisted_4), [
              [_vModelText, searchQuery.value]
            ]),
            _createVNode(_unref(SearchIcon), { class: "icon-m mr-2 text-gray-50" })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.isLoading && _ctx.loadingText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.loadingText), 1 /* TEXT */))
        : (_unref(isEmpty) && _ctx.noResultsText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.noResultsText), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
    ]),
    after: _withCtx(() => [
      (_ctx.$slots['general-create-option'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "text-gray-80 hover:bg-active-secondary group flex w-full items-center gap-2 rounded px-1 py-1 text-left text-sm font-medium",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('general-add-option')))
            }, [
              _renderSlot(_ctx.$slots, "general-create-option")
            ])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.$slots['create-option'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (searchQuery.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "text-gray-80 hover:bg-active-secondary group flex w-full items-center gap-2 rounded px-1 py-1 text-left text-sm font-medium",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (handleAddOption(searchQuery.value)))
                }, [
                  _renderSlot(_ctx.$slots, "create-option", { searchQuery: searchQuery.value })
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      (_ctx.$slots.trigger)
        ? _renderSlot(_ctx.$slots, "trigger", {
            key: 0,
            active: isVisible.value,
            selectedOptions: _unref(selectedOptions)
          })
        : _createCommentVNode("v-if", true),
      (!_ctx.$slots.trigger)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (props.display === _unref(DisplayModes).List)
              ? (_openBlock(), _createBlock(UiDropdownTrigger, _mergeProps({ key: 0 }, props, {
                  expanded: isVisible.value,
                  "total-selected-count": totalSelectedCount.value,
                  "selected-options": _unref(selectedOptions),
                  "formatted-count": formattedCount.value,
                  "excluded-values": _unref(excludedValues),
                  "selected-ids": _unref(selectedIds),
                  "all-options": _unref(allOptions),
                  "search-query": searchQuery.value,
                  "full-width": _ctx.fullWidth,
                  error: _ctx.error,
                  onRemove: _cache[0] || (_cache[0] = ($event: any) => (emit('remove')))
                }), _createSlots({
                  "trigger-single-selected": _withCtx(({ count }) => [
                    _renderSlot(_ctx.$slots, "trigger-single-selected", {
                      count: count,
                      selectedOptions: _unref(selectedOptions)
                    })
                  ]),
                  "trigger-all-selected": _withCtx(({ count }) => [
                    _renderSlot(_ctx.$slots, "trigger-all-selected", {
                      count: count,
                      selectedOptions: _unref(selectedOptions)
                    })
                  ]),
                  "trigger-multiple-selected": _withCtx(({ count }) => [
                    _renderSlot(_ctx.$slots, "trigger-multiple-selected", {
                      count: count,
                      selectedOptions: _unref(selectedOptions)
                    })
                  ]),
                  "trigger-placeholder": _withCtx(() => [
                    _renderSlot(_ctx.$slots, "trigger-placeholder")
                  ]),
                  _: 2 /* DYNAMIC */
                }, [
                  (_ctx.$slots.icon)
                    ? {
                        name: "icon",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "icon")
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["expanded", "total-selected-count", "selected-options", "formatted-count", "excluded-values", "selected-ids", "all-options", "search-query", "full-width", "error"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  ref_key: "tagsTriggerRef",
                  ref: tagsTriggerRef,
                  class: "w-full"
                }, [
                  _createVNode(UiDropdownTagsTrigger, _mergeProps(props, {
                    expanded: isVisible.value,
                    "selected-options": _unref(selectedOptions),
                    "search-query": searchQuery.value,
                    "dropdown-visible": isVisible.value,
                    onSearchChange: _cache[1] || (_cache[1] = ($event: any) => (searchQuery.value = $event)),
                    onToggle: handleOptionClick,
                    onAddOption: handleAddOption
                  }), null, 16 /* FULL_PROPS */, ["expanded", "selected-options", "search-query", "dropdown-visible"])
                ], 512 /* NEED_PATCH */))
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (!_ctx.isLoading && !_unref(isEmpty))
      ? {
          name: "list",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredGroups), (group) => {
              return (_openBlock(), _createBlock(UiDropdownGroup, {
                key: group.id,
                "data-testid": "dropdown-group",
                "list-ref": listRef.value,
                name: group.name || undefined,
                options: group.options,
                "model-value": _unref(selectedIds),
                mode: props.mode,
                label: props.label,
                "get-is-indeterminate": _unref(isIndeterminate),
                "get-is-selected": _unref(isSelected),
                total: group.total,
                "prop-to-check": props.propToCheck,
                "empty-text": group.emptyText,
                onToggle: handleOptionClick,
                onScrollContainer: handleContainerScroll
              }, _createSlots({
                option: _withCtx(({ option: slotOption }) => [
                  _renderSlot(_ctx.$slots, "option", {
                    option: slotOption,
                    searchActive: !!searchQuery.value?.length
                  })
                ]),
                "more-count": _withCtx(({ count }) => [
                  _renderSlot(_ctx.$slots, "more-count", { count: count })
                ]),
                _: 2 /* DYNAMIC */
              }, [
                (_ctx.$slots['option-icon'])
                  ? {
                      name: "option-icon",
                      fn: _withCtx(({ option: slotOption }) => [
                        _renderSlot(_ctx.$slots, "option-icon", { option: slotOption })
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["list-ref", "name", "options", "model-value", "mode", "label", "get-is-indeterminate", "get-is-selected", "total", "prop-to-check", "empty-text"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled", "placement", "size", "max-height", "append-to-document", "max-width", "class", "match-width", "allow-selection-with-space"]))
}
}

})