import { createSharedComposable } from '@vueuse/core'
import { reactive } from 'vue'
import { type FeedbackState } from '@/composables/useReviewSidebar/useReviewSidebar'
import { getLoadingRevieweeOption } from '../../TicketContent/utils/defaultReviewee'

export interface FeedbackFormState extends FeedbackState {
  userQuery: string
}

export default createSharedComposable(() => {
  const getEmptyForm = (): FeedbackFormState => ({
    user: {
      id: undefined,
      name: '',
      avatar: '',
      email: '',
      selfDisabled: false,
    },
    ratings: {},
    comment: '',
    commentTags: [],
    scorecardId: null,
    userQuery: '',
  })

  const form = reactive(getEmptyForm())

  const resetFormState = () => {
    form.user = getLoadingRevieweeOption()
    form.ratings = {}
    form.comment = ''
    form.commentTags = []
    form.scorecardId = null
    form.userQuery = ''
  }

  return {
    form,
    resetFormState,
  }
})
