import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mt-auto pb-1" }
const _hoisted_2 = {
  "aria-label": "Help",
  type: "button",
  class: "navbar__link relative"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex flex-1 items-center justify-start" }

import { computed, ref, defineAsyncComponent } from 'vue'
import { BookmarkIcon, CommandIcon, MessageCircleIcon, SearchIcon, StarIcon, UsersIcon } from '@klausapp/ui-kit/icons'
import { GlobalEvents, UiKeyCombo, theme } from '@klausapp/ui-kit'
import { useRoute, useRouter } from 'vue-router'

import config, { hasZendeskProxy } from '@/config'
import { Tippy, delay } from '@/components/Tippy'
import { session } from '@/composables/useSession'
import { useActivitiesCount } from '@/composables/useActivitiesCount'
import useAssignmentSection from '@/modules/tasks/composables/useAssignmentSection'
import analytics from '@/utils/analytics'
import { logout } from '@/modules/auth/api'
import { hasWorkspaceRole } from '@/utils/roleUtils'
import { showPalette } from '@/modules/application/components/CommandPalette/utils/useCommandPalette'
import QFeatureBadge from '@/components/QFeatureBadge.vue'
import { bus } from '@/utils/bus'
import { openSupport } from '@/utils/support'
import useBreakpoints from '@/composables/useBreakpoints'
import timeTracking from '@/modules/time-tracking/timeTracking'
import { useDashboardsList } from '@/composables/useDashboardsList'
import useNavbarRoutes from '@/composables/useNavbarRoutes'
import ZendeskProductTray from '@/modules/shared/Navigation/components/ZendeskProductTray/ProductTray.vue'
import useTicketRouteParams from '@/composables/useTicketRouteParams'

import Goal from './UserGoal.vue'
import NavLogo from './NavLogo/NavLogo.vue'
import KeyboardShortcutsModal from './KeyboardShortcutsModal.vue'
import NavbarCoachingIcon from './NavbarCoachingIcon.vue'
import NavbarSettingsIcon from './NavbarSettingsIcon.vue'
import NavbarLink from './NavbarLink.vue'
import SwitchAccountModal from './SwitchAccountModal.vue'
import { toggleCommandPaletteCombo } from './keyboardShortcuts'

const HOVER_TIMER = 400

export default /*@__PURE__*/_defineComponent({
  __name: 'Navbar',
  setup(__props) {

const NavConstructionLogo = defineAsyncComponent(() => import('./NavLogo/NavConstructionLogo.vue'))

const activitiesCount = useActivitiesCount()
const { dashboardList, lookerReviewsDashboard, lookerReviewsAgentDashboard } = useDashboardsList()
const { personalAssignments } = useAssignmentSection()
const { assignmentId, calibrationSessionId } = useTicketRouteParams()

const disputesCount = ref(0)
const showAccountSwitchModal = ref(false)
const showKeyboardShortcutsModal = ref(false)
const helpTippy = ref<InstanceType<typeof Tippy>>()

const { navbarRoutesMap } = useNavbarRoutes()
const { breakpoints } = useBreakpoints()
const floatingPageNav = breakpoints.smaller('lg')
// Starts a delay timer to trigger a floating page nav if user does not interact with anything within 400ms
let hoverTimeout: ReturnType<typeof setTimeout> | undefined = undefined

const selfReviews = computed(() => session.workspace?.settings?.selfReviews || false)
const activitesCountTotal = computed(() => activitiesCount.value?.total || 0)

const route = useRoute()
const router = useRouter()

const dashboardRoute = computed(() => {
  if (dashboardList.value.length && (lookerReviewsDashboard.value || lookerReviewsAgentDashboard.value)) {
    return hasWorkspaceRole('AGENT') ? lookerReviewsAgentDashboard.value : lookerReviewsDashboard.value
  }

  return ''
})

const handleLogout = async () => {
  analytics.navbarEvent('Clicked navbar popup item', 'Log out')
  await timeTracking.pause()
  logout()
}

const switchWorkspace = (workspaceId: number) => {
  if (workspaceId === session.workspace.id) return

  analytics.navbarEvent('Clicked navbar popup item', 'Switch team')

  const url = new URL(`${window.location.origin}${router.resolve({ name: route.name || undefined }).href}`)
  url.searchParams.append('workspace', workspaceId.toString())
  window.location.href = url.toString()
}

const openAccountModal = () => {
  showAccountSwitchModal.value = true
  analytics.navbarEvent('Clicked navbar popup item', 'Switch account')
}

const openChat = () => {
  openSupport()
  analytics.navbarEvent('Clicked navbar popup item', 'Chat with us')
}

const toggleShortcutsModal = () => {
  showKeyboardShortcutsModal.value = !showKeyboardShortcutsModal.value
  analytics.shortcutUsed('Toggle shortcut modal')
}

const openShortcutsModal = () => {
  showKeyboardShortcutsModal.value = true
  analytics.navbarEvent('Clicked navbar popup item', 'Keyboard shortcuts')
}

const onConversationsClick = () => {
  analytics.navbarEvent('Clicked navbar', 'Conversations')
  const { singleTicket, ...query } = route.query
  if (singleTicket) router.replace({ query })
}

const startHoverCounter = () => {
  if (!floatingPageNav.value) return
  if (hoverTimeout) clearTimeout(hoverTimeout)
  hoverTimeout = setTimeout(() => {
    hoverTimeout = undefined
    bus.$emit('show-page-nav')
  }, HOVER_TIMER)
}

const stopHoverCounter = () => {
  if (hoverTimeout) {
    clearTimeout(hoverTimeout)
    hoverTimeout = undefined
  }
}

const handleInteraction = (event: MouseEvent) => {
  if (event.target instanceof HTMLElement && event.target.closest('.navbar__link')) {
    stopHoverCounter()
  }
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(GlobalEvents), {
      onKeydown: _withKeys(_withModifiers(toggleShortcutsModal, ["shift","prevent"]), ["?"])
    }, null, 8 /* PROPS */, ["onKeydown"]),
    _createElementVNode("nav", _mergeProps(_ctx.$attrs, {
      class: "navbar",
      onMouseenter: startHoverCounter,
      onMouseleave: stopHoverCounter,
      onClick: handleInteraction
    }), [
      (_unref(config).flagManager)
        ? (_openBlock(), _createBlock(_unref(NavConstructionLogo), { key: 0 }))
        : (_openBlock(), _createBlock(NavLogo, { key: 1 })),
      (
        (_unref(session).features.lookerReviewsDashboard || _unref(session).features.advancedScorecardsDev) &&
        dashboardRoute.value &&
        'id' in dashboardRoute.value
      )
        ? (_openBlock(), _createBlock(NavbarLink, {
            key: 2,
            title: _ctx.$t(_unref(navbarRoutesMap).dashboard.label),
            to: { name: 'dashboard.looker', params: { id: dashboardRoute.value.id } },
            "data-testid": "navbar-dashboards",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Dashboard')))
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).dashboard.icon)))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title", "to"]))
        : (_openBlock(), _createBlock(NavbarLink, {
            key: 3,
            title: _ctx.$t(_unref(navbarRoutesMap).dashboard.label),
            to: { name: 'dashboard' },
            "data-testid": "navbar-dashboards",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Dashboard')))
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).dashboard.icon)))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title"])),
      (!_unref(hasWorkspaceRole)('AGENT') || selfReviews.value)
        ? (_openBlock(), _createBlock(NavbarLink, {
            key: 4,
            title: _ctx.$t(_unref(navbarRoutesMap).conversations.label),
            "data-testid": "navbar-conversations",
            to: { name: 'conversations' },
            class: _normalizeClass([disputesCount.value && _ctx.$style.notificationBubble]),
            "data-highlight-count": (disputesCount.value > 99 ? '99+' : disputesCount.value) || null,
            "hide-tooltip": "",
            onClick: onConversationsClick
          }, {
            default: _withCtx(({ active }) => [
              active
                ? (_openBlock(), _createBlock(Goal, { key: 0 }))
                : _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).conversations.icon), { key: 1 }, null, 512 /* NEED_PATCH */)), [
                    [_directive_tippy, {
          placement: 'right',
          content: _ctx.$t('modules.application.navbar.conversations'),
          delay: _unref(delay),
          offset: [0, 10],
        }]
                  ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title", "class", "data-highlight-count"]))
        : _createCommentVNode("v-if", true),
      (!_unref(hasWorkspaceRole)('AGENT'))
        ? (_openBlock(), _createBlock(NavbarLink, {
            key: 5,
            title: _ctx.$t(_unref(navbarRoutesMap).tasks.label),
            "data-testid": "navbar-tasks",
            to: {
        name: 'tasks',
        params: _unref(assignmentId) ? { assignmentId: _unref(assignmentId) } : { calibrationSessionId: _unref(calibrationSessionId) },
      },
            class: _normalizeClass([_unref(personalAssignments)?.length && _ctx.$style.notificationBubble]),
            "data-highlight-count": (_unref(personalAssignments)?.length > 99 ? '99+' : _unref(personalAssignments)?.length) || null,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Tasks')))
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).tasks.icon)))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title", "to", "class", "data-highlight-count"]))
        : _createCommentVNode("v-if", true),
      _createVNode(NavbarLink, {
        title: _ctx.$t(_unref(navbarRoutesMap).activity.label),
        to: { name: 'activity' },
        class: _normalizeClass([activitesCountTotal.value > 0 && _ctx.$style.notificationBubble]),
        "data-highlight-count": (activitesCountTotal.value > 99 ? '99+' : activitesCountTotal.value) || null,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Activity')))
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).activity.icon)))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title", "class", "data-highlight-count"]),
      (_unref(session).features.coaching || _unref(session).features.quizzes || _unref(session).features.pins)
        ? (_openBlock(), _createBlock(NavbarCoachingIcon, {
            key: 6,
            class: "navbar__link"
          }))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Tippy), {
          ref_key: "helpTippy",
          ref: helpTippy,
          theme: "light navbar__popup",
          trigger: "click",
          placement: "right-end",
          arrow: false,
          interactive: "",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Help menu')))
        }, {
          trigger: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_2, [
              _createVNode(_unref(theme).icons.help)
            ])), [
              [_directive_tippy, { placement: 'right', content: _ctx.$t('modules.application.navbar.help'), delay: _unref(delay) }]
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (helpTippy.value?.hide()))
            }, [
              _createElementVNode("a", {
                href: _ctx.$t('support_links.zendesk_help'),
                target: "_blank",
                rel: "noopener noreferrer",
                class: _normalizeClass(_ctx.$style.button),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar popup item', 'Knowledge Base')))
              }, [
                _createVNode(_unref(BookmarkIcon), { class: "icon-m" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.support_center')), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_3),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.button),
                "data-ignore-theme": "",
                type: "button",
                onClick: openChat
              }, [
                _createVNode(_unref(MessageCircleIcon), { class: "icon-m" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.chat')), 1 /* TEXT */)
              ], 2 /* CLASS */),
              _createElementVNode("a", {
                href: _ctx.$t('support_links.community'),
                target: "_blank",
                rel: "noopener noreferrer",
                class: _normalizeClass(_ctx.$style.button),
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar popup item', 'Community')))
              }, [
                _createVNode(_unref(UsersIcon), { class: "icon-m" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.community')), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_4),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.button),
                "data-ignore-theme": "",
                type: "button",
                onClick: openShortcutsModal
              }, [
                _createVNode(_unref(CommandIcon), { class: "icon-m" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.shortcuts')), 1 /* TEXT */)
              ], 2 /* CLASS */),
              _createElementVNode("a", {
                href: _ctx.$t('support_links.whats_new'),
                target: "_blank",
                rel: "noopener noreferrer",
                class: _normalizeClass(_ctx.$style.button),
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar popup item', 'Whats new')))
              }, [
                _createVNode(_unref(StarIcon), { class: "icon-m" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('modules.application.navbar.changelog')), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_5),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style.button),
                "data-ignore-theme": "",
                type: "button",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (showPalette.value = !_unref(showPalette)))
              }, [
                _createVNode(_unref(SearchIcon), { class: "icon-m" }),
                _createElementVNode("span", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t('modules.application.navbar.command_palette')) + " ", 1 /* TEXT */),
                  _createVNode(_unref(UiKeyCombo), {
                    combo: _unref(toggleCommandPaletteCombo),
                    theme: "light",
                    size: "small",
                    class: "ml-6"
                  }, null, 8 /* PROPS */, ["combo"]),
                  _createVNode(QFeatureBadge, {
                    feature: "commandPalette",
                    class: "ml-3"
                  })
                ])
              ], 2 /* CLASS */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */),
        (_unref(hasZendeskProxy))
          ? (_openBlock(), _createBlock(ZendeskProductTray, {
              key: 0,
              class: "navbar__link relative"
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(NavbarSettingsIcon, {
          ref: "link.settings",
          class: "navbar__link",
          "aria-label": _ctx.$t('modules.application.navbar.settings'),
          onShowAccountModal: openAccountModal,
          onSwitchWorkspace: switchWorkspace,
          onHandleLogout: handleLogout,
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(analytics).navbarEvent('Clicked navbar', 'Settings')))
        }, null, 8 /* PROPS */, ["aria-label"])
      ]),
      _createVNode(SwitchAccountModal, {
        show: showAccountSwitchModal.value,
        onClose: _cache[11] || (_cache[11] = ($event: any) => (showAccountSwitchModal.value = false))
      }, null, 8 /* PROPS */, ["show"]),
      _createVNode(KeyboardShortcutsModal, {
        visible: showKeyboardShortcutsModal.value,
        onClose: _cache[12] || (_cache[12] = ($event: any) => (showKeyboardShortcutsModal.value = false))
      }, null, 8 /* PROPS */, ["visible"])
    ], 16 /* FULL_PROPS */)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})