import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-gray-30 bg-gray-10 border border-b-0 first:rounded-t last:rounded-b last:border-b" }
const _hoisted_2 = { class: "text-nav-icon flex *:h-5 *:w-5 empty:hidden" }
const _hoisted_3 = { class: "text-gray-80 grow text-start text-sm font-semibold" }

import { ChevronRightIcon } from '@klausapp/ui-kit/icons'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAccordionAction',
  emits: ["click"],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "flex w-full items-center gap-2 p-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon")
      ]),
      _createElementVNode("h3", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode(_unref(ChevronRightIcon), { class: "icon-m text-gray-60" })
    ])
  ]))
}
}

})