import DOMPurify from 'dompurify'
import { escape } from 'lodash-es'
import { SANITIZER_PRESETS } from './constants/sanitize-config'

export const escapeHTMLEntities = (str: string) => escape(str)

// https://github.com/cure53/DOMPurify/blob/main/demos/hooks-target-blank-demo.html
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    ;(node as Element).setAttribute('target', '_blank')
    ;(node as Element).setAttribute('rel', 'noopener noreferrer nofollow')
  }
})

export const sanitize = (html: string, config = SANITIZER_PRESETS.BASE_CONFIG) => {
  return DOMPurify.sanitize(html, {
    ...config,
    RETURN_DOM: false,
    RETURN_DOM_FRAGMENT: false,
  })
}

export const strip = (html: string) => sanitize(html, SANITIZER_PRESETS.STRIP)

// TODO: This should happen in data pipeline
export const sanitizeTicketSubject = (title: string) =>
  title.replace(/(<p[^>]+?>|<p>|<\/p>|<a[^>]+?>|<a>|<\/a>|<span[^>]+?>|<span>|<\/span>)/gim, '').replace(/&#39;/g, `'`)
