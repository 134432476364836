import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white h-24 w-full animate-pulse rounded" }

import { defineComponent } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EditorLoader',
  setup(__props) {

defineComponent({ name: 'EditorLoader' })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})