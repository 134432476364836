import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["data-testId", "onClick", "onKeyup"]
const _hoisted_3 = {
  key: 0,
  class: "pointer-events-none text-sm font-bold"
}
const _hoisted_4 = ["disabled", "value"]

import type { FeatherIconComponent } from 'vue3-feather'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiStateButton',
  props: {
    modelValue: {},
    options: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

function updateValue(val: T, evt: MouseEvent | KeyboardEvent) {
  if (evt.target instanceof HTMLElement && evt.target.tagName !== 'LABEL') return
  emit('update:modelValue', val === props.modelValue ? undefined : val)
}

return (_ctx: any,_cache: any) => {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (opt, idx) => {
      return _withDirectives((_openBlock(), _createElementBlock("label", {
        key: opt.value.toString(),
        "data-testId": opt.dataTestId,
        class: _normalizeClass(["group relative flex min-h-8 min-w-8 cursor-pointer select-none items-center justify-center gap-2 text-nowrap border px-2", [
        idx === 0 && 'rounded-l',
        idx !== 0 && 'border-l-0',
        idx === _ctx.options.length - 1 && 'rounded-r',
        _ctx.modelValue === opt.value
          ? 'bg-active-secondary text-active-primary border-active-secondary hover:bg-active-secondary-hover hover:border-active-secondary-hover'
          : 'border-gray-30 hover:bg-gray-20 hover:border-gray-20 bg-white',
        opt.disabled && 'bg-gray-20 pointer-events-none text-gray-50',
        !opt.icon && 'px-4',
      ]]),
        tabindex: "0",
        role: "button",
        onClick: ($event: any) => (updateValue(opt.value, $event)),
        onKeyup: [
          _withKeys(_withModifiers(($event: any) => (updateValue(opt.value, $event)), ["exact"]), ["enter"]),
          _withKeys(_withModifiers(($event: any) => (updateValue(opt.value, $event)), ["exact"]), ["space"])
        ]
      }, [
        (opt.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(opt.label), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("input", {
          type: "radio",
          disabled: opt.disabled,
          class: "invisible fixed",
          value: opt.value
        }, null, 8 /* PROPS */, _hoisted_4),
        (opt.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(opt.icon), {
              key: 1,
              class: _normalizeClass([
          'text-button-primary-icon group-hover:text-button-primary-icon-hover h-4 w-4',
          opt.iconClass,
          _ctx.modelValue === opt.value ? '!text-button-primary-icon-active' : 'text-button-primary-icon',
          opt.disabled && 'text-gray-50',
        ])
            }, null, 8 /* PROPS */, ["class"]))
          : _createCommentVNode("v-if", true)
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2)), [
        [_directive_tippy, { content: opt.tooltip || null, delay: 400, placement: opt.tooltipPlacement || 'top' }]
      ])
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})