import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { "data-testid": "navbar-settings-menu" }
const _hoisted_2 = ["aria-label"]

import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { CheckIcon, LogOutIcon, RepeatIcon } from '@klausapp/ui-kit/icons'

import { session } from '@/composables/useSession'
import { Tippy, delay } from '@/components/Tippy'
import QAvatar from '@/components/QAvatar/QAvatar.vue'
import { hasAccountRole, hasHighestWorkspaceRole } from '@/utils/roleUtils'
import analytics from '@/utils/analytics'
import { useAccountList } from '@/composables/useAccountList'
import useNavbarRoutes from '@/composables/useNavbarRoutes'
import { hasZendeskProxy } from '@/config'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarSettingsIcon',
  emits: ["switch-workspace", "show-account-modal", "handle-logout"],
  setup(__props, { emit: __emit }) {

const emit = __emit

const tippyRef = ref<InstanceType<typeof Tippy>>()
const isOpen = ref(false)
const route = useRoute()
const [accounts] = useAccountList()

const linkActive = computed(() =>
  ['profile', 'settings', 'workspace'].some((r) => route.name?.toString().startsWith(r)),
)

const { navbarRoutesMap } = useNavbarRoutes()

function closeTippy() {
  tippyRef.value?.hide()
  isOpen.value = false
}

function openModal(id?: number) {
  if (id) emit('switch-workspace', id)
  else emit('show-account-modal')

  onLinkClick(id ? 'Workspace switch' : 'Switch account')
}

function onLinkClick(event: string) {
  analytics.navbarEvent('Clicked navbar popup item', event)
  closeTippy()
}

watch(isOpen, (opened) => {
  if (opened) {
    analytics.navbarEvent('Clicked navbar link', 'Avatar')
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_unref(Tippy), {
    ref_key: "tippyRef",
    ref: tippyRef,
    interactive: "",
    trigger: "click",
    theme: "light",
    placement: "right-end",
    "max-width": 500,
    class: _normalizeClass(_ctx.$style.wrapper),
    arrow: false,
    onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop","prevent"])),
    onShow: _cache[5] || (_cache[5] = ($event: any) => (isOpen.value = true)),
    onHide: _cache[6] || (_cache[6] = ($event: any) => (isOpen.value = false)),
    onKeyup: _withKeys(_withModifiers(closeTippy, ["exact"]), ["esc"])
  }, {
    trigger: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("button", {
        "aria-label": "User actions",
        class: _normalizeClass(['navbar__link', linkActive.value && 'router-link-active']),
        "data-testid": "user-actions",
        type: "button"
      }, [
        _createVNode(QAvatar, {
          size: 32,
          src: _unref(session).user.avatar,
          username: _unref(session).user.name,
          alt: "You",
          class: _normalizeClass(_ctx.$style.avatar)
        }, null, 8 /* PROPS */, ["src", "username", "class"])
      ], 2 /* CLASS */)), [
        [_directive_tippy, { placement: 'right', content: _ctx.$t('modules.application.navbar.settings'), delay: _unref(delay) }]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: { name: 'profile.general' },
          class: _normalizeClass(_ctx.$style.button),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onLinkClick('Settings')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style.label)
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).settings.icon), { class: "icon-m text-gray-50" })),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(_unref(navbarRoutesMap).settings.label)), 1 /* TEXT */)
            ], 2 /* CLASS */),
            (_unref(route).name === 'profile.general')
              ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                  key: 0,
                  class: "text-active-primary icon-m"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"]),
        (_unref(hasAccountRole)('ADMIN', 'MANAGER') || _unref(hasHighestWorkspaceRole)('MANAGER', 'LEAD'))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _unref(hasHighestWorkspaceRole)('LEAD') ? { name: 'settings.groups' } : { name: 'settings.users' },
              class: _normalizeClass(_ctx.$style.button),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (onLinkClick('Users & Workspaces')))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.$style.label)
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(navbarRoutesMap).users.icon), { class: "icon-m text-gray-50" })),
                  _createTextVNode(" " + _toDisplayString(_unref(session).features.botQa
              ? _ctx.$t(_unref(navbarRoutesMap).users.label)
              : _ctx.$t('modules.application.navbar.settings_submenu.users')), 1 /* TEXT */)
                ], 2 /* CLASS */),
                (_unref(route).name === 'settings.users')
                  ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                      key: 0,
                      class: "text-active-primary icon-m"
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to", "class"]))
          : _createCommentVNode("v-if", true),
        (_unref(accounts).length > 1 && !_unref(hasZendeskProxy))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              "aria-label": _ctx.$t('modules.application.navbar.switch_account'),
              class: _normalizeClass(_ctx.$style.button),
              "data-ignore-theme": "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (openModal()))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.label)
              }, [
                _createVNode(_unref(RepeatIcon), { class: "icon-m text-gray-50" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('modules.application.navbar.switch_account')), 1 /* TEXT */)
              ], 2 /* CLASS */)
            ], 10 /* CLASS, PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          type: "button",
          "data-ignore-theme": "",
          class: _normalizeClass(_ctx.$style.button),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handle-logout')))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.label)
          }, [
            _createVNode(_unref(LogOutIcon), { class: "icon-m text-gray-50" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('modules.application.navbar.log_out')), 1 /* TEXT */)
          ], 2 /* CLASS */)
        ], 2 /* CLASS */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "onKeyup"]))
}
}

})