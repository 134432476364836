import { ref, computed, defineAsyncComponent } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import i18n from '@/i18n'

interface HelpRoute {
  title: string
  content: ReturnType<typeof defineAsyncComponent>
  children?: Record<string, HelpRoute>
}

export default createSharedComposable(() => {
  const helpDrawerActive = ref(false)

  const pages = ref<Record<string, HelpRoute>>({
    scorecards_migration: {
      title: i18n.t('help_drawer.scorecards_migration.title'),
      content: defineAsyncComponent(() => import('../HelpScorecardsMigration.vue')),
      // If you want to add child routes, you can do it like below
      // Navigating to this route can be done by calling setActiveRoute('scorecards_migration.example')
      // children: {
      //   example: {
      //     title: 'Example',
      //     content: defineAsyncComponent(() => import('./HelpExample.vue')),
      //   },
      // },
    },
  })

  const _activeId = ref('scorecards_migration')
  const activeView = computed(() => {
    const ids = _activeId.value.split('.')
    let current: HelpRoute | undefined = pages.value[ids[0]]

    // Child routes will be separated by '.', e.g. 'scorecards_migration.example'
    // This loop will traverse the child routes and construct the active view
    for (let i = 1; i < ids.length; i++) {
      if (!current || !current.children) return null
      current = current.children[ids[i]]
    }

    return current
  })

  const setActiveRoute = (id: string) => {
    _activeId.value = id
  }

  const backToParent = () => {
    if (!isActiveChildRoute.value) return
    // Remove the last part of the id to go back to the parent route
    setActiveRoute(_activeId.value.split('.').slice(0, -1).join('.'))
  }

  const isActiveChildRoute = computed(() => {
    const ids = _activeId.value.split('.')
    return ids.length > 1
  })

  return {
    pages,
    helpDrawerActive,
    activeView,
    setActiveRoute,
    backToParent,
    isActiveChildRoute,
  }
})
