import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "data-klaus-unsaved-image"]

import { NodeViewWrapper } from '@tiptap/vue-3'
import { XIcon } from '@klausapp/ui-kit/icons'
import type { NodeViewProps } from '@tiptap/core'
import { bus } from '@/utils/bus'
import { useRedirectedImageSource } from '@/composables/useRedirectedImageSource'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageWidget',
  props: {
    editor: {},
    node: {},
    decorations: {},
    selected: { type: Boolean },
    extension: {},
    getPos: { type: Function },
    updateAttributes: { type: Function },
    deleteNode: { type: Function }
  },
  setup(__props: any) {

const props = __props

const handleError = () => {
  props.updateAttributes({ error: true })
}

const removeImage = () => {
  const pos = props.getPos()
  props.editor
    .chain()
    .deleteRange({ from: pos, to: pos + 1 })
    .focus()
    .run()
  bus.$emit('comment-editor-image-removed')
}

const { src } = useRedirectedImageSource(() => props.node.attrs.src)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NodeViewWrapper), null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.wrapper)
      }, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(_ctx.$style.button),
          onClick: removeImage
        }, [
          _createVNode(_unref(XIcon), {
            class: _normalizeClass(_ctx.$style.icon)
          }, null, 8 /* PROPS */, ["class"])
        ], 2 /* CLASS */),
        _createElementVNode("img", {
          src: _unref(src),
          "data-klaus-unsaved-image": _ctx.node.attrs['data-klaus-unsaved-image'],
          class: _normalizeClass(_ctx.selected && _ctx.$style.inFocus),
          loading: "lazy",
          onError: handleError
        }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})