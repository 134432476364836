import { v4 as uuid } from 'uuid'
import { session } from '@/composables/useSession'
import { api } from '@/api'

import { type PauseParams, type StopParams, type StopResponse, TimestampType } from './types'

const LIMIT_PAUSE_15_MINUTES = 15 * 60
const LIMIT_PAUSE_30_MINUTES = 30 * 60

let externalId: string | undefined
let connectionId: number | undefined
let isCalibration: boolean | undefined
let eventListenersAdded: boolean

const setId = (ticketIdentifier: { externalId: string; connectionId: number; isCalibration?: boolean }) => {
  externalId = ticketIdentifier.externalId
  connectionId = ticketIdentifier.connectionId
  isCalibration = !!ticketIdentifier.isCalibration
}

const clearId = () => {
  externalId = undefined
  connectionId = undefined
  isCalibration = undefined
}

const visibilityListener = () => {
  if (document.visibilityState === 'visible') start()
  else pause({ limit: true })
}

const addListeners = () => {
  if (eventListenersAdded) return
  document.addEventListener('visibilitychange', visibilityListener)
  eventListenersAdded = true
}

const sendTimestamp = async (type: TimestampType, params: StopParams | PauseParams = {}) => {
  if (!externalId || !connectionId) return

  const body = {
    id: uuid(),
    type,
    conversationId: externalId,
    connectionId,
    isCalibration,
    ...params,
  }

  try {
    return await api.post('t', {
      json: body,
      keepalive: true,
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
  } catch (e) {
    console.info('Time tracking event failed to send', e)
  }
}

const start = () => {
  addListeners()
  if (session.features.limitPauseInTimeTrackingFor15Min) {
    sendTimestamp(TimestampType.Start, { limitPause: LIMIT_PAUSE_15_MINUTES })
    return
  }

  if (session.features.limitPauseInTimeTracking) {
    sendTimestamp(TimestampType.Start, { limitPause: LIMIT_PAUSE_30_MINUTES })
    return
  }

  sendTimestamp(TimestampType.Start)
}

const pause = ({ limit } = { limit: false }) => {
  if (session.features.limitPauseInTimeTrackingFor15Min && limit) {
    return sendTimestamp(TimestampType.Pause, { limitPause: LIMIT_PAUSE_15_MINUTES })
  }

  if (session.features.limitPauseInTimeTracking && limit) {
    return sendTimestamp(TimestampType.Pause, { limitPause: LIMIT_PAUSE_30_MINUTES })
  }

  return sendTimestamp(TimestampType.Pause)
}

const stop = async (params: StopParams): Promise<StopResponse> => {
  const response = await sendTimestamp(TimestampType.Stop, params)
  start()
  return Promise.resolve(response?.json<StopResponse>() || { reviewTime: null })
}

const removeListeners = () => {
  document.removeEventListener('visibilitychange', visibilityListener)
  eventListenersAdded = false
}

export default {
  setId,
  clearId,
  start,
  pause,
  stop,
  removeListeners,
}
