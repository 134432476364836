import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

import { computed } from 'vue'
import { UiSpinner } from '..'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLightButton',
  props: {
    theme: { default: 'blue' },
    loading: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const themeClass = computed(() => {
  if (props.theme === 'gray')
    return 'bg-gray-20 hover:bg-gray-30 text-gray-80 disabled:bg-gray-20 disabled:text-gray-50'
  if (props.theme === 'brand')
    return 'bg-button-primary-background hover:bg-button-primary-background-hover text-white disabled:bg-button-primary-background-disabled'
  return 'bg-blue-20 hover:bg-blue-30 text-gray-80'
})

const spinnerColor = computed(() => {
  if (props.theme === 'gray') return 'gray-60'
  if (props.theme === 'brand') return 'white'
  return 'active-primary'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: ["inline-flex h-6 items-center gap-1 rounded-full px-2.5 text-xs font-medium mix-blend-multiply disabled:cursor-not-allowed", themeClass.value],
    type: "button"
  }), [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_unref(UiSpinner), {
          key: 0,
          "no-spinner-background": "",
          inline: "",
          background: "transparent",
          "spinner-color": spinnerColor.value
        }, null, 8 /* PROPS */, ["spinner-color"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _renderSlot(_ctx.$slots, "icon"),
          _renderSlot(_ctx.$slots, "default")
        ], 64 /* STABLE_FRAGMENT */))
  ], 16 /* FULL_PROPS */))
}
}

})