import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  href: "/admin",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_2 = { key: 1 }

import { computed, ref, watch, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import UiBanner from '@klausapp/ui-kit/components/UiBanner.vue'

import { hasZendeskProxy } from '@/config'
import { session } from '@/composables/useSession'
import { hasAccountRole } from '@/utils/roleUtils'
import analytics from '@/utils/analytics'
import { useEvent } from '@/composables/useEvent'
import i18n from '@/i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationBanners',
  emits: ["padding-change"],
  setup(__props, { emit: __emit }) {

const emit = __emit

const root = ref<HTMLDivElement>()
const userLimitVisible = ref(false)
const hideZDTrialBanner = ref(false)

const route = useRoute()

const trialRemaining = computed(() => {
  const today = new Date()
  const remaining = dayjs(session.account.trialEndAt).diff(today, 'day')
  return remaining > 0 ? remaining : 0
})

const trialRemainingText = computed(() => {
  if (trialRemaining.value === 0)
    return hasZendeskProxy && hasAccountRole('ADMIN')
      ? i18n.t('modules.application.banners.trial_end.template_today_zd_only')
      : i18n.t('modules.application.banners.trial_end.template_today')
  else if (trialRemaining.value === 1)
    return hasZendeskProxy && hasAccountRole('ADMIN')
      ? i18n.t('modules.application.banners.trial_end.template_1_day_zd_only', { n: trialRemaining.value })
      : i18n.t('modules.application.banners.trial_end.template_1_day', { n: trialRemaining.value })
  return hasZendeskProxy && hasAccountRole('ADMIN')
    ? i18n.t('modules.application.banners.trial_end.template_days_zd_only', { n: trialRemaining.value })
    : i18n.t('modules.application.banners.trial_end.template_days', { n: trialRemaining.value })
})

const trialEndingVisible = computed(() => {
  const hasActiveTrial = session.account.status === 'active' && session.account.trialEndAt
  return route.name !== 'onboarding' && hasActiveTrial && trialRemaining.value < 8 && !hasZDTrial.value
})

const hasZDTrial = computed(() => hasZendeskProxy && trialRemaining.value > 8 && trialRemaining.value < 15)

const zdTrialVisible = computed(() => hasZDTrial.value && !hideZDTrialBanner.value)

const bannersVisible = computed(() => zdTrialVisible.value || trialEndingVisible.value || userLimitVisible.value)

watch(bannersVisible, async () => {
  await nextTick()
  setBannerPadding()
})

useEvent('user-limit-reached', () => (userLimitVisible.value = true))

onMounted(async () => {
  await nextTick()
  setBannerPadding()
})

const dismissZDTrialBanner = () => {
  hideZDTrialBanner.value = true
  analytics.closeMessageClicked()
}

const dismissUserLimitBanner = () => {
  userLimitVisible.value = false
  analytics.closeMessageClicked()
}

const setBannerPadding = () => {
  let padding = bannersVisible.value ? '45px' : '0px'

  if (document.body.clientWidth <= 768 && root.value) {
    // Get height of all banners
    const height = Array.from(root.value.children).reduce((acc, child) => {
      if ((child as HTMLElement).style.display === 'none') return acc
      return acc + (child as HTMLElement).offsetHeight
    }, 0)

    padding = height + 'px'
  }

  emit('padding-change', padding)
}

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_sanitize = _resolveDirective("sanitize")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root
  }, [
    (zdTrialVisible.value)
      ? (_openBlock(), _createBlock(UiBanner, {
          key: 0,
          status: "info",
          onClose: dismissZDTrialBanner
        }, {
          default: _withCtx(() => [
            _createVNode(_component_i18n_t, {
              tag: "div",
              keypath: "modules.application.banners.trial_left.template_zd_only"
            }, {
              days: _withCtx(() => [
                (_unref(hasAccountRole)('ADMIN'))
                  ? (_openBlock(), _createElementBlock("a", _hoisted_1, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('modules.application.banners.trial_left.days', trialRemaining.value)), 1 /* TEXT */)
                    ]))
                  : (_openBlock(), _createElementBlock("strong", _hoisted_2, _toDisplayString(_ctx.$t('modules.application.banners.trial_left.days', trialRemaining.value)), 1 /* TEXT */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (trialEndingVisible.value)
      ? (_openBlock(), _createBlock(UiBanner, {
          key: 1,
          status: "info",
          stubborn: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("p", null, null, 512 /* NEED_PATCH */), [
              [_directive_sanitize, trialRemainingText.value]
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (userLimitVisible.value)
      ? (_openBlock(), _createBlock(UiBanner, {
          key: 2,
          status: "warning",
          onClose: dismissUserLimitBanner
        }, {
          default: _withCtx(() => [
            _createVNode(_component_i18n_t, { keypath: "modules.application.banners.user_limit.template" }, {
              link: _withCtx(() => [
                _createVNode(_component_router_link, { to: { name: 'settings.subscription' } }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('modules.application.banners.user_limit.link')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}
}

})