import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "px-3 py-1 text-sm font-medium text-gray-50"
}
const _hoisted_2 = {
  key: 2,
  class: "px-3 py-1 text-left text-sm font-medium text-gray-50"
}

import { computed, useSlots } from 'vue'
import { hasSlotContent } from '@klausapp/shared/utils/slot'

import { UiMenuGroupTheme } from './types'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMenuGroup',
  props: {
    headingTheme: { default: UiMenuGroupTheme.Default }
  },
  setup(__props: any) {



const slots = useSlots()

const isVisible = computed(() => hasSlotContent(slots.default) || hasSlotContent(slots.empty))

const headingThemeClasses = {
  [UiMenuGroupTheme.Default]: 'font-medium text-xs pt-2',
  [UiMenuGroupTheme.Uppercase]: 'font-bold text-caps pt-4',
}

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(["border-gray-20 mb-1 border-b pb-1 last:mb-0 last:border-b-0 last:pb-0", { 'first:pt-1': !_ctx.$slots.name }])
      }, [
        (_ctx.$slots.name)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(["text-gray-60 cursor-default pb-2 pl-3 pr-4 text-left", headingThemeClasses[_ctx.headingTheme]])
            }, [
              _renderSlot(_ctx.$slots, "name")
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        _renderSlot(_ctx.$slots, "default"),
        (!_unref(hasSlotContent)(_ctx.$slots.default) && _ctx.$slots.empty)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "empty")
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.$slots.more)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _renderSlot(_ctx.$slots, "more")
            ]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}
}

})