import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["stroke-dasharray", "stroke-dashoffset"]

import { defineComponent, watch, ref, useCssModule, computed, onMounted, type Ref } from 'vue'

const viewBoxWidth = 36
const circleDiameter = 31.831

export default /*@__PURE__*/_defineComponent({
  __name: 'UiGoal',
  props: {
    goal: { default: 0 },
    actionCount: { default: 0 },
    dark: { type: Boolean, default: false },
    customColor: { type: Boolean, default: false },
    disableAnimation: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const style = useCssModule()
const pulsate = ref(false)

const percentage = computed(() =>
  props.actionCount > 0 ? Math.round((Math.min(props.actionCount, props.goal) * 100) / props.goal) : 0,
)
const circle = ref<Ref | null>(null)

let computedStyle = ref<CSSStyleDeclaration>()

onMounted(() => {
  computedStyle.value = getComputedStyle(circle.value)
})

const circleCircumference = computed(() => {
  const xPadding = computedStyle.value
    ? parseFloat(computedStyle.value.paddingLeft) + parseFloat(computedStyle.value.paddingRight)
    : 0
  const factor = xPadding ? (circle.value?.clientWidth - xPadding) / viewBoxWidth : 1

  // return full circle when goal is fulfilled
  if (percentage.value >= 100) return 100
  return factor * circleDiameter * Math.PI
})

const strokeDashoffset = computed(
  () => circleCircumference.value - (percentage.value / 100) * circleCircumference.value,
)

const circleColors = computed(() => ({
  [style.red]: percentage.value < 25,
  [style.orange]: percentage.value >= 25 && percentage.value < 50,
  [style.green]: percentage.value >= 50,
  [style.custom]: props.customColor,
}))

watch(
  () => props.actionCount,
  (_, updatedBefore) => {
    if (updatedBefore) {
      pulsate.value = true
      setTimeout(() => (pulsate.value = false), 600)
    }
  },
  { immediate: true },
)

defineComponent({
  name: 'UiGoal',
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.$style.wrapper,
      ...(!_ctx.disableAnimation ? [percentage.value >= 100 && 'particles', pulsate.value && _ctx.$style.pulsate] : []),
    ]),
    onTransitionend: _cache[0] || (_cache[0] = ($event: any) => (pulsate.value = false))
  }, [
    (_openBlock(), _createElementBlock("svg", {
      ref_key: "circle",
      ref: circle,
      width: "36",
      height: "36",
      viewBox: "0 0 36 36",
      class: _normalizeClass(_ctx.$style.svg)
    }, [
      _createElementVNode("path", {
        class: _normalizeClass([_ctx.$style.backgroundCircle, _ctx.dark && _ctx.$style.darkTheme]),
        d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
      }, null, 2 /* CLASS */),
      _createElementVNode("path", {
        class: _normalizeClass([_ctx.$style.circle, circleColors.value, _ctx.disableAnimation && _ctx.$style.transitionDisabled]),
        "stroke-dasharray": circleCircumference.value,
        "stroke-dashoffset": strokeDashoffset.value,
        d: "M18 2.0845\n          a 15.9155 15.9155 0 0 1 0 31.831\n          a 15.9155 15.9155 0 0 1 0 -31.831"
      }, null, 10 /* CLASS, PROPS */, _hoisted_1)
    ], 2 /* CLASS */))
  ], 34 /* CLASS, NEED_HYDRATION */))
}
}

})