import { reactive, computed } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import { getCategories } from '@/modules/workspace/api/categories'
import { session } from '@/composables/useSession'
import { type Category } from '@/modules/shared/Review/utils'
import useTicketRouteParams from '@/composables/useTicketRouteParams'
import { getScorecards } from '@/modules/shared/TicketContent/api'
import { isDynamicScorecard as isDynamicScorecardUtil } from '@/modules/shared/TicketContent/utils/scorecard'
import { mapCategoriesById, getSortedGroupsFromCategories } from './utils'

export interface State {
  scorecards: Scorecard[]
  selectedScorecard?: Scorecard
  categories: Category[]
}

export const defaultState: State = {
  scorecards: [],
  selectedScorecard: undefined,
  categories: [],
}

const SCORECARD_STORAGE_KEY = 'klausSelectedScorecard.v2'

export default createSharedComposable(() => {
  const state = reactive(defaultState)

  const { connectionId, conversationId } = useTicketRouteParams()

  const generateScorecardStorageKey = (workspaceId: number) => `${workspaceId}.${SCORECARD_STORAGE_KEY}`

  const isDynamicScorecard = computed(() => isDynamicScorecardUtil(state.selectedScorecard))

  const activeScorecardCategories = computed(() => state.selectedScorecard?.ratingCategoryIds || [])

  function setScorecard(selectedScorecard: Scorecard) {
    state.selectedScorecard = selectedScorecard
  }

  const _setDefaultScorecard = (workspaceId: number) => {
    const key = generateScorecardStorageKey(workspaceId)
    const persisted: string | undefined = localStorage[key]
    const defaultScorecardId = persisted || state.selectedScorecard?.id

    if (defaultScorecardId) {
      const scorecard = state.scorecards.find((sc) => sc.id === defaultScorecardId)

      // Outdated scorecard persisted, remove it
      if (!scorecard) localStorage.removeItem(key)
      setScorecard(scorecard || state.scorecards[0])
    } else {
      setScorecard(state.scorecards[0])
    }
  }

  async function loadScorecards() {
    if (!conversationId.value || !connectionId.value) return

    const { scorecards } = await getScorecards(conversationId.value, connectionId.value)
    const mapped = scorecards.map((card) => ({
      ...card,
      ratingCategoryIds: (card.ratingCategoryIds as unknown[] as string[]).map(parseFloat),
    }))
    state.scorecards = mapped

    if (mapped[0] && (!state.selectedScorecard || isDynamicScorecard.value)) {
      _setDefaultScorecard(session.workspace.id)
    }
  }

  async function _loadRatingCategories() {
    const { data } = await getCategories(session.workspace.id)

    state.categories = data.map((cat) => ({
      autoQaApplicabilities: cat.autoQaApplicabilities,
      categoryId: cat.id,
      categoryName: cat.name,
      categoryDescription: cat.description,
      groupId: cat.groupId,
      groupName: cat.groupName,
      groupPosition: cat.groupPosition,
      scale: cat.scale,
      requireReason: cat.requireReason,
      multipleRequireReasons: cat.multipleRequireReasons,
      requireReasonVisibility: cat.requireReasonVisibility,
      freeTextAllowed: cat.freeTextAllowed,
      weight: cat.weighting,
      critical: cat.failCategory,
      // Group critical is only supported in advanced scorecards
      groupCritical: false,
      reviewCritical: cat.failCategory,
      position: cat.position,
      isArchived: cat.isArchived,
      scorecards: cat.scorecards,
      rootCauses: cat.rootCauses,
      autoQaCategory: cat.autoQaCategory,
      autoQaCustomCategoryTemplateId: cat.autoQaCustomCategoryTemplateId,
      skippable: allowSkip.value,
      isAdvancedScorecardCategory: false,
      // Category ratings are only supported for prompt-based categories
      // which can be used only with advanced scorecards
      categoryRatings: [],
    }))
  }

  const setup = () => {
    _loadRatingCategories()
  }

  const clearSelectedScorecard = () => setScorecard(undefined)

  const categoryOptions = computed(() => mapCategoriesById(state.categories))

  const categoryGroups = computed(() => getSortedGroupsFromCategories(categoryOptions.value))

  const resetScorecards = () => {
    state.scorecards = []
  }

  const selectedScorecardName = computed(() => state.selectedScorecard?.tag)

  const allowSkip = computed(() => session.account.ratingCategory.skippable)

  async function setDraftedScorecard(scorecardId: string) {
    if (isDynamicScorecard.value) return
    if (!state.scorecards.length) await loadScorecards()

    const scorecard = state.scorecards?.find((s) => s.id === scorecardId)
    if (scorecard) setScorecard(scorecard)
  }

  return {
    state,
    setup,
    loadScorecards,
    setScorecard,
    clearSelectedScorecard,
    activeScorecardCategories,
    categoryOptions,
    categoryGroups,
    resetScorecards,
    isDynamicScorecard,
    selectedScorecardName,
    setDraftedScorecard,
    generateScorecardStorageKey,
  }
})
